import React, { useCallback, useEffect, useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { Button, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useHistory, useParams } from 'react-router-dom';
import { Subtitle, SurfacePage, Title } from '../../../components';
import UnitLesson from './components/unit-lesson';
import { TopicProgress } from './components/topic';
import { get, post } from '../../../utils/network';
import TopicProgressPlaceholder from './components/placeholders/topic-progress-placeholder';
import {
  UnitHeaderPlaceholder,
  UnitNamePlaceholder,
} from './components/placeholders/unit-placeholder';
import { getCookie } from '../../../utils/utils';
import BookALessonButton from '../book-lesson/components/book-a-lesson-button';

const useStyles = makeStyles(theme => ({
  leftContainer: {
    flex: 0.6,
    marginLeft: 48,
    marginTop: 120,
    minWidth: 160,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'unset',
    },
  },
  centerContainer: {
    flex: 4,
  },
  rightContainer: {
    flex: 0.8,
  },
  arrow_left_space:{
    marginLeft: '.5rem',
    transform: 'rotate(180deg)'
  },
  arrow_right_space:{
    marginRight: '.5rem',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  topicsProgress: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '0 10px',
    flexWrap: 'wrap',
  },
  navigationButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    flexWrap: 'wrap',
  },
  centerNavigationButtons: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    flexWrap: 'wrap',
  },
  button: {
    backgroundColor: 'rgba(240, 242, 244, 0.5)',
    color: theme.palette.secondary.main,
    fontSize: 12,
    fontWeight: 450,
    borderRadius: 6,
    padding: '8px 24px',
    marginTop: 24,
  },

  video: {
    width: '100%',
    margin: '24px 0',
    borderRadius: 10,
  },
}));

const Unit = () => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();

  const [unit, setUnit] = useState({});
  const [prevAndNextUnit, setPrevAndNextUnit] = useState(null);

  const [loading, setLoading] = useState(true);
  const [lessonCurrentIndex, setLessonCurrentIndex] = useState(0);
  const [loadingVideo, setLoadingVideo] = useState(true);
  const handleChangeLesson = index => setLessonCurrentIndex(index);

  const getUnit = useCallback(() => {
    return get(`units/${id}/mine`)
      .then(res => setUnit(res.message.data))
      .finally(() => setLoading(false));
  }, [id]);

  const getPreviousAndNext = async (unitId) => {
    const previousAndNext = await get(`courses/${getCookie('courseId')}/mine`)
      .then(res => {
        const unitsData = res.message.data.units;
        const previous = unitsData.filter(unit => unit.nextUnit === unitId)?.[0];
        const nextId = unitsData.filter(unit => unit._id === unitId)?.[0]?.nextUnit
        const next = unitsData.filter(unit => unit._id === nextId)?.[0]
        return { previous, next }
      })
    return previousAndNext
  }

  const getPreviousAndNextByUnitId = async () =>{
    const PreviousAndNext = await getPreviousAndNext(id);
    setPrevAndNextUnit(PreviousAndNext) 
  
  };
  useEffect(() => {  
    getPreviousAndNextByUnitId();
  }, [id]);
  
  useEffect(() => {
    getUnit();
  }, [id, getUnit]);
  
  const handleClickUnitNavigation = ({_id, type}) => {
      if (/review/i.test(type)) {
        return history.push(`/home/course_journey/reviewUnits/${_id}/start`);
      }
      setLessonCurrentIndex(0);
      history.push(`/home/course_journey/units/${_id}`);
  };

  const generateNavigationText  = (unit) => unit?.header+ " - " + unit?.name

  const onVideoEnd = () => {
    post(`units/${id}/mine/lessons`, { lesson: unit.lessons[lessonCurrentIndex]._id }).then(() =>
      getUnit().then(() => {
        if (lessonCurrentIndex < unit.lessons.length - 1)
          setLessonCurrentIndex(prevIndex => prevIndex + 1);
      })
    );
  };

  const navigateToExercise = exerciseId => {
    const { type, subtype } = unit.availableExercises.find(exercise => exercise._id === exerciseId);
    const pathname = `/home/course_journey/exercises/${type}${
      subtype ? `/${subtype}` : ''
    }/${exerciseId}`;
    history.push({
      pathname,
      search: `?unitHeader=${unit.header}&unitName=${unit.name}&unitId=${id}`,
    });
  };

  return (
    <SurfacePage centerContainer={false}>
      <div className={classes.leftContainer}>
        <UnitLesson
          currentIndex={lessonCurrentIndex}
          handleClick={handleChangeLesson}
          lessons={unit.lessons}
          loading={loading}
        />
      </div>
      <div className={classes.centerContainer}>
        {loading ? <UnitHeaderPlaceholder /> : <Title withBackArrow>{unit.header}</Title>}
        <div className={classes.header}>
          {loading ? (
            <UnitNamePlaceholder />
          ) : (
            <>
              <Subtitle>{unit.name}</Subtitle>
              <BookALessonButton/>
            </>
          )}
        </div>
        {(loadingVideo || !unit?.lessons?.length) && (
          <Skeleton variant="rect" height={500} className={classes.video} />
        )}
        {unit?.lessons?.length > 0 && (
          <Vimeo
            video={unit.lessons[lessonCurrentIndex].videoUrl}
            className={classes.video}
            responsive
            onReady={() => setLoadingVideo(false)}
            onEnd={onVideoEnd}
            style={{ display: loadingVideo || !unit?.lessons?.length ? 'none' : undefined }}
          />
        )}
        <div className={classes.topicsProgress}>
          {loading ? (
            <TopicProgressPlaceholder amount={5} />
          ) : (
            unit?.availableExercises?.map(({ _id, genericType, score }) => (
              <TopicProgress
                key={_id}
                id={_id}
                navigateToExercise={navigateToExercise}
                topic={genericType}
                score={score}
              />
            ))
          )}
        </div>
        <div
          className={
            prevAndNextUnit?.previous && prevAndNextUnit.next
              ? classes.navigationButtons
              : classes.centerNavigationButtons
          }
        >
          {prevAndNextUnit?.previous && (
            <Button
              onClick={() => handleClickUnitNavigation(prevAndNextUnit?.previous)}
              className={classes.button}
              startIcon={
                <img
                  alt="go-back"
                  src="icons-clickable-arrow-left.svg"
                  className={classes.arrow_right_space}
                />
              }
            >
              {prevAndNextUnit?.previous?.header+ " - "  +prevAndNextUnit?.previous?.name }
            </Button>
          )}

          {prevAndNextUnit?.next && (
            <Button
              onClick={() => handleClickUnitNavigation(prevAndNextUnit?.next)}
              className={classes.button}
              endIcon={
                <img
                  alt="go-back"
                  src="icons-clickable-arrow-left.svg"
                  className={classes.arrow_left_space}
                />
              }
            >
              {generateNavigationText(prevAndNextUnit?.next)}
            </Button>
          )}
        </div>
      </div>
      <div className={classes.rightContainer} />
    </SurfacePage>
  );
};

export default Unit;
