import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles,
  useTheme,
  useMediaQuery,
  Typography,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import { LoginInput, PrimaryButton, TertiaryButton } from '../../../components';
import { post } from '../../../utils/network';
import { EMAIL_REGEX } from '../../../utils/utils';
import I18n from '../../../lang/i18n';

const styles = theme => ({
  dialog: {
    padding: 0,
  },
});

const PasswordResetDialog = props => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { open, classes, onClose, onSubmit } = props;
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorEmail, setErrorEmail] = useState('');

  const handleChange = e => {
    setErrorEmail('');
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    if (email && EMAIL_REGEX.test(email)) {
      setLoading(true);
      post(
        'auth/passwordreset',
        { email: email },
        { errorHandlers: [{ status: 404, method: () => Promise.reject(404) }] }
      )
        .then(res => {
          resetForm();
          onClose();
          onSubmit();
        })
        .catch(err => {
          if (err === 404) {
            setErrorEmail(I18n.t('passwordReset.dialog.errors.emailNotRegistered'));
          } else {
            onClose();
          }
        })
        .finally(() => setLoading(false));
    } else {
      setErrorEmail(I18n.t('passwordReset.dialog.errors.invalidEmail'));
    }
  };

  const resetForm = () => {
    setEmail('');
    setErrorEmail('');
  };

  const closeDialog = () => {
    resetForm();
    onClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      className={classes.dialog}
      onClose={closeDialog}
      open={open}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle style={{ padding: '20px 24px 7px' }}>
        {I18n.t('passwordReset.dialog.title')}
      </DialogTitle>
      <DialogContent>
        <Typography>{I18n.t('passwordReset.dialog.emailCaption')}</Typography>
        <LoginInput
          error={errorEmail}
          name="email"
          label={I18n.t('passwordReset.dialog.email')}
          type="email"
          handleChange={handleChange}
          value={email}
        />
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress size={22} color="primary" />}
        <TertiaryButton onClick={closeDialog} disabled={loading} style={{ color: 'gray' }}>
          {I18n.t('passwordReset.dialog.buttons.cancel')}
        </TertiaryButton>
        <PrimaryButton type="submit" onClick={handleSubmit} disabled={loading}>
          {I18n.t('passwordReset.dialog.buttons.confirm')}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(PasswordResetDialog);
