import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { SecondaryButton, SurfacePage, TertiaryButton, Title } from '../../../../components';
import { useHistory, useLocation } from 'react-router-dom';
import I18n from '../../../../lang/i18n';
import { post } from '../../../../utils/network';
import { getCookie } from '../../../../utils/utils';
import LevelSelectorDialog from '../components/level-selector-dialog';

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 20,
    margin: '36px 0',
    fontWeight: 500,
  },
  levels: {
    marginTop: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    gap: 10,
  },
  value: {
    fontSize: 28,
    fontWeight: 500,
    color: theme.palette.accent.main,
    marginLeft: 10,
  },
  caption: {
    fontSize: 16,
    maxWidth: 440,
    margin: '5px 0',
  },
}));

const PlacementTestResults = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const suggestedCourse = location.state?.suggestedCourse;
  const correctAnswers = location.state?.correctAnswers;
  const incorrectAnswers = location.state?.incorrectAnswers;
  const courseId = getCookie('courseId');
  const courseName = getCookie('courseName');

  const [loading, setLoading] = useState(false);
  const [openLevelSelector, setOpenLevelSelector] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const handleChangeLevel = e => setSelectedCourse(e.target.value);
  const handleCloseDialog = () => {
    setSelectedCourse(null);
    setOpenLevelSelector(false);
  };
  const handleOpenDialog = () => setOpenLevelSelector(true);
  const handleSelectLevel = () => handleEnrollCourse(selectedCourse);

  const handleEnrollCourse = id => {
    setLoading(true);
    post(`users/${getCookie('userId')}/course`, { courseId: id })
      .then(() => history.push('/home/status'))
      .finally(() => {
        setOpenLevelSelector(false);
        setLoading(false);
      });
  };

  const totalQuestion = incorrectAnswers + correctAnswers;

  useEffect(() => {
    if(!suggestedCourse) {
      history.push('/home/course_journey/placement/start');
    }
  }, [history, suggestedCourse]);

  return (
    <>
      <SurfacePage>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Title withBackArrow>{I18n.t('courseJourney.placement.title')}</Title>
        </div>
        <div style={{ marginTop: 20 }}>
          <Title className={classes.text}>
            <span className={classes.value}>{correctAnswers}/{totalQuestion} </span>
            {I18n.t('courseJourney.placement.correctAnswers')}
          </Title>
          <Title className={classes.text}>
            {I18n.t('courseJourney.placement.basedTest')}
            <span className={classes.value}>{suggestedCourse?.name}-{suggestedCourse?.exam}</span>
            {I18n.t('courseJourney.placement.level')}
          </Title>
          <Title className={classes.text}>
            <span className={classes.text}>
            {suggestedCourse?.name === "A1" || suggestedCourse?.name === "A2" ? (
                <>{I18n.t('courseJourney.placement.msgBased1')}<br/>{I18n.t('courseJourney.placement.msgBased2')}</>
              ) : suggestedCourse?.name === "B1" ? (
                <>{I18n.t('courseJourney.placement.msgBased1')}<br/>{I18n.t('courseJourney.placement.msgBased2')}</>
              ) : suggestedCourse?.name === "B2" ? (
                <>{I18n.t('courseJourney.placement.msgBased3')}<br/>{I18n.t('courseJourney.placement.msgBased4')}</>
              ) : suggestedCourse?.name === "C1" ? (
                <>{I18n.t('courseJourney.placement.msgBased5')}<br/>{I18n.t('courseJourney.placement.msgBased6')}</>
              ) : suggestedCourse?.name === "C2" ? (
                <>{I18n.t('courseJourney.placement.msgBased7')}<br/>{I18n.t('courseJourney.placement.msgBased6')}</>
              ) : null}
          </span>
          </Title>
          <SecondaryButton 
            onClick={() => handleEnrollCourse('60edcdca94f8d5e3eec2a6ac')} // suggestedCourse._id colocar esto cuando haya mas de un curso disponible en la pagina.
            loading={loading}
          >
          {courseId && courseName ? "Back to Home" : I18n.t('courseJourney.placement.enrollNow') }
          </SecondaryButton>
        </div>
      </SurfacePage> 
      <LevelSelectorDialog
        open={openLevelSelector}
        selected={selectedCourse}
        handleChange={handleChangeLevel}
        handleClose={handleCloseDialog}
        handleSelect={handleSelectLevel}
        submitting={loading}
      />
    </>
  );
};

export default PlacementTestResults;
