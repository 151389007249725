import React from 'react';
import { Skeleton } from '@material-ui/lab';

const UnitHeaderPlaceholder = ({ style }) => (
  <Skeleton variant="text" height={64} width={180} style={style} />
);

const UnitNamePlaceholder = () => (
  <Skeleton variant="text" height={50} width="70%" style={{ maxWidth: 600 }} />
);

export { UnitNamePlaceholder, UnitHeaderPlaceholder };
