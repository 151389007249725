import { makeStyles } from '@material-ui/core';
import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import GradientSVG from './GradientSvg';

const useStyles = makeStyles(theme => ({
  containerCard: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 24px 32px',
    width: '400px',
    height: '238px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 24px rgba(23, 26, 31, 0.1)',
    borderRadius: '10px',
  },

  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '-0.2px',
    color: '#22282F',
    marginTop: 0,
    marginBottom: 24,
  },

  text: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '16px',
    letterSpacing: '-0.2px',
    color: '#22282F',
    alignSelf: 'flex-end',
    marginBottom: 24,
    marginLeft: 24,
    whiteSpace: 'pre-line',
  },

  contentContainer: {
    display: 'flex',
  },

  progressiveUnit: {
    height: 120,
    width: 120,
    fontWeight: 600,
  },
}));

const CardJourney = ({ title, order, progress }) => {
  const classes = useStyles();
  var msgJourney = null;

  switch (true) {
    case progress <= 0.1:
      msgJourney = "Get started now!\nBegin your journey today.";
      break;
    case progress === 100:
      msgJourney = "Great job!\nYou've completed\nthe course";
      break;
    case progress > 0.1:
      msgJourney = "You are doing great,\nKeep up the pace!";
      break;
    default:
      msgJourney = "Get started now!\nBegin your journey today.";
      break;
  }

  return (
    <div className={classes.containerCard} style={{ order: order }}>
      <h1 className={classes.title}>{title}</h1>
      <div className={classes.contentContainer}>
        <GradientSVG startColor="#44D9E6" endColor="#1F5CDB" idCSS="progressGradient" rotation={90} />
        <CircularProgressbar
          className={classes.progressiveUnit}
          value={progress}
          text={`${progress}%`}
          strokeWidth={10}
          styles={buildStyles({
            strokeLinecap: 'round',
            pathColor: 'url(#progressGradient)',
          })}
        />
        <p className={classes.text}>{msgJourney}</p>
      </div>
    </div>
  );
};

export default CardJourney;
