import { makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Loading, SurfacePage, Title } from '../../../components';
import I18n from '../../../lang/i18n';
import { get } from '../../../utils/network';
import { getCookie } from '../../../utils/utils';
import CardJourney from './components/card-journey';
import CardLesson from './components/card-lesson';
import CardPlan from './components/card-plan';
import CardStatistics from './components/card-statistics';
import CardUnit from './components/card-unit';
import CardWelcome from './components/card-welcome';
import NormalCard from './components/normal-card';

const useStyles = makeStyles(theme => ({
  cardList: {
    flexDirection: 'row',
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 16,
  },
  section: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },

  captionContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 5,
  },
  caption: {
    fontSize: 16,
    maxWidth: 480,
    textAlign: 'center',
  },
  link: {
    color: theme.palette.accent.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },

}));

const StudentHome = () => {
  const [courseProgress, setCourseProgress] = useState(0);
  const [activeUnit, setActiveUnit] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  const [dashboardData, setDashboardData] = useState(null);
  const [userData, setUserData] = useState(null);
  const goToPlacementTest = () => history.push('/home/course_journey/placement/start');
  const goToFAQ = () => history.push('/home/faq');
  const goToUnit = (unit) => {
    if(unit.type === 'Review'){
      history.push(`/home/course_journey/reviewUnits/${unit._id}/start`)
      return;
    }
    history.push(`/home/course_journey/units/${unit._id}`)
  };
  const goToBookLesson = () => history.push('/home/book_lesson');
  const courseId = getCookie('courseId');
  const courseName = getCookie('courseName');
  const nameUser = getCookie('name');
  const [loading, setLoading] = useState(true);
  const bookLesson = getCookie('bookLessonsEnabled');
  let bookLessonBool;

  if (bookLesson === "true") {
    bookLessonBool = true;
  } else if (bookLesson === "false") {
    bookLessonBool = false;
  } else {
    bookLessonBool = false;
  }
  
  const getUser = useCallback(
    () =>
      get(`users/${getCookie('userId')}`)
        .then(res => setUserData(res.message))
        .catch(err => console.log('dashboard err', err)),
    []
  );

  const getCourseProgress = () => {
    if(getCookie('courseId'))
      get(`courses/${getCookie('courseId')}/mine`)
        .then(res => setCourseProgress(res.message.data.progress))
        .catch(err => console.log('dashboard err', err));
  }

  const getActiveUnit = () => {
    if(getCookie('courseId'))
      get(`users/${getCookie('userId')}/active_unit`)
        .then(res => setActiveUnit(res.message.data))
        .catch(err => console.log('dashboard err', err));
  }

  const getDashboard = () =>
    get(`users/${getCookie('userId')}/dashboard`)
      .then(res => setDashboardData(res.message.data))
      .catch(err => console.log('dashboard err', err));

  useEffect(() => {
    Promise.allSettled([getCourseProgress(), getActiveUnit(), getDashboard(), getUser()]).then(() => setLoading(false));
  }, [courseId, getUser]);

  const lastUnit = activeUnit?.header === "Unit 10" && activeUnit?.progress >=0.99;
  const lastRev = activeUnit?.header === "Revision 5" && lastUnit ?  'Great Job!' : 'Complete unit';

  return (
    <SurfacePage maxWidth={'100%'}>
      <Title>
        {I18n.t('home.welcome')}
        {nameUser}!
      </Title>
      {loading ? (
        <Loading />
      ) : (
        <div className={classes.cardList}>
          <div className={classes.section}>
            <CardJourney title="Journey" progress={courseProgress} />
            <CardStatistics data={dashboardData} />
          </div>
          <div className={classes.section}>
            <CardUnit
              title="Active Unit"
              img={activeUnit?.photoUrl}
              unit={activeUnit}
              btnName={lastRev}
              progressUnit={activeUnit?.progress * 100 || 0}
              handleClick={() => goToUnit(activeUnit)}
            />
            {bookLessonBool && (
              <CardLesson
                title="Online Lesson"
                subtitle="Next Lesson"
                btnName="Book Lesson"
                handleClick={goToBookLesson}
              />
            )}
          </div>
          <div className={classes.section}>
            <NormalCard
              title={I18n.t('home.placementTest')}
              subtitle={I18n.t('home.yourLevel')}
              courseName={userData?.studentData?.placementTestResult}
              btnName={userData?.studentData?.placementTestResult ? 'Take again' : 'Take test'}
              handleClick={goToPlacementTest}
              colorLight={userData?.studentData?.placementTestResult}
            />
            <CardPlan
              data={userData?.studentData?.activeSubscription}
              handleRefresh={getUser}
              freeTrial={userData && userData.isFreeTrial ? userData.freeTrialEnd : undefined}
              schoolSubscription={userData?.studentData?.schoolSubscription}
            />
          </div>
        </div>
      )}
      <CardWelcome
        img="photo-card-welcome.webp"
        handleClick={goToPlacementTest}
        open={userData?.studentData?.welcomeEnabled}
      />

      <div className={classes.captionContainer}>
        <p className={classes.caption}>
          {I18n.t('home.student.afterReading')}{' '}
          <span onClick={goToFAQ} className={classes.link}>
            {I18n.t('home.student.faq')}
          </span>
          {I18n.t('home.student.callTechnical')}
          <a
            href="mailto: support@testbridge.it"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            support@testbridge.it
          </a>
        </p>
      </div>

    </SurfacePage>
  );
};
 
export default StudentHome;