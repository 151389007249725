import React from 'react';
import { Skeleton } from '@material-ui/lab';

const LessonPlaceholder = ({ amount = 1 }) => {
  return [...Array(amount)].map((_, i) => (
    <Skeleton key={i} variant="text" height={82} width="100%" style={{ marginBottom: -24 }} />
  ));
};

export default LessonPlaceholder;
