import I18n from '../../lang/i18n';

const DRAWER_OPTIONS = [
  { id: 0, src: 'icons-home.svg', title: I18n.t('drawer.home'), path: 'status' },
  { id: 1, src: 'icons-case.svg', title: I18n.t('drawer.courseJourney'), path: 'course_journey' },
  { id: 2, src: 'icons-book.svg', title: I18n.t('drawer.extraPractice'), path: 'extra_practice' },
  { id: 3, src: 'icons-calendar.svg', title: I18n.t('drawer.bookLesson'), path: 'book_lesson' },
  { id: 4, src: 'icons-map.svg', title: I18n.t('drawer.courseMap'), path: 'course_map' },
  { id: 5, src: 'icons-stats.svg', title: I18n.t('drawer.statistics'), path: 'statistics' },
  { id: 6, src: 'user-avatar-white.svg', title: I18n.t('drawer.profile'), path: 'profile' },
];

const NO_COURSE_DRAWER_OPTIONS = [
  { id: 0, src: 'icons-home.svg', title: I18n.t('drawer.home'), path: 'status' },
  { id: 6, src: 'user-avatar-white.svg', title: I18n.t('drawer.profile'), path: 'profile' },
];

const TEACHER_DRAWER_OPTIONS = [
  { id: 0, src: 'icons-home.svg', title: I18n.t('drawer.home'), path: 'status' },
  { id: 3, src: 'icons-calendar.svg', title: I18n.t('drawer.bookLesson'), path: 'book_lesson' },
  { id: 6, src: 'user-avatar-white.svg', title: I18n.t('drawer.profile'), path: 'profile' },
]

const NO_BOOK_LESSONS_DRAWER_OPTIONS = [
  { id: 0, src: 'icons-home.svg', title: I18n.t('drawer.home'), path: 'status' },
  { id: 1, src: 'icons-case.svg', title: I18n.t('drawer.courseJourney'), path: 'course_journey' },
  { id: 2, src: 'icons-book.svg', title: I18n.t('drawer.extraPractice'), path: 'extra_practice' },
  { id: 4, src: 'icons-map.svg', title: I18n.t('drawer.courseMap'), path: 'course_map' },
  { id: 5, src: 'icons-stats.svg', title: I18n.t('drawer.statistics'), path: 'statistics' },
  { id: 6, src: 'user-avatar-white.svg', title: I18n.t('drawer.profile'), path: 'profile' },
]

export { DRAWER_OPTIONS, NO_COURSE_DRAWER_OPTIONS, TEACHER_DRAWER_OPTIONS, NO_BOOK_LESSONS_DRAWER_OPTIONS };
