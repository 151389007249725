import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Login from './pages/unlogged/login/container';
import LoggedHome from './pages/logged/home';
import { getCookie } from './utils/utils';
import VerifiedAccount from './pages/unlogged/login/verified-account';
import PasswordReset from './pages/unlogged/password-reset/password-reset-form/container';
import history from './utils/history';
import SignUp from './pages/unlogged/sign-up/sign-up';
import VerifyEmail from './pages/unlogged/verify-email';

const Routes = () => {
  const isLogged = getCookie('isLogged');
  return (
    <HashRouter history={history}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/verify-email" component={VerifyEmail} />
        <Route exact path="/passwordreset" component={PasswordReset} />
        <Route exact path="/welcome" component={VerifiedAccount} />
        <Route path="/home" component={LoggedHome} />
        <Route
          path="/"
          render={() => (isLogged ? <Redirect to="/home" /> : <Redirect to="/login" />)}
        />
        <Route path="/404" component={() => <div>404</div>} />
        <Redirect from="*" to="/404" />
      </Switch>
    </HashRouter>
  );
};

export default Routes;
