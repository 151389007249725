import React, { useEffect, useState } from 'react';
import { Drawer, CssBaseline, Hidden, Toolbar, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DrawerContent from './drawer-content';
import { DRAWER_OPTIONS } from './options';

const DRAWER_WIDTH = 56;
const MOBILE_DRAWER_WIDTH = 200;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      overflow: 'hidden',
    },
  },
  container: {
    paddingTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
    backgroundColor: '#EDEDED',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 10,
    },
  },
  drawerPaper: {
    zIndex: 10,
    backgroundColor: theme.palette.primary.main,
    width: DRAWER_WIDTH,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      width: MOBILE_DRAWER_WIDTH,
    },
  },
  logo: {
    display: "block",
    margin: "1rem auto",
  }
}));

const DrawerLeft = ({ handleDrawerToggle, drawerMobileOpen }) => {
  const classes = useStyles();
  const history = useHistory();

  const [active, setActive] = useState(
    () => DRAWER_OPTIONS.find(option => history.location.pathname.includes(option.path))?.id || 0
  );

  const handleChange = ({ id, path }) => {
    history.push(`/home/${path}`);
    setActive(id);
    handleDrawerToggle(false);
  };

  useEffect(() => {
    setActive(
      DRAWER_OPTIONS.find(option => history.location.pathname.includes(option.path))?.id || 0
    );
  }, [history.location.pathname]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Hidden xsDown implementation="css">
        <Drawer
          variant="permanent"
          anchor="left"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
          className={classes.drawerPaper}
        >
          <img className={classes.logo} alt="logo-testbridge" src="testbridge-logo.svg" />
          <DrawerContent handleChange={handleChange} active={active} />
        </Drawer>
      </Hidden>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={drawerMobileOpen}
          onClose={() => handleDrawerToggle()}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <DrawerContent handleChange={handleChange} active={active} />
        </Drawer>
      </Hidden>
    </div>
  );
};

export default DrawerLeft;
