import React from 'react';
import { Skeleton } from '@material-ui/lab';

const TopicProgressPlaceholder = ({ amount = 1 }) =>
  [...Array(amount)].map((_, i) => (
    <div key={i} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Skeleton variant="circle" width={56} height={56} />
      <Skeleton variant="text" width={80} height={30} />
    </div>
  ));

export default TopicProgressPlaceholder;
