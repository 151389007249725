import React, { useCallback, useEffect, useState } from 'react';
import { post } from '../../../../utils/network';
import { useParams } from 'react-router-dom';
import { capitalize } from '../../../../utils/utils';
import { Loading, SurfacePage } from '../../../../components';
import ReviewUnitResults from './review-unit-results';
import { removeUnitReview } from '../../../../utils/utils';
import {
  ListeningChoice,
  ListeningQuestion,
  ListeningWrite,
  ListeningQuestionSingle,
  ReadingChoice,
  ReadingKeyword,
  ReadingOptions,
  ReadingQuestion,
  ReadingWrite,
  Writing,
} from '../exercises';
import { getUnitReviewInLocalStorage, setUnitReviewInLocalStorage } from '../../../../utils/utils';
import { useExerciseTimer } from '../../../../hooks';

const EXERCISES = {
  ListeningChoice,
  ListeningQuestion,
  ListeningQuestionSingle,
  ListeningWrite,
  ReadingChoice,
  ReadingKeyword,
  ReadingOptions,
  ReadingQuestion,
  ReadingWrite,
  Writing,
};

const ReviewUnit = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [exercise, setExercise] = useState(null);
  const [reviewUnitToken, setReviewUnitToken] = useState(null);
  const [result, setResult] = useState(null);
  const [reviewIsFinished, setReviewIsFinished] = useState(false);
  const timer = useExerciseTimer();

  const formatExerciseName = useCallback(
    (type, subtype) => `${type}${subtype ? capitalize(subtype) : ''}`,
    []
  );

  const handleSuccessSubmit = (data) => {
    const finalScores = {};

    Object.keys(data.reviewRun).forEach(elem => {
      const [exerciseType, scoreText] = elem.split(/(score)/i);

      //If it contained "score" then it should have 3 parts: ["ex type", "Score", ""]
      if (!scoreText) return;
      finalScores[exerciseType] = data.reviewRun[`${exerciseType}Score`];
    });
    setResult(finalScores);
    removeUnitReview(id);
  
  };
  const handleEndReview = () => setReviewIsFinished(true);

  const handleExerciseChange = useCallback(
    data => {
      const { type, subtype } = data.exercise;
      data.exercise.exTypeName = formatExerciseName(type, subtype);
      setExercise(data.exercise);
      setReviewUnitToken(data.reviewUnitToken);
      const review = getUnitReviewInLocalStorage(id);
    },
    [formatExerciseName]
  );

  useEffect(() => {
    setLoading(true);
    post(`units/${id}/review`)
      .then(res => {
        const unitReview = getUnitReviewInLocalStorage(id);
        const withoutData = !unitReview || unitReview?.data?.blocked;
        if (withoutData) {
          handleExerciseChange(res.message.data);
          setUnitReviewInLocalStorage(id, { data: res.message.data });
        } else {
          handleExerciseChange(unitReview.data);
        }
        const review = getUnitReviewInLocalStorage(id);
        timer.saveTime(review?.data?.timeTaken || 0);
        setLoading(false);

      })
  }, [id, handleExerciseChange]);

  return loading ? (
    <SurfacePage>
      <Loading />
    </SurfacePage>
  ) : !!result && reviewIsFinished ? (
    <ReviewUnitResults result={result} timer={timer} />
  ) : (
    exercise.exTypeName &&
    React.createElement(EXERCISES[exercise.exTypeName], {
      key: exercise._id,
      data: exercise,
      reviewUnitId: id,
      reviewUnitToken,
      onSuccessSubmit: handleSuccessSubmit,
      handleChange: handleExerciseChange,
      handleEndReview: handleEndReview,
      initialLoading: loading,
      timer: timer,
    })
  );
};

export default ReviewUnit;
