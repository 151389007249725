import React from 'react';

const NextArrow = ({ className, style, onClick }) => (
  <div style={{ ...style, display: 'block', right: -40 }} className={className} onClick={onClick}>
    <img src="icons-clickable-right.svg" alt="right-icon" />
  </div>
);

const PrevArrow = ({ className, style, onClick }) => (
  <div style={{ ...style, display: 'block', left: -40 }} className={className} onClick={onClick}>
    <img src="icons-clickable-left.svg" alt="left-icon" />
  </div>
);

const SLIDER_SETTINGS = {
  className: 'center',
  centerMode: true,
  focusOnSelect: true,
  infinite: true,
  centerPadding: 0,
  slidesToShow: 2.99,
  // slidesToScroll: 3,
  speed: 500,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

export { SLIDER_SETTINGS };
