import React from 'react';
import { InfoRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import { TextArea } from '../../../../components';
import I18n from '../../../../lang/i18n';

const useStyles = makeStyles(theme => ({
  top: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 6,
  },
  caption: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  wordCount: {
    fontSize: 16,
    color: theme.palette.grey.main,
  },
  icon: {
    color: theme.palette.accent.main,
  },
  notSelected: {
    fontSize: 16,
  },
}));

const TextAreaAnswer = ({ answer, handleChange, rows = 15, selectedQuestion }) => {
  const classes = useStyles();
  const wordRegex = new RegExp("\\w+", "g");
  return (
    <>
      <div className={classes.top}>
        {!selectedQuestion ? (
          <div className={classes.caption}>
            <InfoRounded className={classes.icon} />
            <span className={classes.notSelected}>{I18n.t('textAreaAnswer.notSelected')}</span>
          </div>
        ) : (
          <div style={{ flex: 1 }} />
        )}
        <span className={classes.wordCount}>
          {I18n.t('textAreaAnswer.wordCount')}: { Array.from(answer?.matchAll(wordRegex)).length }
        </span>
      </div>
      <TextArea
        value={answer}
        handleChange={handleChange}
        rows={rows}
        disabled={!selectedQuestion}
      />
    </>
  );
};

export default TextAreaAnswer;
