import React, { useState } from 'react';
import {
  LoginInput,
  PasswordInput,
  SecondaryButton,
  SuccessToast,
  Title,
} from '../../../components';
import { Button, Checkbox, FormControlLabel, Grid, Typography, makeStyles, useTheme } from '@material-ui/core';
import I18n from '../../../lang/i18n';
import { useFormValidation } from '../../../hooks';
import { post } from '../../../utils/network';
import { useHistory } from 'react-router-dom';
import validate from './validator';
import moment from 'moment';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { omit } from 'lodash';
import config from '../../../utils/config';
import CookieBanner from '../login/cookies-banner';

const useStyles = makeStyles(theme => ({
  background: {
    padding: '2rem',
    backgroundColor: '#FFF',
  },
  grid: {
    minHeight: '92vh',
  },
  title: {
    textAlign: 'left',
    margin: '0 auto',
    fontWeight: 800,
    fontSize: '56px',
    color: '#44d9e6',
  },
  titleContainer: {
    width: 495,
    [theme.breakpoints.down(516)]: {
      width: 'auto',
    },
  },
  form: {
    width: 450,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down(516)]: {
      maxWidth: '80%',
      padding: '20px 10px',
      margin: 0,
    },
  },
  inputLogin: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxLogin: {
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  inputIcon: {
    marginRight: 5,
    color: 'grey',
  },
  passwordCaption: {
    color: theme.palette.grey.main,
  },
  info: {
    marginTop: '1.3rem',
    fontSize: 18,
    color: theme.palette.primary.main,
    '& p': {
      margin: '10px 0',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tipItem: {
    fontSize: '24px',
    lineHeight: '38.4px',
    '& p': {
      margin: 0,
      fontFamily: theme.typography.fontFamily,
    },
  },
  hCaptcha: {
    transform: 'scale(0.96)',
    transformOrigin: '0 0',
  },
  captchaError: {
    marginBottom: 16,
    textAlign: 'start',
    color: '#f44336',
    fontSize: 12,
  },
  loginTextContainer: {
    marginTop: '0.5em'
  },
  textAction: {
    border: 'none',
    color: '#2b78ff',
    fontSize: 16,
    fontWeight: 600,
    cursor: 'pointer',
    margin: '10px 0',
  },
}));

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  isVerified: false,
};

const SignUp = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');



  const { canSubmit, handleChange, handleBlur, values, errors, setErrors } = useFormValidation(
    INITIAL_STATE,
    validate
  );

  const onCloseToast = () => {
    setSuccessMessage('');
    history.push({
      pathname: '/verify-email',
      search: `?email=${values.email}`,
    });
  };

  const handle400 = err => {
    return err.json().then(e => {
      e.fields.map(error =>
        setErrors(prevErrors => ({
          ...prevErrors,
          [error.name]: error.message,
        }))
      );
      return { ...e, statusCode: err.status };
    });
  };

  const handleCaptchaError = err => {
    setErrors(prevErrors => ({ ...prevErrors, isVerified: I18n.t('signup.errors.verifyError') }));
  };

  const handleSubmit = e => {
    if (canSubmit(e)) {
      setSubmitting(true);
      post(
        'auth/signup',
        {
          timeZone: moment().format('Z'),
          ...omit(values, 'isVerified','iAmOver14' ),
        },
        { errorHandlers: [{ status: 400, method: handle400 }] }
      )
        .then(res => {
          if (res.statusCode === 201) setSuccessMessage(I18n.t('signup.successMessage'));
        })
        .finally(() => setSubmitting(false));
    }
  };

  const onVerifyCaptcha = token => {
    setErrors(prevErrors => ({ ...prevErrors, isVerified: '' }));
    post('auth/verify-captcha', { token })
      .then(() => handleChange({ target: { name: 'isVerified', value: true } }))
      .catch(handleCaptchaError);
  };

  const handleLogin = () => history.push('/login');

  return (
    <div className={classes.background}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.grid}
      >
        <div className={classes.titleContainer}>
          <Title color="secondary" className={classes.title}>
            {I18n.t('signup.title')}
          </Title>
        </div>
        <ul className={classes.info}>
          {I18n.t('signup.captions').map((tip, index) => (
            <li key={index} className={classes.tipItem}>
              <p>{tip}</p>
            </li>
          ))}
        </ul>
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.inputLogin}>
            <LoginInput
              name="firstName"
              value={values.firstName}
              label={I18n.t('signup.inputs.firstName')}
              type="text"
              error={errors.firstName}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <div style={{ width: 20 }} />
            <LoginInput
              error={errors.lastName}
              name="lastName"
              label={I18n.t('signup.inputs.lastName')}
              type="text"
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={values.lastName}
            />
          </div>
          <div className={classes.inputLogin}>
            <LoginInput
              error={errors.email}
              name="email"
              label={I18n.t('signup.inputs.email')}
              type="text"
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={values.email}
            />
          </div>
          <div className={classes.inputLogin}>
            <PasswordInput
              error={errors.password}
              name="password"
              label={I18n.t('signup.inputs.password')}
              hideStartAdornment
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={values.password}
              helperText={
                <span className={classes.passwordCaption}>{I18n.t('signup.passwordCaption')}</span>
              }
            />
          </div>

          <FormControlLabel
            label={"Ho 14 anni o più"}
            control={<Checkbox name={"iAmOver14"} onClick={(event) => {
              handleChange({ target: { name: 'iAmOver14', value: event.target.checked } });
              if(event.target.checked)
                setErrors(prevErrors => ({ ...prevErrors, iAmOver14: '' }));
            }}/>}
          />

          <Typography style={{ textAlign: 'left', fontSize: 12, marginTop: '0.5rem', marginBottom: '0.5rem', color: 'red', display: !errors.iAmOver14 ? 'none' : 'block'  }}>Per accedere a Testbridge devi aver compiuto 14 anni. Per registrarti, chiedi ai tuoi genitori di inviarci una richiesta via mail a info@testbridge.it </Typography>
          <div>
            <Typography style={{ textAlign: 'left', fontSize: 15}}>Cliccando su "Continua", dichiaro di:</Typography>
            <ul style={{ textAlign: 'left', marginTop: 2}}>
              <li><Typography style={{fontSize: 15}}>accettare i <a href={`${config.termsUrl}`} rel="noopener noreferrer" target="_blank">Termini di servizio;</a></Typography></li>
              <li><Typography style={{fontSize: 15}}>aver preso visione della <a href={`${config.privacyUrl}` } rel="noopener noreferrer" target="_blank">Privacy Policy</a> e di acconsentire al trattamento dei miei dati personali</Typography></li>
            </ul>
          </div>

          <div className={classes.actions}>
            <div className={classes.hCaptcha}>
              <HCaptcha sitekey="43b22130-1034-4958-bfe5-551563b863b5" onVerify={onVerifyCaptcha} />
          </div>
            {/* <Button onClick={() => handleChange({ target: { name: 'isVerified', value: true } })}>
              Verify
            </Button> */}

            <SecondaryButton type="submit" onClick={handleSubmit} loading={submitting}>
              {I18n.t('signup.buttons.signup')}
            </SecondaryButton>
          </div>
          {errors.isVerified && (
            <Typography className={classes.captchaError}>{errors.isVerified}</Typography>
          )}
        </form>
        <div className={classes.loginTextContainer}>
          <Typography component="span" className={classes.loginTitle}>
            {I18n.t('signup.loginCaption')}{' '}
          </Typography>
          <span className={classes.textAction} style={{ marginTop: 5 }} onClick={handleLogin}>
            {I18n.t('login.buttons.login')}
          </span>
        </div>
      </Grid>
      <SuccessToast
        open={successMessage !== ''}
        duration={2000}
        onClose={onCloseToast}
        text={successMessage}
      />
      <CookieBanner/>
    </div>
  );
};

export default SignUp;
