import React, { useEffect, useState } from 'react';
import { FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import { get } from '../../../../utils/network';
import TextPlaceholder from '../../../../components/text-placeholder';

const useStyles = makeStyles(theme => ({
  levels: {
    marginTop: 20,
  },
  loadingContainer: {
    marginTop: 20,
  },
}));

const LevelSelector = ({ selected, onChange }) => {
  const classes = useStyles();
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    get('courses')
      .then(res => {
        const sortedCourses = res.message.data.sort((a, b) => a.name.localeCompare(b.name));
        setCourses(sortedCourses);
      })
      .finally(() => setLoading(false));
  }, []);

  return loading ? (
    <div className={classes.loadingContainer}>
      <TextPlaceholder amount={6} />
    </div>
  ) : (
    <RadioGroup value={selected} onChange={onChange} className={classes.levels}>
      {courses.map(course => (
        <FormControlLabel
          key={course._id}
          value={course._id + ''}
          control={<Radio size="small" />}
          label={`${course.name} - ${course.exam}`}
        />
      ))}
      <FormControlLabel
        value= {"idontknow"}
        control={<Radio size="small" />}
        label="I don't know"
      />
    </RadioGroup>
  );
};

export default LevelSelector;
