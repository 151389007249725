import React from 'react';
import { makeStyles } from '@material-ui/core';
import Exercise from '../exercise';
import { useExercise } from '../../../../../hooks';
import TextPlaceholder from '../../../../../components/text-placeholder';
import I18n from '../../../../../lang/i18n';
import { interpolateComponent } from '../utils';
import InterpolatedTextInput from '../../components/interpolated-text-input';
import {formatAnswersBody} from '../utils';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginTop: 24,
  },
  keywordList: {
    flex: 1,
    borderLeft: `1px solid ${theme.palette.grey.light}`,
    padding: '0 20px',
    maxHeight: '50vh',
    overflow: 'auto',
    minWidth: 'fit-content',
    maxWidth: 290,
    paddingRight: 24,
  },
  inputRoot: {
    borderRadius: 4,
  },
  notchedOutline: {
    borderColor: theme.palette.accent.main,
  },
  input: {
    textAlign: 'center',
    fontWeight: 600,
    padding: '6px 0',
    marginTop: 3,
  },
  inputCorrect: {
    borderColor: theme.palette.state.success,
  },
  inputIncorrect: {
    borderColor: theme.palette.state.failure,
  },
  keywordContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '24px 0',
    fontSize: 16,
  },
  keywordIndex: {
    fontWeight: 600,
    margin: 0,
    marginRight: 14,
  },
  keyword: {
    margin: 0,
  },
}));

const formatAnswersResponse = content => {
  return content.map(c => ({
    questionId: c._id,
    wordPosition: c.wordPosition,
    answer: '',
  }));
};


const Keyword = ({ index, keyword }) => {
  const classes = useStyles();
  return (
    <div className={classes.keywordContainer}>
      <p className={classes.keywordIndex}>{index}</p>
      <p className={classes.keyword}>{keyword}</p>
    </div>
  );
};

const ReadingKeyword = ({ data, reviewUnitId, reviewUnitToken, onSuccessSubmit, handleChange, handleEndReview }) => {
  const classes = useStyles();
  const reviewUnit = {
    exercise: data,
    reviewUnitId,
    reviewUnitToken,
  };

  const handleSuccessSubmit = (statusCode, data) => {
    if (onSuccessSubmit) onSuccessSubmit(statusCode, data);
  };

  const {
    loading,
    submitting,
    exercise,
    answers,
    result,
    handleChangeAnswer,
    handleClean,
    handleSubmit,
    timer,
  } = useExercise({ formatAnswersResponse, reviewUnit, onSuccessSubmit: handleSuccessSubmit, formatAnswersBody, });

  const interpolateInputs = exercise => {
    return interpolateComponent({
      exercise,
      answers,
      result,
      gapComponent: InterpolatedTextInput,
      gapComponentProps: { handleChangeAnswer },
    });
  };

  const renderKeywords = exercise => (
    <div className={classes.keywordList}>
      <p className={classes.title}>{I18n.t('courseJourney.exercise.keywordList')}</p>
      <div>
        {loading ? (
          <TextPlaceholder width={200} amount={5} />
        ) : (
          exercise?.content.map((c, index) => <Keyword index={index + 1} keyword={c.keyword} />)
        )}
      </div>
    </div>
  );

  return (
    <Exercise
      exercise={exercise}
      loading={loading}
      submitting={submitting}
      renderText={interpolateInputs}
      handleSubmit={handleSubmit}
      handleRetry={handleClean}
      handleChange={handleChange}
      handleEndReview = {handleEndReview}
      renderRight={renderKeywords}
      finalResult={result}
      timer={timer}
      isReviewUnit={!!reviewUnitId}
    />
  );
};

export default ReadingKeyword;
