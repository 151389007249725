import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell as MUITableCell,
  TableContainer,
  TableHead,
  TableRow as MUITableRow,
  withStyles,
} from '@material-ui/core';
import { Surface, SurfacePage, Title, TitleButton, Loading } from '../../../components';
import { get } from '../../../utils/network';
import I18n from '../../../lang/i18n';
import { getCookie } from '../../../utils/utils';
import ReactMarkdown from 'react-markdown';
import BookALessonButton from '../book-lesson/components/book-a-lesson-button';

const useStyles = makeStyles(theme => ({
  search: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0',
    flexWrap: 'wrap',
  },
  tableCell: {
    border: 'none',
    padding: '14px 20px',
    [theme.breakpoints.down(600)]: {
      padding: '14px 8px',
    },
  },
  tableContainer: {
    marginTop: 50,
    marginBottom: 20,
    width: '95%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  unusedLessonsTime: {
    fontSize: 18,
    marginTop: 10,
    opacity: 0.8,
    color: theme.palette.text.primary,
  },
  courseElementTextColor: {
    color: "#22282f"
  }
}));

const TableCell = withStyles(theme => {
  return {
    head: {
      backgroundColor: '#FFF',
      color: theme.palette.text.primary,
      fontWeight: 600,
      fontSize: 16,
      padding: 12,
    },
    body: {
      fontSize: 16,
      '&:not(:last-child)': {
        borderRight: `1px solid ${theme.palette.grey.light}`,
      },
      verticalAlign: 'baseline',
      borderBottom: 'none',
      padding: 12,
      minHeight: 54,
    },
  };
})(MUITableCell);

const TableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(MUITableRow);

const CourseMap = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    get(`courses/${getCookie('courseId')}/coursemap`)
      .then(res => setData(res.message.data))
      .finally(() => setLoading(false));
  }, []);

  const renderCourseMapData = data =>
    data?.length > 0
      ? data.map(text => (
          <ReactMarkdown 
          components={{
            p: ({node, ...props}) => <p style={{margin: '2px'}} className={classes.courseElementTextColor} {...props} />
          }}
          key={text}>
            {text}
          </ReactMarkdown>
        ))
      : '-';

  return (
    <SurfacePage>
      <Title withBackArrow> {I18n.t('courseMap.title')}</Title>
      <BookALessonButton/>
      {loading ? (
        <Loading style={{ height: 400 }} />
      ) : (
        <TableContainer component={Surface} className={classes.tableContainer}>
          <Table classes={{ root: classes.table }}>
            <TableHead>
              <TableRow>
                <TableCell>{I18n.t('courseMap.unit')}</TableCell>
                <TableCell>{I18n.t('courseMap.topics')}</TableCell>
                <TableCell>{I18n.t('courseMap.grammar')}</TableCell>
                <TableCell>{I18n.t('courseMap.vocabulary')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(
                (unit, index) =>
                  Object.keys(unit.courseMapData || {}).length > 0 && (
                    <TableRow key={index}>
                      <TableCell>
                        <b>{unit.header.split(" ")[1] || index}</b> <span className={classes.courseElementTextColor}>{unit.name}</span>
                      </TableCell>
                      <TableCell>{renderCourseMapData(unit.courseMapData.topics)}</TableCell>
                      <TableCell>{renderCourseMapData(unit.courseMapData.grammar)}</TableCell>
                      <TableCell>{renderCourseMapData(unit.courseMapData.vocabulary)}</TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </SurfacePage>
  );
};

export default CourseMap;
