import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.2px',
    color: '#22282F',
    margin: 0,
  },

  text: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '28px',
    lineHeight: '42px',
    letterSpacing: '-0.35px',
    color: '#31ADC5',
    margin: 0,
  },

  description: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.2px',
    color: '#75808F',
    margin: 0,
  },
}));

const DasbhoardLabel = ({ title, text, description }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <p className={classes.title}>{title}</p>
      {!!text ? (
        <h2 className={classes.text}>{text}</h2>
      ) : (
        <div style={{ margin: '20px 0px', height: 2, width: 8, backgroundColor: '#31ADC5' }}></div>
      )}
      <p className={classes.description}>{description}</p>
    </div>
  );
};

export default DasbhoardLabel;
