import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Collapse,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  useTheme,
} from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import I18n from '../../../../lang/i18n';
import { FeedbackTooltip } from './feedback'

const useStyles = makeStyles(theme => ({
  questionContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '4px 0',
    fontSize: 16,
    borderRadius: 4,
    padding: '10px 16px',
    boxShadow: '0 2px 8px 0 rgba(23, 26, 31, 0.1)',
    backgroundColor: '#FFF',
    marginRight: 24,
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: '#e5f2ff',
    },
  },
  questionIndex: {
    fontWeight: 600,
    margin: 0,
    marginRight: 14,
  },
  question: {
    margin: 0,
  },
  questionCheck: {
    margin: 0,
    fontWeight: 600,
  },
  radioGroup: {
    marginBottom: 20,
    marginTop: 10,
  },
  checkIcon: {
    marginLeft: 5,
    color: theme.palette.state.success,
  },
  closeIcon: {
    marginLeft: 5,
    color: theme.palette.state.failure,
  },
  optionWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Question = ({
  index,
  question,
  selected,
  isCorrect = null,
  explanation,
  correctOption,
  options,
  handleCheck,
  children,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const toggleAnswers = () => setOpen(prevOpen => !prevOpen);
  return (
    <>
      <div className={classes.questionContainer} onClick={toggleAnswers}>
        <p className={classes.questionIndex}>{index}</p>
        <p className={classes.question}>{question}</p>
      </div>
      <Collapse in={open}>
        {children}
        <RadioGroup
          value={selected}
          onChange={isCorrect === null && handleCheck}
          className={classes.radioGroup}
        >
          {options.map((option, index) => {
            const value = (index + 1).toString();
            return isCorrect !== null && selected === value ? (
              <FeedbackTooltip isCorrect={isCorrect} feedback={{ explanation: explanation, correctAnswer: options[correctOption-1] }}>
                <FormControlLabel
                  value={value}
                  control={
                    <Radio
                      size="small"
                      style={{
                        pointerEvents: 'none',
                        color: isCorrect
                          ? theme.palette.state.success
                          : theme.palette.state.failure,
                      }}
                    />
                  }
                  style={{ marginLeft: 0 }}
                  label={
                    <div className={classes.optionWithIcon}>
                      {option}
                      {isCorrect ? (
                        <Check className={classes.checkIcon} />
                      ) : (
                        <Close className={classes.closeIcon} />
                      )}
                    </div>
                  }
                />
              </FeedbackTooltip>
            ) : (
              <FormControlLabel
                value={value}
                control={<Radio size="small" />}
                style={{ marginLeft: 0 }}
                label={option}
              />
            );
          })}
        </RadioGroup>
      </Collapse>
    </>
  );
};

const QuestionCheck = ({ index, id, selected, handleSelectQuestion, text, isWithRadio = true }) => {
  const classes = useStyles();
  const handleChange = e => handleSelectQuestion(e.target.value);
  const [withRadio,] = useState(isWithRadio);

  useEffect (()=> {
    if(!withRadio){
      handleSelectQuestion(id)
    }
  },[])


  return (
    <>
      {withRadio && (<div
        className={classes.questionContainer}
        style={{ marginLeft: 5, marginRight: 15, padding: '4px 16px'}}>
      
        <p className={classes.questionCheck}>
          {I18n.t('questionCheck.question')} {index}
        </p>
        <div style={{ flex: 1 }} />
        <span>{I18n.t('questionCheck.choose')}</span>
        <Radio size="small" value={id} checked={selected === id } onChange={handleChange} />
        </div>)}

      <Collapse in={selected === id}>
        <div style={{ padding: '0 10px' }}>
          <ReactMarkdown>{text}</ReactMarkdown>
        </div>
      </Collapse>
    </>
  );
};

export { Question, QuestionCheck };
