import React from 'react';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell as MUITableCell,
  TableContainer,
  TableHead,
  TableRow as MUITableRow,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import TextPlaceholder from '../../../../components/text-placeholder';
import { FeedbackTooltip } from './feedback';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    padding: '15px 20px 15px 0',
    overflowX: 'auto',
  },
  questionCell: {
    display: 'flex',
    alignItems: 'center',
  },
  checkIcon: {
    marginRight: 5,
    color: theme.palette.state.success,
  },
  closeIcon: {
    marginRight: 5,
    color: theme.palette.state.failure,
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '20px 0',
    paddingRight: 20,
  },
}));

const TableCell = withStyles(theme => {
  return {
    head: {
      backgroundColor: '#FFF',
      color: theme.palette.text.primary,
      fontWeight: 600,
      fontSize: 16,
      borderRight: `1px solid ${theme.palette.grey.light}`,
      textAlign: 'center',
      padding: 12,
    },
    body: {
      fontSize: 16,
      borderRight: `1px solid ${theme.palette.grey.light}`,
      borderBottom: 'none',
      padding: 12,
      minHeight: 54,
    },
  };
})(MUITableCell);

const TableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(MUITableRow);

const CheckCell = ({ checked, isCorrect = null, handleCheck }) => {
  return (
    <TableCell onClick={isCorrect === null && handleCheck} style={{ textAlign: 'center' }}>
      {checked && <Check color="secondary" />}
    </TableCell>
  );
};

const SpeakersTable = ({ loading, speakers, rows, isChecked, handleCheck, isCorrectFunction, correctAnswerFunction }) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down(780));
  return loading ? (
    <div className={classes.loadingContainer}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
        <TextPlaceholder width={smallScreen ? '100%' : 100} amount={5} />
      </div>
      <TextPlaceholder amount={6} />
    </div>
  ) : (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {
            speakers.map((speaker, index) =>{
              const isCorrect = isCorrectFunction(speaker._id);
              return (
                <FeedbackTooltip isCorrect={isCorrect} feedback={{ explanation: speaker.explanation, correctAnswer: correctAnswerFunction(speaker._id, rows) }}>                  
                  <TableCell>
                        {isCorrect === null ? (
                          ''
                        ) : (isCorrect) ? (
                          <Check className={classes.checkIcon} />
                        ) : (
                          <Close className={classes.closeIcon} />
                        )}
                        Speaker {index + 1}</TableCell>
                </FeedbackTooltip>
                )
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, indexRow) => {

            return (
              <TableRow >
                
                  <TableCell className={classes.questionCell}>
                    {row}
                  </TableCell>
                {speakers.map((speaker, index) => {
                  const isCorrect = isCorrectFunction(speaker._id)
                  return (
                    <CheckCell
                      key={index}
                      isCorrect={isCorrect}
                      handleCheck={handleCheck(speaker._id, indexRow + 1)}
                      checked={ isChecked(speaker._id, indexRow + 1) }
                    />
                  )
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SpeakersTable;
