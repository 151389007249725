import React from 'react';
import { getCookie } from '../../../utils/utils';
import StudentHome from './student-home';
import TeacherHome from './teacher-home';

const Home = () => {
  return getCookie('isTeacher') ? <TeacherHome /> : <StudentHome />;
};

export default Home;
