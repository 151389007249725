import React from 'react';
import { Select } from '../../../../components';
import { FeedbackTooltip } from './feedback';

const InterpolatedSelect = ({
  answer,
  indexSelectFlag,
  isCorrect,
  feedback,
  handleChangeAnswer,
  exercise,
}) => (
  <FeedbackTooltip isCorrect={isCorrect} feedback={feedback}>
    <div style={{ display: 'inline' }}>
      <Select
        value={answer?.answer}
        onChange={handleChangeAnswer(answer.questionId)}
        options={exercise.content[indexSelectFlag].options}
        isCorrect={isCorrect}
      />{' '}
    </div>
  </FeedbackTooltip>
);

export default InterpolatedSelect;
