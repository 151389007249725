import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import useQueryParams from './use-query-params';
import { get, post } from '../utils/network';
import {
  setUnitReviewInLocalStorage,
  getUnitReviewInLocalStorage,
  getDecodedAnserData,
} from '../utils/utils';
import useExerciseTimer from './use-exercise-timer';

const useExercise = ({ formatAnswersResponse, onSuccessSubmit, errorHandlers, formatAnswersBody, reviewUnit, resetAnswersOnRetry = true }) => {
  const { id } = useParams();
  const queryParams = useQueryParams();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [exercise, setExercise] = useState(reviewUnit?.exercise || null);
  const [answers, setAnswers] = useState(null);
  const [result, setResult] = useState(null);
  const timer = useExerciseTimer();

  const handleSubmit = () => {
    timer.pause();
    post(
      reviewUnit?.reviewUnitId ? `units/${reviewUnit.reviewUnitId}/review` : 'exercise-answers',
      {
        exerciseId: exercise._id,
        answers: formatAnswersBody ? formatAnswersBody(answers) : answers,
        unitId: reviewUnit?.reviewUnitToken ? undefined : queryParams.get('unitId'),
        timeTaken: timer.getTotalSeconds(),
        reviewUnitToken: reviewUnit?.reviewUnitToken || undefined,
      },
      { errorHandlers: errorHandlers ? errorHandlers : [] }
    )
      .then(res => {
        const {correctAnswers, answers, score } = getDecodedAnserData(res.message.data.reviewUnitToken) || {};
        if (res.statusCode === 201) {
          onSuccessSubmit(res.message.data);
          res.message.data.final = true;
        }
        reviewUnit?.reviewUnitId ? setResult({...res.message.data, answers, correctAnswers, score }) : setResult(res.message.data)

        if (getUnitReviewInLocalStorage(id)) {
          const unitReviewFromLS = getUnitReviewInLocalStorage(id);
          const timeTaken = unitReviewFromLS?.data?.timeTaken;
          unitReviewFromLS.data = res.message.data;
          unitReviewFromLS.data.timeTaken = timeTaken ? timeTaken : 0;
          setUnitReviewInLocalStorage(id, unitReviewFromLS);
        }
      })
      .finally(() => setSubmitting(false));
  };

  const handleClean = () => {
    setLoading(false);
    setSubmitting(false);
    setResult(null);
    if (resetAnswersOnRetry)
      setAnswers(formatAnswersResponse(exercise.content));
  };

  const handleChangeAnswer = questionId => e => {
    const value = e.target?.value;
    setAnswers(prevAnswers =>
      prevAnswers.map(answer => {
        if (answer.questionId === questionId) return { ...answer, answer: value ? value : '' };
        return answer;
      })
    );
  };

  useEffect(() => {
    if (!reviewUnit?.exercise) {
      get(`exercises/${id}`)
        .then(res => {
          setExercise(res.message.data);
          setAnswers(formatAnswersResponse(res.message.data.content));
        })
        .then(() => setLoading(false));
    } else {
      setLoading(false);
      setAnswers(formatAnswersResponse(exercise.content));
    }
    timer.resume();
  }, [id, formatAnswersResponse]);

  return {
    loading,
    submitting,
    exercise,
    answers,
    result,
    timer,
    setAnswers,
    handleClean,
    handleSubmit,
    handleChangeAnswer,
  };
};

export default useExercise;
