import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  surface: {
    padding: '32px 0',
    display: 'flex',
    flexWrap: 'wrap',
  },
  centerContainer: {
    padding: '0 56px',
    margin: '0 auto',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px',
    },
  },
}));

const Surface = ({ children, className, style = {} }) => {
  return (
    <Paper style={{ boxShadow: '0 8px 16px 0 rgb(0 0 0 / 10%)', ...style }} className={className}>
      {children}
    </Paper>
  );
};

const SurfacePage = ({
  children,
  centerContainer = true,
  maxWidth = 1400,
  className,
  style = {},
}) => {
  const classes = useStyles();
  return (
    <Paper
      style={{ boxShadow: '0 8px 16px 0 rgb(0 0 0 / 10%)', ...style }}
      className={className || classes.surface}
    >
      {centerContainer ? (
        <div style={{ maxWidth }} className={classes.centerContainer}>
          {children}
        </div>
      ) : (
        children
      )}
    </Paper>
  );
};

export { Surface, SurfacePage };
