import { makeStyles } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';
import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { getCookie } from '../../../../utils/utils';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 24px 32px',
    gap: '16px',
    width: '400px',
    height: '382px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 24px rgba(23, 26, 31, 0.1)',
    borderRadius: '10px',
  },

  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '-0.2px',
    color: '#22282F',
    margin: 0,
  },

  secondTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.2px',
    color: '#22282F',
    marginTop: '-5px',
  },

  btn: {
    padding: '0px 10px',
    height: '32px',
    background: '#EBFFFA',
    borderRadius: '24px',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: darken('#EBFFFA', 0.2),
    },

    //label
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.2px',
    color: '#156085',
  },

  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },

  subtitleGrey: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.2px',
    color: '#75808F',
    marginTop: '-15px',
  },

  imgContainer: {
    width: '100%',
    height: 120,
    overflow: 'hidden',
  },

  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  progressiveUnit: {
    height: '85px',
    width: '85px',
    fontWeight: 600,
    fontSize: 16,
  },

  btnDisabled: {
    backgroundColor: '#CCCCCC', // Cambia el color del botón deshabilitado según tus necesidades
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: '#CCCCCC', // Opcional: puedes establecer el mismo color para el hover deshabilitado
    },
  },

  tooltip: {
    fontSize: '14px',
    color: '#FFFFFF', // Personaliza el color del texto del tooltip según tus necesidades
    padding: '6px 10px',
  },
}));

const CardUnit = ({
  title,
  subtitle,
  unitName,
  btnName,
  handleClick,
  unit,
  order,
  img,
  onSkip,
  phraseUnit,
  progressUnit,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const courseId = getCookie('courseId');
  const courseName = getCookie('courseName');

  const classes = useStyles();
  const handleSkip = () => {
    onSkip();
  };

  useEffect(() => {
    const image = new Image();
    image.src = img;
    if(!courseId && !courseName){
      setIsButtonDisabled(true);
      console.log("test");
    }
    image.onload = () => {
      setIsLoaded(true);
    };
  }, [img]);
  return (
    <div className={classes.container} style={{ order: order }}>
      <h1 className={classes.title}>{title}</h1>
      <div className={classes.imgContainer}>
        {isLoaded && <img className={classes.img} src={img} alt={img} />}
      </div>
      <h1 className={classes.secondTitle}>{unit?.header}</h1>
      <div className={classes.contentContainer}>
        <div className={classes.leftContainer}>
          <p className={classes.subtitleGrey}>{unit?.name}</p>
          <CircularProgressbar
            className={classes.progressiveUnit}
            value={Number(progressUnit.toFixed(1))}
            text={`${Number(progressUnit.toFixed(1))}%`}
            strokeWidth={9}
            styles={buildStyles({
              strokeLinecap: 'round',
              pathColor: '#FF9F19',
              textColor: '#FF9F19',
            })}
          />
        </div>
        <div className={classes.rightContainer}>
        {isButtonDisabled ? (
        <Tooltip title="Please take the Placement Test to unlock it." className={classes.tooltip}>
          <span>
            <button
              disabled={isButtonDisabled}
              className={`${classes.btn} ${classes.btnDisabled}`}
              onClick={handleClick}
            >
              {btnName}
            </button>
          </span>
        </Tooltip>
      ) : (
        <button
          disabled={isButtonDisabled}
          className={classes.btn}
          onClick={handleClick}
        >
          {btnName}
        </button>
      )}
        </div>
      </div>
    </div>
  );
};

export default CardUnit;
