import { Box, IconButton, Typography, withStyles } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React, { useState } from 'react';
import { getLetterScore } from '../../../../utils/utils';
import CalendarColumn from './calendar-column';
import DasbhoardLabel from './dashboard-label';

const useStyles = makeStyles(theme => ({
  titleCard: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '-0.2px',
    color: '#22282F',
    margin: '0',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px 24px 32px',
    gap: '16px',
    width: '400px',
    height: '414px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 24px rgba(23, 26, 31, 0.1)',
    borderRadius: '10px',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    width: '100%',
  },

  month: {
    fontSize: 16,
  },

  weekContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },

  containerLessons: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 16px',
  },
}));

const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const StyledIconButton = withStyles({
  root: {
    alignSelf: 'start',
    padding: 4,
    color: '#2E353F', // This will be the color when it's enabled
    '&$disabled': {
      color: '#AAA', // This will be the color when it's disabled
    },
  },
  disabled: {}, // We need this line to create the $disabled class
})(IconButton);

const getStartOfWeek = () => {
  // Sun, Mon, Tue, Wed, Thu, Fri, Sat
  if (moment().isoWeekday() === 7) return moment();
  else return moment().startOf('isoWeek').subtract(1, 'day');
};

const CardStatistics = ({ data }) => {
  const classes = useStyles();
  const [currentWeek, setCurrentWeek] = useState(getStartOfWeek());

  const handleChangeWeek = direction => {
    setCurrentWeek(moment(currentWeek).add(direction === 'prev' ? -7 : 7, 'days'));
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <h1 className={classes.titleCard}>{'Statistics'}</h1>
        <Typography className={classes.month}>{currentWeek.format('MMMM')}</Typography>
      </Box>
      <Box className={classes.weekContainer}>
        <StyledIconButton
          style={{ alignSelf: 'start', padding: 2 }}
          // We can see just one month
          disabled={moment().diff(currentWeek, 'months', true) > 1}
          onClick={() => handleChangeWeek('prev')}
        >
          <ChevronLeft fontSize="small" />
        </StyledIconButton>
        {data?.dailyExerciseCount &&
          daysOfWeek.map((dayOfWeek, index) => (
            <CalendarColumn
              currentWeek={currentWeek}
              dayOfWeek={dayOfWeek}
              index={index}
              key={index}
              data={data.dailyExerciseCount}
            />
          ))}
        <StyledIconButton
          style={{ alignSelf: 'start', padding: 2 }}
          // We can't see the future
          disabled={currentWeek.format('DDMMYYYY') === getStartOfWeek().format('DDMMYYYY')}
          onClick={() => handleChangeWeek('next')}
        >
          <ChevronRight fontSize="small" />
        </StyledIconButton>
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div className={classes.containerLessons}>
          <DasbhoardLabel title={'Views (videos)'} text={data?.completedLessonsCount} />
          <DasbhoardLabel title={'Online lessons'} text={data?.bookedLessonsCount} />
        </div>
        <div className={classes.containerLessons}>
          <DasbhoardLabel title={'Exercises'} text={data?.exerciseCount} />
          <DasbhoardLabel
            title={'Overall grade'}
            text={data?.reviewWithProgress ? getLetterScore(data?.overallScore) : ''}
          />
        </div>
      </Box>
    </Box>
  );
};

export default CardStatistics;
