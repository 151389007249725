import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Exercise from '../exercise';
import { QuestionCheck } from '../../components/question';
import QuestionPlaceholder from '../../components/placeholders/question-placeholder';
import TextAreaAnswer from '../../components/text-area-answer';
import { SmallButton } from '../../../../../components';
import TextAreaAnswerPlaceholder from '../../components/placeholders/text-area-answer-placeholder';
import I18n from '../../../../../lang/i18n';
import { useExercise } from '../../../../../hooks';
import WritingFeedback from '../../components/writingFeedback';
import { useRef } from 'react';

const useStyles = makeStyles(theme => ({
  questionList: {
    flex: 1,
    maxHeight: '50vh',
    overflow: 'inherit',
    paddingTop: 15,
  },
  textArea: {
    flex: 1,
    paddingBottom: 10,
    paddingTop: 25,
    paddingRight: 20,
  },
}));

const formatAnswersResponse = content => {
  return content.map(c => ({
    questionId: c._id,
    answer: '',
  }));
};

const Writing = ({ data, reviewUnitId, reviewUnitToken,  onSuccessSubmit, handleChange, handleEndReview }) => {
  const classes = useStyles();
  const reviewUnit = {
    exercise: data,
    reviewUnitId,
    reviewUnitToken,
  };
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [feedback, setFeedback] = useState(null);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false); 
  const feedbackAnchorEl = useRef(null);

  const handleSuccessSubmit = (data) => {
    if (onSuccessSubmit) {
      onSuccessSubmit(data);
    }
    setFeedback(null);
    setFeedbackModalOpen(true);
  };

  const handle400 = async (res) => await res.text().then((data) => {
    const error = JSON.parse(data);
    if (error?.fields && ["word_limit_error", "gpt_attempts_limit"].includes(error.fields[0]?.error_type)){
      setFeedback({ text: error.fields[0].message, type: "error" });
      setFeedbackModalOpen(true);
    } else if(["openai_error"].includes(error.fields[0]?.error_type)){
      setFeedback({ text: "An unexpected error ocurred. Please try again later.", type: "error" });
      setFeedbackModalOpen(true);
    }

    timer.resume();

    return Promise.reject(400);
  })

  const {
    loading,
    submitting,
    exercise,
    answers,
    result,
    handleChangeAnswer,
    handleClean,
    handleSubmit,
    setAnswers,
    timer,
  } = useExercise({ 
    formatAnswersResponse,
    onSuccessSubmit: handleSuccessSubmit, 
    errorHandlers: [{ status: 400, method: handle400 }],
    reviewUnit,
    resetAnswersOnRetry: false,
  });

  const handleSelectQuestion = questionId => {
    setAnswers(formatAnswersResponse(exercise.content));
    setSelectedQuestion(questionId);
  };

  const renderQuestions = exercise => (
    <div className={classes.questionList}>
      {loading ? (
        <QuestionPlaceholder amount={2} />
      ) : (
        <div>
          {exercise?.content.map((question, index) => (
            <QuestionCheck
              key={question._id}
              id={question._id}
              index={index + 1}
              selected={selectedQuestion}
              handleSelectQuestion={handleSelectQuestion}
              text={question.assignment + '\n\n' + (question.example ? question.example : "")}
              isWithRadio={!exercise.name.split(" ")[0] === 'Unit' }
            />
          ))}
        </div>
      )}
      <div style={{ height: 20 }} />
    </div>
  );

  const feedbackAvailable = () => result?.answers[0]?.feedback || feedback?.text

  const toggleFeedbackModal = () => setFeedbackModalOpen(!feedbackModalOpen);

  const closeFeedbackModal = useCallback(() => setFeedbackModalOpen(false), [feedbackModalOpen]);

  const renderTextAreaAnswer = () => (
    <div className={classes.textArea} ref={feedbackAnchorEl}>
      {loading ? (
        <TextAreaAnswerPlaceholder />
      ) : (
        <>
          <TextAreaAnswer
            answer={
              answers
                ? answers.find(a => a.questionId === selectedQuestion)?.answer || answers[0].answer
                : ''
            }
            handleChange={handleChangeAnswer(selectedQuestion)}
            selectedQuestion={selectedQuestion}
          />
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <Grid item xs={6} height='100%'>
              { !feedbackAvailable() ? null : (
                <SmallButton 
                  variant='tertiary'
                  color='primary'
                  onClick={toggleFeedbackModal}
                > 
                  { feedbackModalOpen ? ('Hide feedback') : ('View feedback')}
                </SmallButton>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );

  const modalFeedback = useMemo(() => result?.answers[0]?.feedback ? { text: result?.answers[0]?.feedback, type: 'success' } : { text: feedback?.text, type: feedback?.type }, [feedback, result]);

  return (
    <>
      <Exercise
        exercise={exercise}
        loading={loading}
        submitting={submitting}
        handleSubmit={async () => {
          setFeedback({text: "**" + I18n.t('courseJourney.exercise.feedback.writing.loading') + "**", type: 'loading' });
          setFeedbackModalOpen(true);
          handleSubmit() 
        }}
        handleRetry={handleClean}
        handleChange={handleChange}
        handleEndReview = {handleEndReview}
        renderRight={renderTextAreaAnswer}
        renderText={renderQuestions}
        finalResult={result}
        timer={timer}
        isReviewUnit={!!reviewUnitId}
        hideFeedbackTutorial={true}
        correctAnswersText={I18n.t('courseJourney.exercise.score')}
      />
      <WritingFeedback 
        anchorEl={feedbackAnchorEl}
        open={feedbackModalOpen && feedbackAvailable()}
        feedback={ modalFeedback }
        closeModal={closeFeedbackModal}
      />
    </>
  );
};

export default Writing;
