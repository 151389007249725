import { enUS, esES } from '@material-ui/core/locale';
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/es';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import I18n from './lang/i18n';
import Routes from './routes';

let theme = createMuiTheme(
  {
    palette: {
      secondary: {
        main: '#31adc5',
        contrastText: '#FFFFFF',
      },
      primary: {
        main: '#00204a',
        contrastText: '#FFFFFF',
      },
      accent: {
        main: '#2b78ff',
      },
      progress: {
        green: '#4dd63b',
        yellow: '#ffc700',
        orange: '#ff9345',
        red: '#ff3a5e',
      },
      state: {
        success: '#56e041',
        failure: '#ff3a5e',
      },
      grey: {
        main: '#75808f',
        light: '#d2d7da',
      },
    },
    shape: {
      borderRadius: 10,
    },
    typography: {
      // Use the system font instead of the default Roboto font.
      fontFamily: ['"Poppins"', 'sans-serif'].join(','),
      useNextVariants: true,
      button: {
        textTransform: 'none',
      },
      allVariants: {
        color: '#22282f',
      },
    },
  },
  I18n.locale === 'es' ? esES : enUS
);

theme = responsiveFontSizes(theme);
moment.locale(I18n.locale);

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <Routes />
      </DndProvider>
    </MuiThemeProvider>
  );
}

export default App;
