import React from 'react';
import { FormControl, makeStyles, MenuItem, Select as MUISelect, useTheme } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import I18n from '../lang/i18n';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: props => props.minWidth,
    marginTop: 3,
    margin: '0 6px',
  },
  icon: {
    marginRight: 4,
    fill: props => props.color,
  },
  iconCorrect: {
    marginRight: 4,
    fill: theme.palette.state.success,
  },
  iconIncorrect: {
    marginRight: 4,
    fill: theme.palette.state.failure,
  },
  selectClass: {
    paddingLeft: props => props.paddingLeft,
    backgroundColor: 'transparent',
    borderRadius: 4,
    border: 'none',
  },
  selectMenu: {
    cursor: 'pointer',
    borderRadius: 4,
    fontSize: 16,
    boxShadow: '0 -4px 24px 0 rgba(23, 26, 31, 0.1)',
    '& option': {
      padding: '6px 16px',
    },
    '& option:hover': {
      background: '#f0f2f4',
    },
  },
  select: {
    '&:before': {
      borderColor: props => props.color,
    },
    '&:after': {
      borderColor: props => props.color,
    },
  },
  selectCorrect: {
    pointerEvents: 'none',
    '&:before': {
      borderColor: theme.palette.state.success,
    },
    '&:after': {
      borderColor: theme.palette.state.success,
    },
  },
  selectIncorrect: {
    pointerEvents: 'none',
    '&:before': {
      borderColor: theme.palette.state.failure,
    },
    '&:after': {
      borderColor: theme.palette.state.failure,
    },
  },
}));

const Placeholder = ({ children }) => {
  const theme = useTheme();
  return <div style={{ color: theme.palette.grey.main }}>{children}</div>;
};

const Select = ({
  value = '',
  onChange,
  isCorrect = null,
  options,
  color = 'accent',
  paddingLeft = 12,
  minWidth = 160,
}) => {
  const theme = useTheme();
  const classes = useStyles({ color: theme.palette[color].main, paddingLeft, minWidth });

  return (
    <FormControl className={classes.formControl}>
      {isCorrect !== null ? (
        <MUISelect
          defaultValue={value}
          value={value}
          displayEmpty
          className={isCorrect ? classes.selectCorrect : classes.selectIncorrect}
          classes={{
            icon: isCorrect ? classes.iconCorrect : classes.iconIncorrect,
            select: classes.selectClass,
          }}
          MenuProps={{
            open: false,
          }}
          IconComponent={isCorrect ? Check : Close}
        >
          <MenuItem value={value}>{value}</MenuItem>
        </MUISelect>
      ) : (
        <MUISelect
          value={value}
          onChange={onChange}
          displayEmpty
          className={classes.select}
          classes={{
            icon: classes.icon,
            select: classes.selectClass,
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            classes: {
              paper: classes.selectMenu,
            },
          }}
          renderValue={
            value !== '' ? null : () => <Placeholder>{I18n.t('select.placeholder')}</Placeholder>
          }
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </MUISelect>
      )}
    </FormControl>
  );
};

export default Select;
