import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useDrag } from 'react-dnd';

const useStyles = makeStyles(theme => ({
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '4px 0',
    fontSize: 16,
    borderRadius: 4,
    padding: '10px 16px',
    boxShadow: '0 2px 8px 0 rgba(23, 26, 31, 0.1)',
    backgroundColor: '#FFF',
    marginRight: 24,
    marginBottom: 20,
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: '#e5f2ff',
    },
    '&p': {
      margin: 0,
    },
  },
}));

const Option = ({ children, index, canDrag }) => {

  const classes = useStyles();
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: 'option',
      item: {
        index,
        option: children,
      },
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
      canDrag: () => canDrag
      ,
    }),
    [canDrag]
  );
  return (
    <div ref={dragRef} style={{ opacity }}>
      <div className={classes.optionContainer}>{children}</div>
    </div>
  );
};

export default Option;
