import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { PlayArrow } from '@material-ui/icons';
import I18n from '../../../../lang/i18n';
import config from '../../../../utils/config';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f1f3f4',
    borderRadius: 30,
  },
  play: {
    width: 25,
    height: 25,
  },
  time: {
    fontSize: 13,
  },
  caption: {
    color: 'grey',
    margin: '5px 10px',
    fontStyle: 'italic',
  },
  tooltip: {
    maxWidth: 'none',
  },
}));

const AudioPlayer = ({ src, counterKey, onStartPlaying, onStopPlaying, anotherTrackPlaying, canPlay, disabled = false }) => {
  const classes = useStyles();
  const audioRef = useRef();
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const [isPlaying, setIsPlaying] = useState(false);

  const currentCounter = Number(window.localStorage.getItem(counterKey)) || 0;

  const onTimeUpdate = () => setCurrentTime(audioRef?.current?.currentTime);

  // revisar si ocultar boton

  const onPlay = () => {
    if(!disabled && currentCounter < config.maxAudioReplay)
    {
      audioRef.current.play();
      setIsPlaying(true);
      onStartPlaying();
    }
  }

  const onEnded = () => {
    setDuration(audioRef?.current?.duration);
    setCurrentTime(0);
    window.localStorage.setItem(counterKey, currentCounter + 1);
    setIsPlaying(false);
    onStopPlaying();
  };

  const onLoadedMetadata = () => setDuration(audioRef?.current?.duration);

  const formatDuration = time => {
    const timeMs = moment.duration(time, 'seconds').asMilliseconds();
    return moment(timeMs).format('mm:ss', { trim: false });
  };

  const playerDisabled = currentCounter >= config.maxAudioReplay;

  useEffect(() => {
    if(!canPlay && isPlaying)
    {
      audioRef.current.pause();
      audioRef.current.load(); //Restart the track to 0
      onEnded();
    }
  });

  return (
    <>
      <audio
        ref={audioRef}
        preload="metadata"
        onLoadedMetadata={onLoadedMetadata}
        onTimeUpdate={onTimeUpdate}
        onEnded={onEnded}
        controlsList="nodownload"
      >
        <source src={src} type="audio/mp3" />
      </audio>
      <div className={classes.container}>
        <Tooltip
          title={disabled ? (anotherTrackPlaying ? I18n.t('courseJourney.exercise.anotherTrackPlaying') : I18n.t('courseJourney.exercise.playerDisabled')) : ''}
          placement="right"
          classes={{ tooltip: classes.tooltip }}
        >
          <IconButton onClick={onPlay} disabled={playerDisabled} style={{opacity: disabled ? 0.5 : 1}}>
            <PlayArrow className={classes.play} />
          </IconButton>
        </Tooltip>

        <p className={classes.time} style={{ color: playerDisabled ? 'grey' : 'inherit' }}>
          {formatDuration(currentTime)} / {formatDuration(duration)}
        </p>
      </div>
      {playerDisabled && (
        <p className={classes.caption}>{I18n.t('courseJourney.exercise.listeningLimit')}</p>
      )}
    </>
  );
};

export default AudioPlayer;
