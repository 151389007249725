import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormControl, FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import {
  SecondaryButton,
  Subtitle,
  SurfacePage,
  TextArea,
  Title,
  Select,
  ErrorToast,
  SuccessToast,
  Loading,
} from '../../../components';
import { get, post } from '../../../utils/network';
import I18n from '../../../lang/i18n';
import { useQueryParams } from '../../../hooks';
import { getCookie, handle402BookLesson } from '../../../utils/utils';
import Swal from 'sweetalert2';

const TOPICS = [
  I18n.t('bookLesson.form.topics.lessonReview'),
  I18n.t('bookLesson.form.topics.practiceSpeaking'),
  I18n.t('bookLesson.form.topics.unitExercisesCorrection'),
  I18n.t('bookLesson.form.topics.examStrategy'),
];

const useStyles = makeStyles(theme => ({
  subtitle: {
    paddingTop: 30,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 10,
    marginTop: 30,
  },
  question: {
    fontSize: 16,
  },
}));

const BookLessonForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const [availableUnits, setAvailableUnits] = useState([]);
  const [unit, setUnit] = useState('');
  const [loading, setLoading] = useState(true);
  const [topic, setTopic] = useState(TOPICS[0]);
  const [comments, setComments] = useState('');
  const [bookError, setBookError] = useState(false);
  const [booking, setBooking] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const queryParams = useQueryParams();


  useEffect(() => {
    [queryParams.get('teacher'), queryParams.get('datetime')].some(param => !param) && history.push('/home/book_lesson/new');
  }, [queryParams, history]);


  useEffect(() => {
    get(`courses/${getCookie('courseId')}`)
      .then(res => {
        setAvailableUnits(res.message.data.units);
        setUnit(res.message.data.units[0]);
      })
      .finally(() => setLoading(false));
  }, []);

  const handle400 = () => {
    setBookError(I18n.t('bookLesson.messages.error'));
    return Promise.reject(400);
  };

  const handleSubmit = () => {
    setBooking(true);
    post(
      'scheduled-lessons',
      {
        teacher: queryParams.get('teacher'),
        datetime: queryParams.get('datetime'),
        unitId: unit._id,
        topic,
        comments,
      },
      { errorHandlers: [{ status: 400, method: handle400 }, {status: 402, method: handle402BookLesson(history)}] }
    )
      .then(res => {
        if (res.message.data.url) {
          window.location.href = res.message.data.url;
        } else {
          setSuccessMessage(I18n.t('bookLesson.messages.successWithSubscription'));
        }
      })
      .catch(() => {})
      .finally(() => setBooking(false));
  };

  const handleUnitChange = event => {
    const unit = event.target.value
    const parts = unit.split(' - ');
    const unitName = parts[1] || parts[0].trim();
    const selected = availableUnits.find(unit => unit.name === unitName || unit.header === unitName);
    setUnit(selected);
  };

  const formatDisplayUnit = (unit) => `${unit?.header} ${unit?.name ? `- ${unit?.name}` : ''}`

  const formattedUnit = formatDisplayUnit(unit);

  return (
    <SurfacePage>
      <Title withBackArrow>{I18n.t('bookLesson.title')}</Title>
      <div style={{ paddingLeft: 35 }}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Subtitle className={classes.subtitle}>{I18n.t('bookLesson.form.unit')}:</Subtitle>
            <p className={classes.question}>{I18n.t('bookLesson.form.unitQuestion')}</p>
            <Select
              color="secondary"
              paddingLeft={0}
              options={availableUnits.map(unit => formatDisplayUnit(unit))}
              value={formattedUnit}
              onChange={handleUnitChange}
              minWidth={300}
            />
            <Subtitle className={classes.subtitle}>{I18n.t('bookLesson.form.topic')}:</Subtitle>
            <p className={classes.question}>{I18n.t('bookLesson.form.topicQuestion')}</p>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="skills"
                defaultValue={TOPICS[0]}
                name="skills"
                value={topic}
                onChange={e => setTopic(e.target.value)}
              >
                {TOPICS.map(skill => (
                  <FormControlLabel
                    key={skill}
                    value={skill}
                    control={<Radio size="small" />}
                    label={skill}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <div>
              <Subtitle className={classes.subtitle}>
                {I18n.t('bookLesson.form.comments')}:
              </Subtitle>
              <TextArea value={comments} handleChange={e => setComments(e.target.value)} />
            </div>
            <div className={classes.actions}>
              <SecondaryButton onClick={handleSubmit} loading={booking}>
                {I18n.t('bookLesson.book')}
              </SecondaryButton>
            </div>
          </>
        )}
      </div>
      <SuccessToast
        open={!!successMessage}
        onClose={() => history.push('/home')}
        text={successMessage}
        duration={3000}
      />
      <ErrorToast open={!!bookError} onClose={() => setBookError(false)} text={bookError} />
    </SurfacePage>
  );
};

export default BookLessonForm;
