import React from 'react';
import TextInput from './text-input';
import { FeedbackTooltip } from './feedback';

const InterpolatedTextInput = ({ answer, indexSelectFlag, isCorrect, feedback, handleChangeAnswer }) => (
  <FeedbackTooltip isCorrect={isCorrect} feedback={feedback}>  
    <div style={{ display: 'inline' }}>
      <TextInput
        value={answer?.answer}
        placeholder={`${indexSelectFlag + 1}`}
        isCorrect={isCorrect}
        handleChange={handleChangeAnswer(answer?.questionId)}
      />{' '}
    </div>
  </FeedbackTooltip>
);

export default InterpolatedTextInput;
