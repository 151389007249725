import React, { Component } from 'react';
import { withTheme } from '@material-ui/core';
import { put } from '../../../../utils/network';
import PasswordResetFormComponent from './component';
import I18n from '../../../../lang/i18n';

const FIELDS = {
  password: I18n.t('passwordResetForm.inputs.newPassword'),
  passwordRepeat: I18n.t('passwordResetForm.inputs.confirmNewPassword'),
};

class PasswordResetContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordRepeat: '',
      errorpassword: '',
      errorpasswordRepeat: '',
      errortoken: '',
      loading: false,
      success: false,
    };
  }

  errorReset = () => {
    this.setState({
      errorpassword: '',
      errorpasswordRepeat: '',
    });
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.errorReset();
    this.setState({ [name]: value });
  };

  checkLength = pass => pass && pass.length >= 8 && pass.length <= 20;

  checkRegex = pass => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(pass);

  checkNoErrors = () => {
    let returnValue;
    if (this.checkLength(this.state.password)) {
      if (this.checkRegex(this.state.password)) {
        returnValue = true;
      } else {
        this.setState({
          errorpassword: I18n.t('passwordResetForm.errors.invalidPassword'),
        });
        returnValue = false;
      }
    } else {
      this.setState({
        errorpassword: I18n.t('passwordResetForm.errors.invalidPasswordLength'),
      });
      returnValue = false;
    }

    if (this.state.password === this.state.passwordRepeat) {
      return returnValue;
    } else {
      this.setState({
        errorpasswordRepeat: I18n.t('passwordResetForm.errors.passwordMismatch'),
      });
      return false;
    }
  };

  handle400 = err => {
    err.text().then(error => {
      let errorObject = JSON.parse(error);
      errorObject.fields.forEach(field => {
        if (field.name === 'token')
          this.setState({
            errortoken: I18n.t('passwordResetForm.errors.invalidLink'),
          });
        else {
          const message = field.message.replace(new RegExp('password'), FIELDS['password']);
          this.setState({ ['error' + field.name]: message });
        }
      });
    });
    return Promise.reject(400);
  };

  handleSubmit = e => {
    e.preventDefault();
    const errorHandlers = [
      { status: 400, method: this.handle400 },
      { status: 401, method: this.handle400 },
    ];
    const hash = this.props.location.search.slice(6); //?hash=asd123
    if (this.checkNoErrors()) {
      this.setState({ loading: true }, () => {
        const body = { password: this.state.password, token: hash };
        put('auth/passwordreset', body, { errorHandlers })
          .then(data => {
            this.setState({ success: true });
          })
          .finally(() => this.setState({ loading: false }));
      });
    }
  };

  handleSuccess = () => this.props.history.push('/');

  render() {
    return (
      <PasswordResetFormComponent
        data={this.state}
        handleSubmit={this.handleSubmit}
        handleChange={this.handleChange}
        handleSuccess={this.handleSuccess}
        onDismissError={() => this.setState({ errortoken: '' })}
      />
    );
  }
}

export default withTheme(PasswordResetContainer);
