export default theme => ({
  background: {
    backgroundColor: '#efefef',
    [theme.breakpoints.down(516)]: {
      backgroundColor: 'white',
    },
  },
  gridLogin: {
    minHeight: '92vh',
  },
  title: {
    textAlign: 'left',
    margin: '0 auto',
  },
  titleContainer: {
    width: 495,
    [theme.breakpoints.down(516)]: {
      width: 'auto',
    },
  },
  formLogin: {
    marginTop: '2.5%',
    marginBottom: '2.5%',
    width: 400,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '35px 50px 35px 50px',
    borderRadius: 8,
    boxShadow: '0 8px 16px 0 rgb(0 0 0 / 10%)',
    [theme.breakpoints.down(516)]: {
      maxWidth: '80%',
      padding: '20px 10px',
      margin: 0,
      boxShadow: 'none',
      borderRadius: 0,
    },
    backgroundColor: 'white',
  },
  inputLogin: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitButton: {
    marginTop: 20,
    marginBottom: 10,
    margin: '0 auto',
    minWidth: 140,
  },
  textAction: {
    border: 'none',
    color: '#55A3F5',
    fontSize: 16,
    fontWeight: 600,
    cursor: 'pointer',
    margin: '20px 0',
  },
  loadingContainer: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    position: 'fixed',
    zIndex: 1,
  },
});
