import { makeStyles, useTheme } from '@material-ui/core';
import I18n from 'i18n-js';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SecondaryButton, Subtitle, Title } from '../../components';
import { useQueryParams } from '../../hooks';

const useStyles = makeStyles(theme => ({
  background: {
    backgroundColor: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '92vh',
    width: 'fit-content',
    gap: 30,
  },
  title: {
    textAlign: 'left',
    margin: '0 auto',
    fontWeight: 500,
    fontSize: '24px',
    color: '#000',
  },
  subtitle: {
    textAlign: 'center',
    margin: '10px',
    fontWeight: 500,
    fontSize: '18px',
    color: '#A9A9A9',
  },
  titleContainer: {
    width: 495,
    [theme.breakpoints.down(516)]: {
      width: 'auto',
    },
  },
  mailbox: {
    height: 200,
    marginTop: 50,
  },
  button: {
    marginTop: '10px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  caption: {
    color: theme.palette.grey.main,
    textAlign: 'center',
    fontSize: 14,
    width: '70%',
    lineHeight: '1.5',
  },
}));

const VerifyEmail = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const queryParams = useQueryParams();
  const [email, setEmail] = useState('');


  const handleContinueToLogin = () => {
    history.push('/login');
  }

  useEffect(() => {
    const email = queryParams.get('email');
    if(!email) {
      return history.push('/login');
    }
    setEmail(email);

  }, [history, queryParams]);

  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <Title color="secondary" className={classes.title}>
            {I18n.t('verifyEmail.title')}
          </Title>
        </div>
        <Subtitle className={classes.subtitle}>{I18n.t('verifyEmail.subtitle')}</Subtitle>

        <span className={classes.caption}>
          {I18n.t('verifyEmail.emailSentTo', { email })}
        </span>

          <SecondaryButton className={classes.button} onClick={handleContinueToLogin}>
            {I18n.t('verifyEmail.continueToLogin')}
          </SecondaryButton>
      </div>
    </div>
  );
};

export default VerifyEmail;
