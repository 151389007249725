import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Loading, SurfacePage, Title } from '../../../components';
import { getCookie } from '../../../utils/utils';
import { get } from '../../../utils/network';
import I18n from '../../../lang/i18n';
import Score from './components/score';

const useStyles = makeStyles(theme => ({
  subtitle: {
    fontWeight: 500,
    fontSize: 22,
    marginTop: 50,
    margin: 0,
  },
  text: {
    fontSize: 18,
    maxWidth: 495,
    margin: 0,
    color: theme.palette.grey.main,
  },
}));

const Statistics = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    get(`users/${getCookie('userId')}/stats`)
      .then(res => setData(res.message.data.stats))
      .finally(() => setLoading(false));
  }, []);

  return (
    <SurfacePage>
      <Title withBackArrow>{I18n.t('statistics.title')}</Title>
      <p className={classes.subtitle}>{I18n.t('statistics.subtitle')}</p>
      <p className={classes.text}>{I18n.t('statistics.text')}</p>
      {loading ? (
        <Loading />
      ) : (
        data && (
          <>
            <Score
              name={I18n.t('courseJourney.exercise.genericTypes.reading')}
              value={data.readingScore}
            />
            <Score
              name={I18n.t('courseJourney.exercise.genericTypes.useOfEnglish')}
              value={data.useOfEnglishScore}
            />
            <Score
              name={I18n.t('courseJourney.exercise.genericTypes.listening')}
              value={data.listeningScore}
            />
            <Score
              name={I18n.t('courseJourney.exercise.genericTypes.writing')}
              value={data.writingScore}
            />
            <Score
              name={I18n.t('courseJourney.exercise.genericTypes.overallScore')}
              value={data.overallScore}
              color="secondary"
            />
          </>
        )
      )}
    </SurfacePage>
  );
};

export default Statistics;
