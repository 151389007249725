import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import DrawerLeft from './drawer-left/drawer-left';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flex: 1,
  },
  childrenContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    margin: 16,
    [theme.breakpoints.down('md')]: {
      margin: 20,
    },
    [theme.breakpoints.down('xs')]: {
      margin: 10,
    },
  },
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  const [drawerMobileOpen, setDrawerMobileOpen] = useState(false);

  const handleDrawerToggle = value => {
    if (value !== undefined) setDrawerMobileOpen(value);
    else setDrawerMobileOpen(prevState => !prevState);
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <DrawerLeft drawerMobileOpen={drawerMobileOpen} handleDrawerToggle={handleDrawerToggle} />
        <div className={classes.childrenContainer}>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
