import React from 'react';
import { makeStyles } from '@material-ui/core';
import I18n from '../../../../lang/i18n';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    fontSize: 20,
    marginTop: 50,
  },
  text: {
    fontSize: 16,
    maxWidth: 495,
  },
  link: {
    fontWeight: 600,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
}));

const NoLessons = ({ handleClick, isTeacher }) => {
  const classes = useStyles();
  return (
    <>
      <p className={classes.title}>{I18n.t('bookedLessons.noLessons.title')}</p>
      {!isTeacher && <p className={classes.text}>
        <span className={classes.link} onClick={handleClick}>
          {I18n.t('bookedLessons.noLessons.bookLesson')}
        </span>{' '}
        {I18n.t('bookedLessons.noLessons.text')}
      </p>}
    </>
  );
};

export default NoLessons;
