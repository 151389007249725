import React, { useState } from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Tooltip, useMediaQuery, CircularProgress, Grid } from '@material-ui/core';
import { TEACHER_DRAWER_OPTIONS, DRAWER_OPTIONS, NO_COURSE_DRAWER_OPTIONS, NO_BOOK_LESSONS_DRAWER_OPTIONS } from './options';
import { getCookie } from '../../utils/utils';
import I18n from 'i18n-js';
import { deleteAllCookies } from '../../utils/utils';
import { post } from '../../utils/network';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  drawerContentContainer: {
    height: "100%",
    width: "100%",
  },
  drawerContent: {
    padding: '16px 8px',
  },
  drawerButton: {
    cursor: 'pointer',
    borderRadius: 4,
    padding: 8,
    display: 'flex',
    marginBottom: 16,
  },
  drawerButtonText: {
    fontSize: 16,
    color: '#FFF',
    margin: 0,
    paddingTop: 3,
    paddingLeft: 12,
  },
  circularProgress: {
    color: '#FFF',
  }
}));

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFF',
    fontSize: 16,
    maxWidth: 220,
    padding: '8px 16px',
    marginTop: 2,
    margin: '0 10px',
    borderRadius: '0 4px 4px 0',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}))(Tooltip);

const DrawerButton = ({ active, title, iconSrc, onClick }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <HtmlTooltip placement="right" title={title}>
      <div
        className={classes.drawerButton}
        onClick={onClick}
        style={{ backgroundColor: active ? theme.palette.secondary.main : 'transparent' }}
      >
        <img alt={iconSrc} src={iconSrc} width={24} />
        {mobileScreen && <p className={classes.drawerButtonText}>{title}</p>}
      </div>
    </HtmlTooltip>
  );
};

const DrawerContent = ({ active, handleChange }) => {
  const classes = useStyles();
  const availableOptions =  getCookie('isTeacher') ? TEACHER_DRAWER_OPTIONS 
                          : getCookie('courseId') ? (
                              !getCookie('bookLessonsEnabled') ? NO_BOOK_LESSONS_DRAWER_OPTIONS 
                              : DRAWER_OPTIONS 
                            )
                          : NO_COURSE_DRAWER_OPTIONS;
  const options = availableOptions;
  const history = useHistory();
  const [loggingOut, setLoggingOut] = useState(false);

  const handleLogout = async () => {
    setLoggingOut(true);
    deleteAllCookies();
    post('auth/logout', '').finally(() => {
      history.push('/login');
      setLoggingOut(false);
    });
  };

  return (
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="center"
        className={classes.drawerContentContainer}
      >
        <Grid item>
          {options.filter(opt => opt.id < 6).map(({ id, src, title, path }) => (
            <DrawerButton
              key={id}
              active={active === id}
              iconSrc={src}
              title={title}
              onClick={() => handleChange({ id, path })}
            />
          ))}
        </Grid>
        <Grid item>
          {options.filter(opt => opt.id >= 6).map(({ id, src, title, path }) => (
            <DrawerButton
              key={id}
              active={active === id}
              iconSrc={src}
              title={title}
              onClick={() => handleChange({ id, path })}
            />
          ))}
          { loggingOut ? (
            <div className={classes.drawerContent}>
              <CircularProgress className={classes.circularProgress} size={24}/>
            </div>
          ) : (
            <DrawerButton
              key={DRAWER_OPTIONS.length}
              active={active === DRAWER_OPTIONS.length}
              iconSrc={'icons-logout.svg'}
              title={I18n.t('drawer.logout')}
              onClick={handleLogout}
            />
          )}
        </Grid>
      </Grid>
  );
};

export default DrawerContent;
