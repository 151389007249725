import React from 'react';
import Exercise from '../exercise';
import { useExercise } from '../../../../../hooks';
import { interpolateComponent } from '../utils';
import InterpolatedSelect from '../../components/interpolated-select';

const formatAnswersResponse = content => {
  return content.map(c => ({
    questionId: c._id,
    wordPosition: c.wordPosition,
    answer: '',
  }));
};

const ReadingChoice = ({ data, reviewUnitId, reviewUnitToken, onSuccessSubmit, handleChange, handleEndReview}) => {

  const reviewUnit = {
    exercise: data,
    reviewUnitId,
    reviewUnitToken,
  };

  const handleSuccessSubmit = (statusCode, data) => {
    if (onSuccessSubmit) onSuccessSubmit(statusCode, data);
  };

  const {
    loading,
    submitting,
    exercise,
    answers,
    result,
    handleChangeAnswer,
    handleClean,
    handleSubmit,
    timer,
  } = useExercise({ formatAnswersResponse, onSuccessSubmit: handleSuccessSubmit, reviewUnit, });

  const interpolateSelects = exercise => {
    return interpolateComponent({
      exercise,
      answers,
      result,
      gapComponent: InterpolatedSelect,
      gapComponentProps: { handleChangeAnswer },
    });
  };

  return (
    <Exercise
      exercise={exercise}
      loading={loading}
      submitting={submitting}
      finalResult={result}
      handleSubmit={handleSubmit}
      handleRetry={handleClean}
      handleChange={handleChange}
      handleEndReview = {handleEndReview}
      renderText={interpolateSelects}
      leftStyle={{ paddingBottom: 24 }}
      timer={timer}
      isReviewUnit={!!reviewUnitId}
    />
  );
};

export default ReadingChoice;
