import React from 'react';
import { insertElementAtPosition, SELECT_FLAG } from '../../../../utils/utils';

const insertSelectFlags = exercise => {
  let arr = exercise.text.split(' ');
  exercise.content.forEach((gap, index) => {
    arr = insertElementAtPosition(
      arr,
      gap.wordPosition + index,
      `${SELECT_FLAG}_${index}_${gap.wordPosition}`
    );
  });
  return arr;
};

const brRegex = /(\\n|\n)/

const interpolateComponent = ({ exercise, answers, result, gapComponent, gapComponentProps }) => {
  const words = insertSelectFlags(exercise);
  return words.map(word => {
    if (word.includes(SELECT_FLAG)) {
      const indexSelectFlag = Number(word.split('_')[1]);
      const wordPosition = Number(word.split('_')[2]);
      const answer = answers.find(a => a.wordPosition === wordPosition);
      const isCorrect = result?.answers?.find(a => a.questionId === answer.questionId)?.correct;
      const feedback = { explanation: exercise.content[indexSelectFlag].explanation }
      if(exercise.options) {
        feedback.correctAnswer = exercise.options[result?.answers?.find(a => a.questionId === answer.questionId)?.correctAnswer - 1]
      }
      else {
        feedback.correctAnswer = result?.answers?.find(a => a.questionId === answer.questionId)?.correctAnswer
      }

      return React.createElement(gapComponent, {
        answer,
        isCorrect,
        indexSelectFlag,
        feedback,
        exercise,
        key: indexSelectFlag,
        ...gapComponentProps,
      });
    } else if(word.match(brRegex)) //If the word contains line breaks \n then add <p> to break the line
    {
      return word.split(brRegex).filter(e => e).map(e => {
        return e.match(brRegex) ? <p></p> : e + " "
      })
    }
    return word + ' ';
  }).flat();
};

const formatAnswersBody = content => {
  return content.map(c => ({...c, answer: c.answer.trim()}));
}

export { insertSelectFlags, interpolateComponent, formatAnswersBody };
