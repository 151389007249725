import React from 'react';
import { makeStyles, Slider as MUISlider, styled } from '@material-ui/core';
import { getColorScore, getLetterScore } from '../../../../utils/utils';

const useStyles = makeStyles(theme => ({
  scoreContainer: {
    padding: 12,
    margin: '60px 0',
    display: 'flex',
  },
  scoreName: {
    width: 200,
    fontWeight: 500,
    fontSize: 18,
    color: theme.palette.grey.main,
  },
}));

const MARKS = [
  {
    value: 0,
    label: 'E',
  },
  {
    value: 26,
    label: 'D',
  },
  {
    value: 41,
    label: 'C',
  },
  {
    value: 61,
    label: 'B',
  },
  {
    value: 85,
    label: 'A',
  },
];

const sliderStyles = theme => ({
  height: 2,
  padding: '15px 0',
  '& .MuiSlider-valueLabel': {
    color: theme.palette.primary.main,
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 10,
    width: 1,
    '&.MuiSlider-markActive': {
      opacity: 1,
      backgroundColor: '#bfbfbf',
    },
  },
});

const GreenSlider = styled(MUISlider)(({ theme }) => ({
  ...sliderStyles(theme),
  '& .MuiSlider-valueLabel': {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.progress.green,
  },
  '& .MuiSlider-track': {
    color: theme.palette.progress.green,
  },
  '& .MuiSlider-thumb': {
    color: theme.palette.progress.green,
  },
}));

const OrangeSlider = styled(MUISlider)(({ theme }) => ({
  ...sliderStyles(theme),
  '& .MuiSlider-valueLabel': {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.progress.orange,
  },
  '& .MuiSlider-track': {
    color: theme.palette.progress.orange,
  },
  '& .MuiSlider-thumb': {
    color: theme.palette.progress.orange,
  },
}));

const YellowSlider = styled(MUISlider)(({ theme }) => ({
  ...sliderStyles(theme),
  '& .MuiSlider-valueLabel': {
    fontSize: 18,
    fontWeight: 600,
    color: theme.palette.progress.yellow,
  },
  '& .MuiSlider-track': {
    color: theme.palette.progress.yellow,
  },
  '& .MuiSlider-thumb': {
    color: theme.palette.progress.yellow,
  },
}));

const RedSlider = styled(MUISlider)(({ theme }) => ({
  ...sliderStyles(theme),
  '& .MuiSlider-valueLabel': {
    color: theme.palette.progress.red,
    fontSize: 18,
    fontWeight: 600,
  },
  '& .MuiSlider-track': {
    color: theme.palette.progress.red,
  },
  '& .MuiSlider-thumb': {
    color: theme.palette.progress.red,
  },
}));

const SLIDER_BY_COLOR = {
  red: RedSlider,
  yellow: YellowSlider,
  orange: OrangeSlider,
  green: GreenSlider,
};

const Score = ({ name, value, color = 'primary' }) => {
  const classes = useStyles();
  const sliderColor = getColorScore(value);
  return (
    <div className={classes.scoreContainer}>
      <span className={classes.scoreName}>{name}</span>
      {React.createElement(SLIDER_BY_COLOR[sliderColor], {
        defaultValue: Number(value).toFixed(2),
        valueLabelDisplay: 'on',
        valueLabelFormat: value => getLetterScore(value),
        style: { pointerEvents: 'none' },
        marks: MARKS,
      })}
    </div>
  );
};

export default Score;
