import moment from 'moment';
import I18n from '../lang/i18n';
import * as jose from 'jose'
import Swal from 'sweetalert2';

export const sleep = (timeMS, onTimer) =>
  new Promise((resolve, reject) => setTimeout(() => onTimer(resolve, reject), timeMS));

export const deleteCookie = name => {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;';
};

export const getCookie = name => {
  let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
};

export const setCookie = (name, value) => {
  document.cookie = `${name}=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');
  cookies.forEach(cookie => {
    const name = cookie.indexOf('=') > -1 ? cookie.substr(0, cookie.indexOf('=')) : cookie;
    deleteCookie(name);
  });
};

export const isLogged = () => getCookie('isLogged');

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
export const NAME_REGEX = /^([a-zA-ZÀ-úÜü]+\s?)+$/;

export const MIN_GREEN = 66;
export const MIN_YELLOW = 33;
export const MIN_ORANGE = 5;

export const insertElementAtPosition = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const SELECT_FLAG = 'INSERT-SELECT';

export const formatMinutes = minutes =>
  minutes < 60
    ? `${moment.duration(minutes, 'minutes').asMinutes()} ${I18n.t('global.minutes')}`
    : `${moment.duration(minutes, 'minutes').asHours()} ${I18n.t('global.hours')}`;

export const getLetterScore = score => {
  if (score > 85) {
    return 'A';
  } else if (score >= 61) {
    return 'B';
  } else if (score >= 41) {
    return 'C';
  } else if (score >= 26) {
    return 'D';
  } else {
    return 'E';
  }
};

export const getColorScore = score => {
  if (score > 85) {
    return 'green';
  } else if (score >= 61) {
    return 'yellow';
  } else if (score >= 41) {
    return 'orange';
  } else if (score >= 26) {
    return 'orange';
  } else {
    return 'red';
  }
};

// Storage CRUD for UnitReview
export const setUnitReviewInLocalStorage = (id, {data}) => {
  const storeReview = {unit_id:id, data: {...data}, date: moment(), user_id: getCookie('userId')}
  const storedReviews = (JSON.parse(localStorage.getItem('storedReviews')) || []).filter(unit => unit.unit_id !== id || unit?.user_id !== getCookie('userId'))
  storedReviews.push(storeReview)
  localStorage.setItem('storedReviews', JSON.stringify(storedReviews))
};

export const getUnitReviewInLocalStorage = (id) => {
  const storeReview = ((JSON.parse(localStorage.getItem('storedReviews')) || []).filter(unit => unit?.unit_id === id && unit?.user_id === getCookie('userId'))[0] || null)
  return storeReview?.data?.reviewUnitToken ? storeReview : null
};

export const removeUnitReview = (id) => {
  const storedReviews = (JSON.parse(localStorage.getItem('storedReviews')) || []).filter(unit => unit.unit_id !== id || unit?.user_id !== getCookie('userId'))
  localStorage.setItem('storedReviews', JSON.stringify(storedReviews))  
}
export const getDecodedAnserData = (reviewUnitToken)=> {
  let result = null
  if(reviewUnitToken){
    const { correctAnswers, answers, score } = jose.decodeJwt(reviewUnitToken);
     result = { correctAnswers, answers, score };
  }
  return result;
};

export const handle402BookLesson = (history) => (res) => {
  res.text().then(data => ({ ...JSON.parse(data) })).then((res) => {
    const code = res.code;
    if(code === 12) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: I18n.t('global.unsubscribedDialog.lessonAfterCancelled'),
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `<span style='color:#FFF; font-weight: bold'>${I18n.t('global.unsubscribedDialog.goToPlans')}</span>`,
        cancelButtonText: `<span style='color:#00acc2; font-weight: bold'>${I18n.t('global.unsubscribedDialog.goToBookLessons')}</span>`,
        confirmButtonColor: '#2dacc4',
        cancelButtonColor: '#f8f8f9',
      }).then(result => {
        if (result.isConfirmed) history.push('/home/plans');
        else history.push('/home/book_lesson/new');
        return Promise.reject(402);
      });
    } else {
      return Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: I18n.t('global.unsubscribedDialog.unsubscribed'),
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `<span style='color:#FFF; font-weight: bold'>${I18n.t('global.unsubscribedDialog.goToPlans')}</span>`,
        cancelButtonText: `<span style='color:#00acc2; font-weight: bold'>${I18n.t('global.unsubscribedDialog.goToHome')}</span>`,
        confirmButtonColor: '#2dacc4',
        cancelButtonColor: '#f8f8f9',
        
      }).then((result) => {
        if (result.isConfirmed) history.push('/home/plans');
        else history.push('/home');
        return Promise.reject(402);
      });
    }
  });
}