import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { useTheme } from '@material-ui/core';

const TextAreaAnswerPlaceholder = () => {
  const theme = useTheme();
  return (
    <Skeleton
      variant="rect"
      height={282}
      width={'100%'}
      style={{ borderRadius: theme.shape.borderRadius }}
    />
  );
};

export default TextAreaAnswerPlaceholder;
