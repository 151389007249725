import React from 'react';
import Exercise from '../exercise';
import { useExercise } from '../../../../../hooks';
import { interpolateComponent, formatAnswersBody } from '../utils';
import InterpolatedTextInput from '../../components/interpolated-text-input';

const formatAnswersResponse = content => {
  return content.map(c => ({
    questionId: c._id,
    wordPosition: c.wordPosition,
    answer: '',
  }));
};

const ReadingWrite = ({ data, reviewUnitId, reviewUnitToken, onSuccessSubmit, handleChange, handleEndReview }) => {
  const reviewUnit = {
    exercise: data,
    reviewUnitId,
    reviewUnitToken,
  };

  const handleSuccessSubmit = (statusCode, data) => {
    if (onSuccessSubmit) onSuccessSubmit(statusCode, data);
  };

  const {
    loading,
    submitting,
    exercise,
    answers,
    result,
    handleChangeAnswer,
    handleClean,
    handleSubmit,
    timer,
  } = useExercise({ formatAnswersResponse, reviewUnit, onSuccessSubmit: handleSuccessSubmit, formatAnswersBody, });

  const interpolateInputs = exercise => {
    return interpolateComponent({
      exercise,
      answers,
      result,
      gapComponent: InterpolatedTextInput,
      gapComponentProps: { handleChangeAnswer },
    });
  };

  return (
    <Exercise
      exercise={exercise}
      loading={loading}
      submitting={submitting}
      renderText={interpolateInputs}
      handleSubmit={handleSubmit}
      handleRetry={handleClean}
      handleChange={handleChange}
      handleEndReview = {handleEndReview}
      finalResult={result}
      timer={timer}
      isReviewUnit={!!reviewUnitId}
    />
  );
};

export default ReadingWrite;
