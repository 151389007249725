import React from 'react';
import { Layout } from '../../components';
import { Route, Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { getCookie } from '../../utils/utils';
import HomePage from './home/home';
import Unit from './course-journey/unit';
import CourseJourney from './course-journey/course-journey';
import PlacementTest from './course-journey/placement-test/placement-test';
import BookLesson from './book-lesson/book-lesson';
import PlacementTestStart from './course-journey/placement-test/placement-test-start';
import PlacementTestResults from './course-journey/placement-test/placement-test-results';
import Profile from './profile/profile';
import BookedLessons from './book-lesson/booked-lessons';
import PaymentSuccess from './book-lesson/payment-success';
import PaymentFailure from './book-lesson/payment-failure';
import BookLessonForm from './book-lesson/book-lesson-form';
import ReviewUnit from './course-journey/review-unit/review-unit';
import CourseMap from './course-map/course-map';
import Statistics from './statistics/statistics';
import ExtraPractice from './extra-practice/extra-practice';
import Plans from './profile/plans';
import {
  ListeningChoice,
  ListeningQuestion,
  ListeningQuestionSingle,
  ListeningWrite,
  ReadingChoice,
  ReadingKeyword,
  ReadingOptions,
  ReadingQuestion,
  ReadingWrite,
  Writing,
} from './course-journey/exercises';
import FAQ from './faq/faq';
import ReviewUnitStart from './course-journey/review-unit/review-unit-start';

const PlacementTestRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/start`} component={PlacementTestStart} />
      <Route exact path={`${path}/results`} component={PlacementTestResults} />
      <Route exact path={`${path}/:id`} component={PlacementTest} />
    </Switch>
  );
};
const ReviewUnitRouter = () => {
  const { path } = useRouteMatch();
  
  return (
    <Switch>
      <Route exact path={`${path}/start`} component={ReviewUnitStart} />
      {/*<Route exact path={`${path}/results`} component={ReviewUnitResults} />*/ }
      <Route exact path={`${path}/`} component={ReviewUnit} />
    </Switch>
  );
};

const DEFAULT_REDIRECT_PATH = '/home/course_journey/placement/start';
const WithCourseIdRoute = ({component, path, redirectPath = DEFAULT_REDIRECT_PATH}) => {
  const courseId = getCookie('courseId');
  if (!courseId) {
    return <Redirect from={path} to={redirectPath} />;
  }
  return <Route exact path={path} component={component} />;

}

const ExerciseRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/writing/:id`} component={Writing} />
      <Route exact path={`${path}/reading/keyword/:id`} component={ReadingKeyword} />
      <Route exact path={`${path}/reading/choice/:id`} component={ReadingChoice} />
      <Route exact path={`${path}/reading/question/:id`} component={ReadingQuestion} />
      <Route exact path={`${path}/reading/write/:id`} component={ReadingWrite} />
      <Route exact path={`${path}/reading/options/:id`} component={ReadingOptions} />
      <Route exact path={`${path}/listening/choice/:id`} component={ListeningChoice} />
      <Route exact path={`${path}/listening/write/:id`} component={ListeningWrite} />
      <Route exact path={`${path}/listening/question/:id`} component={ListeningQuestion} />
      <Route exact path={`${path}/listening/questionSingle/:id`} component={ListeningQuestionSingle} />
    </Switch>
  );
};

const CourseJourneyRouter = () => {
  const { path } = useRouteMatch();
  const courseId = getCookie('courseId');
  if (!courseId) {
    return (
      <Switch>
        <Route path={`${path}/placement`} component={PlacementTestRouter} />
        <Redirect from={`${path}/*`} to={DEFAULT_REDIRECT_PATH} />
      </Switch>
    );
  }
  return (
    <Switch>
      <Route exact path={path} component={CourseJourney} />
      <Route exact path={`${path}/units/:id`} component={Unit} />
      <Route path={`${path}/reviewUnits/:id`} component={ReviewUnitRouter} />
      <Route path={`${path}/exercises`} component={ExerciseRouter} />
      <Route path={`${path}/placement`} component={PlacementTestRouter} />
    </Switch>
  );
};

const BookLessonRouter = () => {
  const { path } = useRouteMatch();
  const courseId = getCookie('courseId');
  const isTeacher = getCookie('isTeacher');
  
  if (isTeacher) {
    return (
      <Switch>
        <Route exact path={[path, `${path}/history`]} component={BookedLessons} />
      </Switch>
    );
  } else if (!courseId) {
    return (
      <Switch>
        <Redirect from={`${path}/*`} to={DEFAULT_REDIRECT_PATH} />
      </Switch>
    );
  } else {
    return (
        <Switch>
          <Route exact path={[path, `${path}/history`]} component={BookedLessons} />
          <Route exact path={`${path}/payment_success`} component={PaymentSuccess} />
          <Route exact path={`${path}/payment_failure`} component={PaymentFailure} />
          <Route exact path={`${path}/new`} component={BookLesson} />
          <Route exact path={`${path}/form`} component={BookLessonForm} />
        </Switch>
      );
  }
};

const LoggedHome = () => {
  const isLogged = getCookie('isLogged');
  return !isLogged ? (
    <Redirect from="/home/*" to="/login" />
  ) : (
    <Layout>
      <Switch>
        <Route exact path={['/home', '/home/status']} component={HomePage} />
        <Route path="/home/course_journey" component={CourseJourneyRouter} />
        { !getCookie('bookLessonsEnabled') ? null : 
          <Route path="/home/book_lesson" component={BookLessonRouter} />
        }
        <WithCourseIdRoute path="/home/course_map" component={CourseMap}/>
        <WithCourseIdRoute path="/home/statistics" component={Statistics}/>
        <WithCourseIdRoute path="/home/extra_practice" component={ExtraPractice}/>
        <Route exact path="/home/plans" component={Plans} />
        <Route exact path="/home/profile" component={Profile} />
        <Route exact path="/home/faq" component={FAQ} />
        <Redirect from="/home/*" to="/404" />
      </Switch>
    </Layout>
  );
};

export default LoggedHome;
