import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, makeStyles, useTheme } from '@material-ui/core';
import {
  SurfacePage,
  Title,
  TextInput,
  SecondaryButton,
  TertiaryButton,
  SuccessToast,
} from '../../../components';
import I18n from '../../../lang/i18n';
import { EMAIL_REGEX, getCookie, NAME_REGEX } from '../../../utils/utils';
import { get, handle400, patch, post } from '../../../utils/network';
import TextPlaceholder from '../../../components/text-placeholder';
import ActiveSubscription from './components/active-subscription';
import ActiveSubscriptionPlaceholder from './components/active-subscription-placeholder';
import { useFormValidation } from '../../../hooks';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '20px 0',
    paddingLeft: 35,
  },
  passwordReset: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

export const FIELDS = {
  firstName: I18n.t('profile.inputs.firstName'),
  lastName: I18n.t('profile.inputs.lastName'),
  email: I18n.t('profile.inputs.email'),
};

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
};

const validate = values => {
  if (!values.firstName) return { firstName: I18n.t('profile.errors.fillFirstName') };
  if (!NAME_REGEX.test(values.firstName)) return { firstName: I18n.t('profile.errors.invalidFirstName') };

  if (!values.lastName) return { lastName: I18n.t('profile.errors.fillLastName') };
  if (!NAME_REGEX.test(values.lastName)) return { lastName: I18n.t('profile.errors.invalidLastName') };

  if (!values.email) return { email: I18n.t('profile.errors.fillEmail') };
  if (!EMAIL_REGEX.test(values.email)) return { email: I18n.t('profile.errors.invalidEmail') };
  
  return {};
};

const Profile = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isTeacher = getCookie('isTeacher')
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [submittingPasswordReset, setSubmittingPasswordReset] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const {
    canSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    setErrors,
    resetValues,
  } = useFormValidation(INITIAL_STATE, values => validate(values));

  const handleCancel = () => setEditMode(false);
  const handleEdit = () => setEditMode(true);

  const getChangedValues = () => {
    const changedValues = [];
    Object.keys(initialValues).forEach(key => {
      initialValues[key] !== values[key] && changedValues.push(key);
    });
    return changedValues.filter(v => v !== 'created');
  };

  const handleSubmit = e => {
    if (canSubmit(e)) {
      setSubmitting(true);
      const body = {};
      const changedValues = getChangedValues();

      if (changedValues.length === 0) {
        setSubmitting(false);
        setEditMode(false);
        return;
      }

      changedValues.forEach(changedValue => {
        Object.assign(body, { [changedValue]: values[changedValue] });
      });

      patch(`users/${getCookie('userId')}`, body, {
        errorHandlers: [{ status: 400, method: handle400(FIELDS, setErrors) }],
      })
        .then(() => {
          setEditMode(false);
          setInitialValues(prevState => ({
            ...prevState,
            ...body,
          }));
          setSuccessMessage(I18n.t('profile.messages.updated'));
        })
        .finally(() => setSubmitting(false));
    }
  };

  const handlePasswordReset = () => {
    if (!submittingPasswordReset) {
      setSubmittingPasswordReset(true);
      post('auth/passwordreset', { email: initialValues.email })
        .then(() => setSuccessMessage(I18n.t('profile.messages.passwordReset')))
        .finally(() => setSubmittingPasswordReset(false));
    }
  };

  const getUser = useCallback(() => {
    get(`users/${getCookie('userId')}`)
      .then(res => {
        setData(res.message);
        const { firstName, lastName, email } = res.message;
        const initialData = { firstName, lastName, email };
        resetValues(initialData);
        setInitialValues(initialData);
      })
      .finally(() => setLoading(false));
  }, [resetValues]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <SurfacePage maxWidth={1210}>
      <Title withBackArrow>{I18n.t('profile.title')}</Title>
      {loading ? (
        <div className={classes.container}>
          { isTeacher ? null : 
           <ActiveSubscriptionPlaceholder />
          }
          <TextPlaceholder amount={4} height={60} />
        </div>
      ) : (
        <>
          <div className={classes.container}>
            { isTeacher ? null : 
              <ActiveSubscription
                data={data?.studentData?.activeSubscription}
                handleRefresh={getUser}
                freeTrial={data?.isFreeTrial ? data.freeTrialEnd : undefined}
                schoolSubscription={data?.studentData?.schoolSubscription}
              />
            }
            <TextInput
              name="firstName"
              value={values.firstName}
              error={errors.firstName}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={FIELDS['firstName']}
              disabled={!editMode}
              fullWidth
            />
            <TextInput
              name="lastName"
              value={values.lastName}
              error={errors.lastName}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={FIELDS['lastName']}
              disabled={!editMode}
              fullWidth
            />
            <TextInput
              name="email"
              value={values.email}
              error={errors.email}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={FIELDS['email']}
              disabled={!editMode}
              fullWidth
            />
            {data?.studentData?.course?.name && (
              <TextInput
                value={data?.studentData?.course?.name}
                label={I18n.t('profile.inputs.course')}
                disabled
                fullWidth
              />
            )}
            {editMode && (
              <p
                className={classes.passwordReset}
                style={{ color: submittingPasswordReset && theme.palette.text.disabled }}
                onClick={handlePasswordReset}
              >
                {submittingPasswordReset && (
                  <CircularProgress size={14} style={{ marginRight: 10 }} />
                )}
                {I18n.t('profile.buttons.passwordReset')}
              </p>
            )}
          </div>
          <div className={classes.actions}>
            {editMode ? (
              <>
                <TertiaryButton style={{ marginRight: 10 }} onClick={handleCancel}>
                  {I18n.t('profile.buttons.cancel')}
                </TertiaryButton>
                <SecondaryButton onClick={handleSubmit} loading={submitting}>
                  {I18n.t('profile.buttons.save')}
                </SecondaryButton>
              </>
            ) : (
              <SecondaryButton onClick={handleEdit}>
                {I18n.t('profile.buttons.edit')}
              </SecondaryButton>
            )}
          </div>
        </>
      )}
      <SuccessToast
        open={!!successMessage}
        onClose={() => setSuccessMessage(false)}
        text={successMessage}
      />
    </SurfacePage>
  );
};

export default Profile;
