import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { SecondaryButton, Subtitle, SurfacePage, Title } from '../../../components';
import I18n from '../../../lang/i18n';
import qs from 'qs';

const useStyles = makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  title: {
    color: theme.palette.state.success,
  },
  icon: {
    color: theme.palette.state.success,
    fontSize: 35,
  },
  dataContainer: {
    margin: '40px 0',
  },
  data: {
    fontSize: 20,
    color: theme.palette.primary.main,
  },
  value: {
    fontWeight: 500,
  },
  caption: {
    marginTop: 40,
    fontSize: 16,
    maxWidth: 480,
  },
}));

const PaymentSuccess = () => {
  const classes = useStyles();
  const history = useHistory();
  const { name, price } = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });
  const goHome = () => history.push('/home');
  return (
    <SurfacePage>
      <div className={classes.titleContainer}>
        <Title withBackArrow className={classes.title}>{I18n.t('bookLesson.payment.success.title')}</Title>
        <CheckCircle className={classes.icon} />
      </div>
      <Subtitle>{I18n.t('bookLesson.payment.success.subtitle')}:</Subtitle>
      <div className={classes.dataContainer}>
        <p className={classes.data}>
          {I18n.t('bookLesson.payment.productName')}: <span className={classes.value}>{name}</span>
        </p>
        <p className={classes.data}>
          {I18n.t('bookLesson.payment.price')}: <span className={classes.value}>{price}</span>
        </p>
      </div>
      <SecondaryButton onClick={goHome}>
        {I18n.t('bookLesson.payment.success.done')}
      </SecondaryButton>
      <p className={classes.caption}>{I18n.t('bookLesson.payment.success.caption')}</p>
    </SurfacePage>
  );
};

export default PaymentSuccess;
