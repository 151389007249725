import { useStopwatch } from 'react-timer-hook';
import moment from 'moment';

const useExerciseTimer = () => {
  const stopwatch = useStopwatch({ autoStart: false });

  const getTotalSeconds = () => {
    const { seconds, minutes, hours, days } = stopwatch;
    return moment
      .duration({
        seconds,
        minutes,
        hours,
        days,
      })
      .asSeconds();
  }

  const start = () => {
    window.sessionStorage.removeItem('exerciseTimer', getTotalSeconds());
    stopwatch.reset();
    stopwatch.start();
  }

  const pause = () => {
    window.sessionStorage.setItem('exerciseTimer', getTotalSeconds());
    stopwatch.pause();
  }

  const resume = () => {
    const totalSeconds = Number(window.sessionStorage.getItem('exerciseTimer'));
    const stopwatchOffset = new Date();
    stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + totalSeconds);
    stopwatch.reset(stopwatchOffset, true);
    window.sessionStorage.removeItem('exerciseTimer');
  }

  const saveTime = (seconds) => {
    window.sessionStorage.setItem('exerciseTimer', seconds);
  }

  const reload = () => {
    const totalSeconds = Number(window.sessionStorage.getItem('exerciseTimer'));
    const stopwatchOffset = new Date();
    stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + totalSeconds);
    stopwatch.reset(stopwatchOffset, false);
    window.sessionStorage.removeItem('exerciseTimer');
  }

  return { 
    seconds: stopwatch.seconds,
    minutes: stopwatch.minutes,
    hours: stopwatch.hours,
    getTotalSeconds,
    start, 
    pause, 
    resume,
    saveTime,
    reload,
  };
};

export default useExerciseTimer;
