import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Exercise from '../exercise';
import { useExercise } from '../../../../../hooks';
import TextPlaceholder from '../../../../../components/text-placeholder';
import Option from '../../components/option';
import { interpolateComponent } from '../utils';
import DroppableTextInput from '../../components/droppable-text-input';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginTop: 24,
  },
  optionsList: {
    flex: 1,
    borderLeft: `1px solid ${theme.palette.grey.light}`,
    padding: '0 20px',
    maxHeight: '50vh',
    overflow: 'auto',
    maxWidth: 450,
    paddingRight: 24,
  },
  noOptions: {
    color: theme.palette.grey.main,
    fontSize: 16,
    whiteSpace: 'pre-line',
  },
}));

const formatAnswersResponse = content => {
  return content.map(c => ({
    questionId: c._id,
    wordPosition: c.wordPosition,
    answer: '',
  }));
};

const ReadingOptions = ({ data, reviewUnitId, reviewUnitToken, onSuccessSubmit, handleChange, handleEndReview }) => {
  const classes = useStyles();
  const reviewUnit = {
    exercise: data,
    reviewUnitId,
    reviewUnitToken,
  };

  const handleSuccessSubmit = (statusCode, data) => {
    if (onSuccessSubmit) onSuccessSubmit(statusCode, data);
  };

  const {
    loading,
    submitting,
    exercise,
    answers,
    result,
    handleChangeAnswer,
    handleClean,
    handleSubmit,
    timer,
  } = useExercise({ formatAnswersResponse, reviewUnit, onSuccessSubmit: handleSuccessSubmit, resetAnswersOnRetry: false });

  const interpolateInputs = exercise => {
    return interpolateComponent({
      exercise,
      answers,
      result,
      gapComponent: DroppableTextInput,
      gapComponentProps: { handleChangeAnswer },
    });
  };

  const renderOptions = exercise => {
    const canDrag = !result;
    const availableOptions = loading
      ? []
      : exercise.options.filter(option => !answers.some(a => a.answer === option));
    return (
      <div className={classes.optionsList}>
        <p className={classes.title}>Options</p>
        <div>
          {loading ? (
            <TextPlaceholder width={200} amount={5} />
          ) : availableOptions.length > 0 ? (
            availableOptions.map((option, index) => (
              <Option index={index + 1} key={option} canDrag={canDrag}>
                {option}
              </Option>
            ))
          ) : (
            <p className={classes.noOptions}>You have already used all the available options.</p>
          )}
        </div>
      </div>
    );
  };

  return (
    <Exercise
      exercise={exercise}
      loading={loading}
      submitting={submitting}
      renderText={interpolateInputs}
      handleSubmit={handleSubmit}
      handleRetry={handleClean}
      handleChange={handleChange}
      handleEndReview = {handleEndReview}
      renderRight={renderOptions}
      finalResult={result}
      timer={timer}
      isReviewUnit={!!reviewUnitId}
    />
  );
};

export default ReadingOptions;
