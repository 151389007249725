import { Dialog, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '24px',
    flex: 1,
    width: '100%',
    background: '#FFFFFF',
    boxShadow: '0px 4px 24px rgba(34, 40, 47, 0.08)',
    borderRadius: '10px',
    [theme.breakpoints.up('lg')]: {
      maxWidth: 700,
    },
  }, 

  btnContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    width: '100%',
    gap: '24px',
    marginRight: '10%',
    marginBottom: 24,
  },

  img: {
    objectFit: 'cover',
    overflow: 'hidden',
    width: '100%',
    maxHeight: 300,
  },

  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '40px',
    lineHeight: '50px',
    letterSpacing: '-0.952381px',
    color: '#31ADC5',
    textAlign: 'center',
    margin: '0px 0px 16px',
  },

  text: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.2px',
    color: '#22282F',
    flex: 'none',
    paddingLeft: '64px',
    paddingRight: '64px',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },

  btnTest: {
    //Button
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 24px',
    background: '#31ADC5',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',

    //Label
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.2px',
    color: '#FFFFFF',

    '&:hover': {
      backgroundColor: darken('#31ADC5', 0.4),
    },
  },

}));

const CardComplete = ({ img, handleClick, open, handleClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isLoaded, setIsLoaded] = useState(false);
  const smallScreen = useMediaQuery(theme.breakpoints.down(1060));

  useEffect(() => {
    const image = new Image();
    image.src = img;
    image.onload = () => {
      setIsLoaded(true);
    };
  }, [img]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={smallScreen ? 'xl' : 'md'}
      fullScreen={smallScreen}
    >
      <div className={classes.cardContainer}>
        {isLoaded ? (
          <img className={classes.img} src={img} alt={img} />
        ) : (
          <div style={{ width: '100%', height: '100%', minHeight: 300 }} />
        )}
        <div>
          <h1 className={classes.title}>Congratulations!</h1>
          <p className={classes.text}>
          You've successfully made your way through the course. This gives you full access to the materials for the remainder of your subscription. Use it to review the content on your own to improve your score. You can still book lessons online with our teachers if you feel the need.     
            <br/><br/> Best of luck on your exam!
          </p>
        </div>
        <div className={classes.btnContainer}>
          <button className={classes.btnTest} onClick={handleClick}>
            Got it!
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default CardComplete; 