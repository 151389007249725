import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import { Tooltip } from '@material-ui/core';
import TextInput from './text-input';
import { FeedbackTooltip } from './feedback';

const DroppableTextInputTooltip = ({ children, answer, isCorrect, feedback }) => {
  return (
    isCorrect === undefined ? (
      <Tooltip title={answer?.answer === '' ? 'Drop your answer here' : ''}>
        {children}
      </Tooltip>
    ) : (
      <FeedbackTooltip isCorrect={isCorrect} feedback={feedback}>
        {children}
      </FeedbackTooltip>
    )
  );
}


const DroppableTextInput = ({ answer, indexSelectFlag, isCorrect, feedback, handleChangeAnswer }) => {
  const [value, setValue] = useState('');
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'option',
    drop: item => handleDrop(item),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const handleDrop = item => {
    setValue(item.option);
    handleChangeAnswer(answer.questionId)({ target: { value: `${item.index}` } });
  };

  const handleClear = ({target}) => {
    const {value} = target;

    if(value === "") setValue("")
  }

  return (
    <DroppableTextInputTooltip answer={answer} isCorrect={isCorrect} feedback={feedback}>
      <div ref={drop} key={answer.id} style={{ display: 'inline' }}>
        <TextInput
          value={value}
          placeholder={isOver && canDrop ? 'drop here' : `${indexSelectFlag + 1}`}
          isCorrect={isCorrect}
          handleChange={handleClear}
          maxWidth={300}
          type="search"
        />{' '}
      </div>
    </DroppableTextInputTooltip>
  );
};

export default DroppableTextInput;
