import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
  SecondaryButton,
  Subtitle,
  SurfacePage,
  Title,
  Loading,
  Select,
} from '../../../../components';
import I18n from '../../../../lang/i18n';
import { post } from '../../../../utils/network';
import { insertElementAtPosition, SELECT_FLAG } from '../../../../utils/utils';

const useStyles = makeStyles(theme => ({
  caption: {
    fontSize: 16,
    color: theme.palette.grey.main,
    fontWeight: 500,
    marginTop: 0,
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.grey.light,
    width: '100%',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginTop: 24,
  },
  text: {
    lineHeight: 2.4,
    fontSize: 16,
    marginTop: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    gap: 10,
  },
  bullet: {
    width: 7,
    height: 7,
    backgroundColor: theme.palette.accent.main,
    marginRight: 14,
    borderRadius: '50%',
  },
}));

const PlacementTest = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentExercises, setCurrentExercises] = useState([]);
  const [placementTestToken, setPlacementTestToken] = useState('');
  const [currentAnswers, setCurrentAnswers] = useState([]);
  const [answeredQuestions, setAnsweredQuestions] = useState(0);

  const handleSelectAnswer = (exerciseId, wordPosition) => event => {
    const value = event.target.value
    setCurrentAnswers(prevAnswers =>
      prevAnswers.map(exercise => {
        if (exercise.exerciseId === exerciseId)
          return {
            ...exercise,
            answers: exercise.answers.map(answer => {
              if (answer.wordPosition === wordPosition) return { ...answer, answer: value };
              return answer;
            }),
          };
        return exercise;
      })
    );
  };

  const interpolateSelects = exercise => {
    if (currentExercises.length > 0 && currentAnswers.length > 0) {
      let arr = exercise.text.split(' ');

      exercise.content.forEach((gap, index) => {
        arr = insertElementAtPosition(
          arr,
          gap.wordPosition + index,
          `${SELECT_FLAG}_${index}_${gap.wordPosition}`
        );
      });

      const component = arr.map((word, index) => {
        if (word.includes(SELECT_FLAG)) {
          const gapIndex = Number(word.split('_')[1]);
          const wordPosition = Number(word.split('_')[2]);
          const value = currentAnswers
            .find(a => a.exerciseId === exercise._id)
            ?.answers.find(a => a.wordPosition === wordPosition)?.answer;
          return (
            <div key={index} style={{ display: 'inline' }}>
              <Select
                value={value}
                onChange={handleSelectAnswer(exercise._id, wordPosition)}
                options={exercise.content[gapIndex].options}
              />{' '}
            </div>
          );
        }
        return word + ' ';
      });

      return (
        <div key={exercise._id} style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }}>
          <div className={classes.bullet} />
          {component}
        </div>
      );
    }
  };

  const formatResponse = res => {
    setPlacementTestToken(res.message.data.placementTestToken);
    setCurrentExercises(res.message.data.placementExercises);
    setCurrentAnswers(
      res.message.data.placementExercises.map(ex => ({
        exerciseId: ex._id,
        answers: ex.content.map(c => ({
          questionId: c._id,
          wordPosition: c.wordPosition,
          answer: '',
        })),
      }))
    );
  };

  const handleSubmit = () => {
    setSubmitting(true);
    const answeredNumber = currentAnswers.length;
    post('placement-test', { placementTestToken, answers: currentAnswers })
      .then(res => {
        if (res.statusCode === 201) {
          setCurrentExercises([]);
          setCurrentAnswers([]);
          history.push({
            pathname: '/home/course_journey/placement/results',
            state: {
              suggestedCourse: res.message.data.course,
              correctAnswers: res.message.data.correctAnswers,
              incorrectAnswers: res.message.data.incorrectAnswers
            },
          });
        } else {
          formatResponse(res);
        }
        setAnsweredQuestions(prevState => prevState + answeredNumber);
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    const postPlacementTest = () => {
      post('placement-test', { currentLevel: id })
        .then(res => formatResponse(res))
        .finally(() => setLoading(false));
    };

    postPlacementTest();
  }, [id]);

  return (
    <SurfacePage>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Title withBackArrow>{I18n.t('courseJourney.placement.title')}</Title>
      </div>
      <div>
        <Subtitle>{I18n.t('courseJourney.placement.subtitle')}</Subtitle>
        <Title style={{ fontSize: 18, marginTop: 14 }}>
          {I18n.t('courseJourney.placement.answeredQuestions')}: {answeredQuestions || '-'}
        </Title>
        {loading ? (
          <Loading style={{ height: '30vh' }} />
        ) : (
          currentExercises.length > 0 && (
            <>
              <div className={classes.text}>
                {currentExercises.map(exercise => interpolateSelects(exercise))}
              </div>
              <div className={classes.buttonContainer}>
                <SecondaryButton onClick={handleSubmit} loading={submitting}>
                  {I18n.t('courseJourney.placement.next')}
                </SecondaryButton>
              </div>
            </>
          )
        )}
      </div>
    </SurfacePage>
  );
};

export default PlacementTest;
