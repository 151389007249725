import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useExercise } from '../../../../../hooks';
import AudioPlayer from '../../components/audio-player';
import InterpolatedTextInput from '../../components/interpolated-text-input';
import AudioPlayerPlaceholder from '../../components/placeholders/audio-player-placeholder';
import Exercise from '../exercise';
import { formatAnswersBody, interpolateComponent } from '../utils';

const useStyles = makeStyles(() => ({
  audioPlayer: {
    marginRight: 20,
    marginTop: 20,
  },
}));

const formatAnswersResponse = content => {
  return content.map(c => ({
    questionId: c._id,
    wordPosition: c.wordPosition,
    answer: '',
  }));
};

const ListeningWrite = ({ data, reviewUnitId, reviewUnitToken, onSuccessSubmit, handleChange, handleEndReview }) => {
  const { id } = useParams();
  const classes = useStyles();
  const reviewUnit = {
    exercise: data,
    reviewUnitId,
    reviewUnitToken,
  };
  const localStorageCounterKey = `${id}-playCounter`;
  const handleSuccessSubmit = (statusCode, data) => {
    window.localStorage.removeItem(localStorageCounterKey);
    if (onSuccessSubmit) onSuccessSubmit(statusCode, data);
  };
  const {
    loading,
    submitting,
    exercise,
    answers,
    result,
    handleChangeAnswer,
    handleClean,
    handleSubmit,
    timer,
  } = useExercise({ formatAnswersResponse, onSuccessSubmit: handleSuccessSubmit, reviewUnit, formatAnswersBody, });

  const [isAudioPlaying, setAudioPlaying] = useState(false);
  const handleAudioStartPlaying = () => {
    setAudioPlaying(true);
  }

  const handleAudioStopPlaying = () => {
    setAudioPlaying(false)
  }

  const interpolateInputs = exercise => {
    return interpolateComponent({
      exercise,
      answers,
      result,
      gapComponent: InterpolatedTextInput,
      gapComponentProps: { handleChangeAnswer },
    });
  };

  const renderTop = exercise => (
    <div className={classes.audioPlayer}>
      {loading ? (
        <AudioPlayerPlaceholder />
      ) : (
        <AudioPlayer
          src={exercise?.audioUrl}
          counterKey={localStorageCounterKey}
          disabled={result !== null || isAudioPlaying}
          onStartPlaying={handleAudioStartPlaying}
          onStopPlaying={handleAudioStopPlaying}
          anotherTrackPlaying={isAudioPlaying}
          canPlay={!submitting}
        />
      )}
    </div>
  );

  return (
    <Exercise
      exercise={exercise}
      loading={loading}
      submitting={submitting}
      finalResult={result}
      renderText={interpolateInputs}
      renderTop={renderTop}
      handleSubmit={handleSubmit}
      handleRetry={handleClean}
      handleEndReview = {handleEndReview}
      handleChange={handleChange}
      timer={timer}
      isReviewUnit={!!reviewUnitId}
    />
  );
};

export default ListeningWrite;
