import React from 'react';
import { CircularProgress, Grid, useTheme, withStyles } from '@material-ui/core';
import {
  ErrorToast,
  SuccessToast,
  PasswordInput,
  Title,
  SecondaryButton,
} from '../../../../components';
import styles from './styles';
import I18n from '../../../../lang/i18n';

const PasswordResetFormComponent = ({
  classes,
  data,
  handleSubmit,
  handleChange,
  handleSuccess,
  onDismissError,
}) => {
  const {
    loading,
    errorpassword,
    password,
    errorpasswordRepeat,
    passwordRepeat,
    errortoken,
    success,
  } = data;
  const theme = useTheme();
  return (
    <div className={classes.background}>
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      )}
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.gridLogin}
      >
        <div className={classes.titleContainer}>
          <Title className={classes.title}>{I18n.t('passwordResetForm.title')}</Title>
        </div>
        <form className={classes.formLogin} onSubmit={handleSubmit}>
          <div className={classes.inputLogin}>
            <PasswordInput
              error={errorpassword}
              name="password"
              label={I18n.t('passwordResetForm.inputs.newPassword')}
              handleChange={handleChange}
              value={password}
              hideStartAdornment
            />
          </div>
          <div className={classes.inputLogin}>
            <PasswordInput
              error={errorpasswordRepeat}
              name="passwordRepeat"
              label={I18n.t('passwordResetForm.inputs.confirmNewPassword')}
              hideStartAdornment
              handleChange={handleChange}
              value={passwordRepeat}
            />
          </div>
          <SecondaryButton type="submit" className={classes.submitButton} onClick={handleSubmit}>
            {I18n.t('passwordResetForm.buttons.confirm')}
          </SecondaryButton>
          <p onClick={handleSuccess} className={classes.textAction}>
            {I18n.t('passwordResetForm.buttons.goToLogin')}
          </p>
        </form>
      </Grid>
      <ErrorToast
        open={errortoken !== ''}
        onClose={onDismissError}
        text={I18n.t('passwordResetForm.errors.invalidLink')}
      />
      <SuccessToast
        open={success}
        onClose={handleSuccess}
        duration={3000}
        text={I18n.t('passwordResetForm.successMessage')}
      />
    </div>
  );
};

export default withStyles(styles)(PasswordResetFormComponent);
