import React from 'react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 550,
    fontSize: 16,
  },
  labelSmall: {
    fontWeight: 550,
    fontSize: 14,
  },
  root: {
    padding: '10px 26px',
    borderRadius: 6,
  },
  small: {
    padding: '4px 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '4px 10px',
    },
  },
  rootSmall: {
    padding: '6px 14px',
    borderRadius: 6,
  },
  disabled: {
    color: `${theme.palette.secondary.main} !important`,
  },
}));

const buttonClasses = (size, classes) => ({
  label: size === 'small' ? classes.labelSmall : classes.label,
  root: size === 'small' ? classes.rootSmall : classes.root,
});

const PrimaryButton = ({
  children,
  onClick,
  disabled = false,
  startIcon,
  endIcon,
  type,
  loading = false,
  style,
  className,
  size = 'medium',
}) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      size={size}
      disabled={loading || disabled}
      startIcon={startIcon}
      disableElevation
      endIcon={loading ? <CircularProgress size={16} style={{ marginLeft: 10 }} /> : endIcon}
      type={type}
      className={className}
      style={style}
      classes={buttonClasses(size, classes)}
    >
      {children}
    </Button>
  );
};

const SecondaryButton = ({
  children,
  onClick,
  disabled = false,
  startIcon,
  endIcon,
  type,
  loading = false,
  style,
  className,
  size = 'medium',
}) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={onClick}
      disabled={loading || disabled}
      startIcon={startIcon}
      endIcon={loading ? <CircularProgress size={16} style={{ marginLeft: 10 }} /> : endIcon}
      type={type}
      size={size}
      className={className}
      disableElevation
      style={style}
      classes={{
        disabled: classes.disabled,
        ...buttonClasses(size, classes),
      }}
    >
      {children}
    </Button>
  );
};

const TertiaryButton = ({
  children,
  onClick,
  disabled = false,
  startIcon,
  endIcon,
  type,
  loading = false,
  color = 'secondary',
  style,
  className,
  size = 'medium',
}) => {
  const classes = useStyles();
  return (
    <Button
      variant="text"
      size={size}
      color={color}
      onClick={onClick}
      disabled={loading || disabled}
      startIcon={startIcon}
      endIcon={loading ? <CircularProgress size={16} style={{ marginLeft: 10 }} /> : endIcon}
      type={type}
      className={className}
      disableElevation
      style={style}
      classes={buttonClasses(size, classes)}
    >
      {children}
    </Button>
  );
};

const VARIANTS = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  tertiary: TertiaryButton,
};

const SmallButton = ({ variant = 'secondary', ...props }) => {
  const classes = useStyles();
  return React.createElement(VARIANTS[variant], { ...props, className: classes.small });
};

export { PrimaryButton, SecondaryButton, TertiaryButton, SmallButton };
