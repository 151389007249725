import React from 'react';
import {
  Button,
  LinearProgress,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Surface } from '../../../../components';
import { MIN_GREEN, MIN_YELLOW } from '../../../../utils/utils';
import I18n from '../../../../lang/i18n';

const useStyles = makeStyles(theme => ({
  surface: {
    width: 340,
    height: 340,
    position: 'relative',
    margin: 10,
    boxShadow: '0 8px 12px 0 rgba(23, 26, 31, 0.05) !important',
    [theme.breakpoints.down('sm')]: {
      width: 300,
    },
  },
  container: {
    borderRadius: 10,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  },
  progressIcon: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: '#fff',
    boxShadow: '0 8px 12px 0 rgba(255, 199, 0, 0.15)',
    position: 'absolute',
    top: 120,
    left: 0,
    right: 0,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'rgba(240, 242, 244, 0.5)',
    color: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: 600,
    borderRadius: 6,
    padding: '8px 24px',
    marginTop: 24,
  },
  title: {
    fontSize: 28,
    fontWeight: 500,
  },
  description: {
    color: theme.palette.grey.main,
    fontSize: 20,
    fontWeight: 500,
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    objectFill: 'cover',
  },
  progressLine: {
    backgroundColor: 'transparent',
    borderRadius: 10,
  },
  greenBar: {
    backgroundColor: theme.palette.progress.green,
  },
  yellowBar: {
    backgroundColor: theme.palette.progress.yellow,
  },
  orangeBar: {
    backgroundColor: theme.palette.progress.orange,
  },
}));

const UnitCard = ({ id, unitHeader, description, type, progress, photoUrl, blocked = false, endOfCourse = false }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const iconSrc = blocked
    ? 'lock.svg'
    : progress === 100
    ? 'check-circle-green.svg'
    : 'alert-circle-outline.svg';

  const handleClickUnit = () => {
    if (/review/i.test(type)) {
      return history.push(`/home/course_journey/reviewUnits/${id}/start`);
    }
    history.push(`/home/course_journey/units/${id}`);
  };

  return (
    <Surface className={classes.surface} style={{ opacity: blocked ? 0.5 : 1 }}>
      <div className={classes.container}>
        <div className={classes.progressIcon}>
          <img src={iconSrc} alt={iconSrc} />
        </div>
        <img
          src={photoUrl || "unitImage.png"}
          alt="unit"
          width={mobileScreen ? 300 : 340}
          height={140}
          className={classes.image}
        />
        <div className={classes.content}>
          <Typography className={classes.title}>{unitHeader}</Typography>
          <Typography className={classes.description}>{description}</Typography>
          {!blocked && !endOfCourse && (
            <Button className={classes.button} onClick={handleClickUnit}>
              {progress === 100
                ? I18n.t('courseJourney.viewUnit')
                : I18n.t('courseJourney.completeUnit')}
            </Button>
          )}
        </div>
        <LinearProgress
          variant="determinate"
          value={progress}
          className={classes.progressLine}
          classes={{
            bar:
              progress > MIN_GREEN
                ? classes.greenBar
                : progress > MIN_YELLOW
                ? classes.yellowBar
                : classes.orangeBar,
          }}
        />
      </div>
    </Surface>
  );
};

export default UnitCard;
