import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { SecondaryButton, SurfacePage, Title } from '../../../../components';
import I18n from '../../../../lang/i18n';
import LevelSelector from '../components/level-selector';

const useStyles = makeStyles(theme => ({
  question: {
    fontSize: 20,
    marginTop: 14,
    fontWeight: 500,
  },
  levels: {
    marginTop: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    gap: 10,
  },
}));

const PlacementTestStart = () => {
  const classes = useStyles();
  const history = useHistory();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const handleChangeLevel = e => setSelectedCourse(e.target.value);

  const handleStart = () => {
    const courseId = selectedCourse === "idontknow" ? "60edcdca94f8d5e3eec2a6aa" : selectedCourse;
    history.push(`/home/course_journey/placement/${courseId}`);
  };

  return (
    <SurfacePage>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Title withBackArrow>{I18n.t('courseJourney.placement.title')}</Title>
      </div>
      <div>
        <Title className={classes.question}>
          {I18n.t('courseJourney.placement.startQuestion')}
        </Title>
        <LevelSelector selected={selectedCourse} onChange={handleChangeLevel} />
      </div>
      <div className={classes.buttonContainer}>
        {selectedCourse && (
          <SecondaryButton onClick={handleStart}>
            {I18n.t('courseJourney.placement.start')}
          </SecondaryButton>
        )}
      </div>
    </SurfacePage>
  );
};

export default PlacementTestStart;
