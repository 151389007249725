import { EMAIL_REGEX, NAME_REGEX, PASSWORD_REGEX } from '../../../utils/utils';
import I18n from '../../../lang/i18n';

const validate = values => {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = I18n.t('signup.errors.fillFirstName');
  } else if (!NAME_REGEX.test(values.firstName)) {
    errors.firstName = I18n.t('signup.errors.invalidFirstName');
  }

  if (!values.lastName) {
    errors.lastName = I18n.t('signup.errors.fillLastName');
  } else if (!NAME_REGEX.test(values.lastName)) {
    errors.lastName = I18n.t('signup.errors.invalidLastName');
  }

  if (!values.password) {
    errors.password = I18n.t('signup.errors.fillPassword');
  } else if (values.password.length < 8 || values.password.length > 20) {
    errors.password = I18n.t('signup.errors.invalidPasswordLength');
  } else if (!PASSWORD_REGEX.test(values.password)) {
    errors.password = I18n.t('signup.errors.invalidPassword');
  }

  if (!values.email) {
    errors.email = I18n.t('signup.errors.fillEmail');
  } else if (!EMAIL_REGEX.test(values.email)) {
    errors.email = I18n.t('signup.errors.invalidEmail');
  }

  if (!values.isVerified) {
    errors.isVerified = I18n.t('signup.errors.verify');
  }
  if(!values.iAmOver14){
    errors.iAmOver14 = true;
  }
  return errors;
};

export default validate;
