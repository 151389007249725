import React from 'react';
import TextPlaceholder from '../../../../../components/text-placeholder';

const QuestionPlaceholder = ({ amount = 1 }) =>
  [...Array(amount)].map((_, i) => (
    <div key={i} style={{ marginBottom: 20 }}>
      <TextPlaceholder height={60} width="95%" />
      <TextPlaceholder amount={4} width="95% " />
    </div>
  ));

export default QuestionPlaceholder;
