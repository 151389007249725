import React from 'react';
import { CircularProgress, Grid, Typography, useTheme, withStyles } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import I18n from '../../../lang/i18n';
import { LoginInput, PasswordInput, SecondaryButton, Title } from '../../../components';
import styles from './styles';
import { useHistory } from 'react-router-dom';
import CookieBannerv2 from './cookies-banner';

const ERROR_LEVEL_COLORS = {
  warning: {
    color: '#856404',
    backgroundColor: '#fff3cd',
    border: '1px solid #ffeeba',
  },
  error: {
    color: '#721c24',
    backgroundColor: '#f8d7da',
    border: '1px solid #f5c6cb',
  },
  success: {
    color: '#155724',
    backgroundColor: '#d4edda',
    border: '1px solid #c3e6cb',
  },
};

const LoginComponent = ({ classes, data, handleChange, handleSubmit, handlePasswordReset }) => {
  const theme = useTheme();
  const history = useHistory();
  const handleSignup = () => history.push('/signup');
  return (
    <div className={classes.background}>
      {data.token ? (
        <div className={classes.loadingToken}>
          <CircularProgress size={60} />
          <p style={{ fontSize: 18 }}>{I18n.t('login.loading')}</p>
        </div>
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.gridLogin}
        >
          <div className={classes.titleContainer}>
            <Title className={classes.title}>{I18n.t('login.title')}</Title>
          </div>
          <form className={classes.formLogin} onSubmit={handleSubmit}>
            {data.errorMessage && (
              <div className={classes.errorContainer} style={ERROR_LEVEL_COLORS[data.errorLevel]}>
                <InfoOutlined className={classes.errorIcon} />
                <div className={classes.errorMessage}>
                  {data.errorUuid && <p className={classes.errorUuid}>Code: {data.errorUuid}</p>}
                  <p className={classes.errorText}>
                    {data.errorUuid && `${I18n.t('login.errors.details')}:`} {data.errorMessage}
                  </p>
                </div>
              </div>
            )}
            <div className={classes.inputLogin}>
              <LoginInput
                error={data.errorUsername}
                name="username"
                label={I18n.t('login.inputs.email')}
                type="text"
                handleChange={handleChange}
                value={data.username}
              />
            </div>
            <div className={classes.inputLogin}>
              <PasswordInput
                error={data.errorPassword}
                name="password"
                label={I18n.t('login.inputs.password')}
                hideStartAdornment
                handleChange={handleChange}
                value={data.password}
              />
            </div>
            <p onClick={() => handlePasswordReset(true)} className={classes.textAction}>
              {I18n.t('login.buttons.forgotPassword')}
            </p>
            <SecondaryButton
              type="submit"
              className={classes.signInButton}
              onClick={handleSubmit}
              loading={data.loading}
            >
              {I18n.t('login.buttons.login')}
            </SecondaryButton>
          </form>
          <Typography className={classes.signupTitle}>{I18n.t('login.signupCaption')}</Typography>
          <p className={classes.textAction} style={{ marginTop: 5 }} onClick={handleSignup}>
            {I18n.t('login.buttons.signup')}
          </p>
        </Grid>
      )}
      <CookieBannerv2/>
    </div>
  );
};

export default withStyles(styles)(LoginComponent);
