import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import I18n from '../../../lang/i18n';
import { post } from '../../../utils/network';
import { getCookie, setCookie } from '../../../utils/utils';
import LoginComponent from './component';
import config from '../../../utils/config';
import PasswordResetDialog from '../password-reset/password-reset-dialog';
import { SuccessToast } from '../../../components';

const LoginContainer = () => {
  const history = useHistory();
  const params = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorUsername, setErrorUsername] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [passwordResetOpen, setPasswordResetOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorLevel, setErrorLevel] = useState(params.level || null);
  const [errorMessage, setErrorMessage] = useState(params.message || null);
  const [errorUuid] = useState(params.uuid || null);

  const resetErrors = () => {
    setErrorPassword('');
    setErrorUsername('');
  };

  const checkEmptyInputs = () => {
    if (username === '') setErrorUsername(I18n.t('login.errors.fillEmail'));
    if (password === '') setErrorPassword(I18n.t('login.errors.fillPassword'));
    setLoading(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    resetErrors();
    if (name === 'username') setUsername(value);
    if (name === 'password') setPassword(value);
  };

  const handle401 = err => {
    err.json().then(e => {
      if (e.code === 115) {
        setErrorMessage(I18n.t('login.errors.validateAccount'));
        setErrorLevel('success');
      } else {
        setErrorUsername(I18n.t('login.errors.wrongEmailOrPassword'));
        setErrorPassword(I18n.t('login.errors.wrongEmailOrPassword'));
      }
    });
    return Promise.reject(401);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (username !== '' && password !== '') {
      setLoading(true);

      const body = {
        email: username,
        password,
        locale: 'es',
      };

      const errorHandlers = [
        { status: 401, method: handle401 },
        { status: 404, method: () => Promise.reject(404) },
        { status: 400, method: () => Promise.reject(400) },
      ];

      post('auth/login', body, {
        errorHandlers,
      })
        .then(data => {
          setCookie('isLogged', true);
          if (config.devEnvironment) setCookie('token', data.message.token);
          setLoading(false);
          history.push('/home/status');
        })
        .catch(err => {
          setLoading(false);
          if (err === 404 || err === 400) {
            setErrorUsername(I18n.t('login.errors.wrongEmailOrPassword'));
            setErrorPassword(I18n.t('login.errors.wrongEmailOrPassword'));
          }
        });
    } else {
      checkEmptyInputs();
    }
  };

  const handlePasswordReset = value => setPasswordResetOpen(value);

  const passwordResetSuccess = () => setSuccessMessage(I18n.t('passwordReset.dialog.success'));

  const onCloseToast = () => setSuccessMessage('');

  useEffect(() => {
    if (getCookie('isLogged')) history.push('/home');
    if (params.token) {
      post('auth/sso', '', { token: params.token }).then(data => {
        setCookie('isLogged', true);
        if (config.devEnvironment) setCookie('token', data.message.token);
      });
    }
  }, [history, params.token]);

  return (
    <>
      <LoginComponent
        handlePasswordReset={handlePasswordReset}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        data={{
          username,
          password,
          errorPassword,
          errorUsername,
          token: params.token,
          loading,
          errorMessage,
          errorLevel,
          errorUuid,
        }}
      />
      <PasswordResetDialog
        open={passwordResetOpen}
        onClose={() => handlePasswordReset(false)}
        onSubmit={passwordResetSuccess}
      />
      <SuccessToast open={successMessage !== ''} onClose={onCloseToast} text={successMessage} />
    </>
  );
};

export default LoginContainer;
