import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 40,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 36,
    },
  },
  subtitle: {
    color: theme.palette.grey.main,
    fontSize: 28,
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
  goBack: {
    marginRight: 10,
    cursor: 'pointer',
  },
}));

const Title = ({ children, className = '', color = 'primary', withBackArrow = false, style }) => {
  const classes = useStyles();
  const history = useHistory();
  const goBack = () => history.goBack();
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {withBackArrow && (
        <img
          onClick={goBack}
          alt="go-back"
          src="icons-clickable-arrow-left.svg"
          className={classes.goBack}
        />
      )}
      <Typography color={color} className={`${classes.title} ${className}`} style={style}>
        {children}
      </Typography>
    </div>
  );
};

const Subtitle = ({ children, className = '', style }) => {
  const classes = useStyles();
  return (
    <Typography className={`${classes.subtitle} ${className}`} style={style}>
      {children}
    </Typography>
  );
};

export { Title, Subtitle };
