import { Box, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import React from 'react';

const useStyles = makeStyles(theme => ({
  dayContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    margin: '0px 6px',
  },
  dayName: {
    fontSize: 12,
  },
  dayNumber: {
    fontSize: 16,
    fontWeight: 600,
  },
  barContainer: {
    display: 'flex',
    alignItems: 'end',
    width: 20,
    height: 120,
  },
  bar: {
    background: 'linear-gradient(180deg, #44D9E6 0%, #1F5CDB 100%);',
  },
  emptyBar: {
    background: '#B5FCF0',
  },
}));

const CalendarBar = ({ score }) => {
  const classes = useStyles();
  return (
    <Tooltip placement="right-end" title={`${score} excercises`}>
      <Box className={classes.barContainer}>
        <Box
          className={score > 0 ? classes.bar : classes.emptyBar}
          borderRadius={20}
          width={1}
          height={score > 0 ? score * 10 : 8}
          maxHeight={120}
        />
      </Box>
    </Tooltip>
  );
};

const CalendarColumn = ({ data, currentWeek, dayOfWeek, index }) => {
  const classes = useStyles();
  const date = moment(currentWeek).add(index, 'days');
  const barData = { date: date.format('D'), score: data[date.format('YYYY-MM-DD')] || 0 };
  return (
    <Box className={classes.dayContainer}>
      <Typography className={classes.dayName}>{dayOfWeek}</Typography>
      <Typography className={classes.dayNumber}>{barData.date}</Typography>
      <CalendarBar score={barData.score} />
    </Box>
  );
};

export default CalendarColumn;
