import { useCallback, useState } from 'react';

function useFormValidation(initialState, validate) {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});

  const resetError = name => setErrors(prevState => ({ ...prevState, [name]: undefined }));

  const handleChange = useCallback(event => {
    const { name, value } = event.target;
    resetError(name);
    setValues(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const resetValues = useCallback(values => {
    setValues(values);
  }, []);

  const handleBlur = event => {
    const { name } = event.target;
    const validationErrors = validate(values);
    setErrors(prevState => ({
      ...prevState,
      [name]: validationErrors[name],
    }));
  };

  const canSubmit = event => {
    event.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    return Object.entries(validationErrors).length === 0;
  };

  return {
    canSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    setErrors,
    resetValues,
  };
}

export default useFormValidation;
