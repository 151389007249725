import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { useTheme } from '@material-ui/core';

const ActiveSubscriptionPlaceholder = () => {
  const theme = useTheme();
  return (
    <Skeleton
      variant="rect"
      height={280}
      width="100%"
      style={{ borderRadius: theme.shape.borderRadius, marginBottom: 10 }}
    />
  );
};

export default ActiveSubscriptionPlaceholder;
