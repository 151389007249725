import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Exercise from '../exercise';
import { Question } from '../../components/question';
import QuestionPlaceholder from '../../components/placeholders/question-placeholder';
import AudioPlayer from '../../components/audio-player';
import AudioPlayerPlaceholder from '../../components/placeholders/audio-player-placeholder';
import { useExercise } from '../../../../../hooks';

const useStyles = makeStyles(theme => ({
  questionList: {
    flex: 1,
    paddingLeft: 5,
    maxHeight: '50vh',
    overflow: 'auto',
    paddingTop: 15,
  },
  audioPlayer: {
    marginRight: 24,
    marginTop: 7,
    marginBottom: 20
  },
}));

const formatAnswersResponse = content => {
  return content.map(c => ({
    questionId: c._id,
    answer: '',
  }));
};

const ListeningQuestionSingle = ({
  data,
  reviewUnitId,
  reviewUnitToken,
  onSuccessSubmit,
  handleChange,
  handleEndReview
}) => {
  const { id } = useParams();
  const classes = useStyles();

  const [isAudioPlaying, setAudioPlaying] = useState(false);

  const reviewUnit = {
    exercise: data,
    reviewUnitId,
    reviewUnitToken,
  };
  const handleSuccessSubmit = (statusCode, data) => {
    Object.keys(localStorage)
      .filter(key => key.startsWith(id))
      .forEach(key => localStorage.removeItem(key));
    if (onSuccessSubmit) onSuccessSubmit(statusCode, data);
  };

  const handleAudioStartPlaying = () => {
    setAudioPlaying(true);
  }

  const handleAudioStopPlaying = () => {
    setAudioPlaying(false)
  }

  const {
    loading,
    submitting,
    exercise,
    answers,
    result,
    handleChangeAnswer,
    handleClean,
    handleSubmit,
    timer,
  } = useExercise({ formatAnswersResponse, onSuccessSubmit: handleSuccessSubmit, reviewUnit });

  const localStorageCounterKey = `${id}-playCounter`;

  return (
    <Exercise
      exercise={exercise}
      loading={loading}
      submitting={submitting}
      finalResult={result}
      handleSubmit={handleSubmit}
      handleRetry={handleClean}
      handleChange={handleChange}
      handleEndReview = {handleEndReview}
      timer={timer}
      isReviewUnit={!!reviewUnitId}
    >
      <div className={classes.questionList}>
        {loading ? (
          <QuestionPlaceholder amount={2} />
        ) : (
          <div>
            <div className={classes.audioPlayer}>
              {loading ? (
                <AudioPlayerPlaceholder />
              ) : (
                <AudioPlayer
                  src={exercise.audioUrl}
                  disabled={result !== null || isAudioPlaying}
                  counterKey={localStorageCounterKey}
                  onStartPlaying={handleAudioStartPlaying}
                  onStopPlaying={handleAudioStopPlaying}
                  anotherTrackPlaying={isAudioPlaying}
                  canPlay={!submitting}
                />
              )}
            </div>
            {exercise?.content.map((question, index) => {
              const answer = result?.answers?.find(a => a.questionId === question._id);
              
              return (
                <Question
                  key={question._id}
                  index={index + 1}
                  selected={answers?.find(a => a.questionId === question._id)?.answer}
                  question={question.question}
                  options={question.options}
                  handleCheck={handleChangeAnswer(question._id)}
                  isCorrect={answer?.correct}
                  explanation={question.explanation}
                  correctOption={answer?.correctAnswer}
                />
              );
            })}
            <div style={{ height: 20 }} />
          </div>
        )}
      </div>
    </Exercise>
  );
};

export default ListeningQuestionSingle;
