import React from 'react';
import { makeStyles } from '@material-ui/core';
import { SecondaryButton } from '../../../../components';
import I18n from '../../../../lang/i18n';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    height: 280,
    width: 222,
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    padding: '16px 24px',
    flexDirection: 'column',
    fontSize: 16,
    borderRadius: 4,
    boxShadow: '0 2px 32px 0 rgba(23, 26, 31, 0.05)',
    backgroundColor: '#FFF',
    '& p': {
      margin: 0,
    },
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: theme.palette.grey.light,
  },
  duration: {
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.secondary.main,
    textAlign: 'center',
    paddingBottom: 16,
  },
  price: {
    fontSize: 28,
    fontWeight: 500,
    paddingTop: 16,
  },
  priceCaption: {
    fontSize: 12,
    fontWeight: 600,
    color: '#566376',
    paddingBottom: 16,
  },
  text: {
    fontSize: 16,
    color: '#566376',
    paddingBottom: 16,
    textAlign: 'center',
  },
}));

const PlanCard = ({
  duration,
  price,
  priceCaption,
  text,
  isCurrentPlan = false,
  handleClick,
  loading,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classes.cardContainer}
      style={{ boxShadow: isCurrentPlan ? '0 4px 24px 0 rgba(68, 217, 230, 0.4)' : '' }}
    >
      <p className={classes.duration}>{duration}</p>
      <div className={classes.divider} />
      <p className={classes.price}>{price}</p>
      <p className={classes.priceCaption}>{priceCaption}</p>
      <p className={classes.text}>{text}</p>
      <div style={{ flex: 1 }} />
      <SecondaryButton
        style={{ borderRadius: 50 }}
        disabled={isCurrentPlan}
        onClick={handleClick}
        loading={loading}
      >
        {isCurrentPlan ? I18n.t('plan.buttons.yourPlan') : I18n.t('plan.buttons.select')}
      </SecondaryButton>
    </div>
  );
};

export default PlanCard;
