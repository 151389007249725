import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import { SecondaryButton, Subtitle, Surface, Title, SurfacePage } from '../../../../components';
import I18n from '../../../../lang/i18n';
import {
  UnitHeaderPlaceholder,
  UnitNamePlaceholder,
} from '../components/placeholders/unit-placeholder';
import TextPlaceholder from '../../../../components/text-placeholder';
import { useQueryParams } from '../../../../hooks';
import TimerPlaceholder from '../components/placeholders/timer-placeholder';
import Timer from '../components/timer';
import { getLetterScore } from '../../../../utils/utils';
import { FeedbackTutorial } from '../components/feedback';
import { useParams } from 'react-router-dom';
import { getUnitReviewInLocalStorage, setUnitReviewInLocalStorage } from '../../../../utils/utils';

const useStyles = makeStyles(theme => ({
  caption: {
    fontSize: 16,
    color: theme.palette.grey.main,
    fontWeight: 500,
    marginTop: 0,
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.grey.light,
    width: '100%',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginTop: 24,
  },
  text: {
    lineHeight: 2.4,
    fontSize: 16,
    marginBottom: 15,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 20,
    alignItems: 'center',
  },
  goBack: {
    marginRight: 10,
    cursor: 'pointer',
  },
  exercise: {
    padding: 24,
    paddingRight: 0,
    paddingBottom: 0,
    margin: '24px 0',
  },
  exerciseContent: {
    flex: 1,
    maxHeight: '50vh',
    overflow: 'auto',
    paddingRight: 10,
  },
  correctAnswers: {
    fontSize: 28,
    fontWeight: 500,
    marginRight: 16,
    lineHeight: '39px',
    letterSpacing: '-0.35px',
  },
  correctAnswersText: {
    fontWeight: 500,
    fontSize: 20,
  },
  correctAnswersCaption: {
    color: theme.palette.grey.main,
    fontSize: 16,
    marginLeft: 10,
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}));

export const ExerciseHeader = ({ loading, unitName, timer, exerciseType }) => {
  const classes = useStyles();
  const { id } = useParams();

  const unitReviewFromLS = getUnitReviewInLocalStorage(id);

  if (unitReviewFromLS && timer.seconds !== 0) {
    unitReviewFromLS.data.timeTaken = timer.getTotalSeconds();
    setUnitReviewInLocalStorage(id, unitReviewFromLS);
  }

  return (
    <div className={classes.subtitle}>
      {loading ? (
        <>
          <UnitNamePlaceholder />
          <TimerPlaceholder />
        </>
      ) : (
        <>
          <Subtitle>
            {unitName ? `${unitName} - ` : ''} {exerciseType}
          </Subtitle>
          {timer && <Timer hours={timer.hours} minutes={timer.minutes} seconds={timer.seconds} />}
        </>
      )}
    </div>
  );
};

const renderExText = exercise => {
  const brRegex = /(\\n|\n)/g;

  const parsedText = exercise?.text.replaceAll(brRegex, '\r\n\r\n'); //Add real line breaks for MD

  return <ReactMarkdown>{parsedText}</ReactMarkdown>;
};

const Exercise = ({
  loading,
  exercise,
  handleSubmit,
  handleRetry,
  handleChange,
  handleEndReview,
  submitting,
  renderText = renderExText,
  renderRight,
  renderTop,
  leftStyle,
  finalResult,
  children,
  timer,
  isReviewUnit,
  hideFeedbackTutorial = false,
  correctAnswersText = I18n.t('courseJourney.exercise.correctAnswers'),
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const queryParams = useQueryParams();
  const [openFeedbackTutorial, setOpenFeedbackTutorial] = useState(false);

  return (
    <SurfacePage>
      {loading ? (
        <UnitHeaderPlaceholder style={{ marginLeft: 35 }} />
      ) : (
        <Title withBackArrow>{isReviewUnit ? 'Revision' : queryParams.get('unitHeader')}</Title>
      )}
      <div style={{ paddingLeft: 35 }}>
        <ExerciseHeader
          loading={loading}
          timer={timer}
          unitName={queryParams.get('unitName')}
          exerciseType={exercise?.genericType}
        />
        <Surface className={classes.exercise}>
          <div style={{ paddingRight: 24 }}>
            <p className={classes.caption}>
              {loading ? (
                <TextPlaceholder />
              ) : (
                <ReactMarkdown>{exercise?.assignment || exercise?.assigment}</ReactMarkdown>
              )}
            </p>
            <div className={classes.divider} />
          </div>
          {renderTop && renderTop(exercise)}
          {children ? (
            children
          ) : (
            <div style={{ display: 'flex' }}>
              <div className={classes.exerciseContent} style={leftStyle}>
                {(exercise?.description || loading) && (
                  <p className={classes.title}>
                    {loading ? (
                      <TextPlaceholder width={150} />
                    ) : (
                      <ReactMarkdown>{exercise?.description}</ReactMarkdown>
                    )}
                  </p>
                )}
                <div className={classes.text}>
                  {loading ? <TextPlaceholder amount={8} /> : renderText(exercise)}
                </div>
              </div>
              {renderRight && renderRight(exercise)}
            </div>
          )}
        </Surface>
        <div className={classes.buttonContainer}>
          <div>
            {finalResult && (
              <>
                <span className={classes.correctAnswers}>
                  <span style={{ color: theme.palette.secondary.main }}>
                    { finalResult?.correctAnswers}
                  </span>{' '}
                  /{' '}
                  {
                    exercise?.numberOfSpeakers ? exercise?.numberOfSpeakers
                    : exercise?.genericType === 'Writing' ? 20
                    : exercise?.content.length
                  }
                </span>
                <span className={classes.correctAnswersText}>
                  { correctAnswersText }
                </span>
                <span className={classes.correctAnswersCaption}>
                  {I18n.t(
                    `courseJourney.exercise.answersCaption.${getLetterScore(finalResult?.score)}`
                  )}
                </span>
              </>
            )}
          </div>
          <SecondaryButton
            onClick={
              finalResult
                ? (isReviewUnit ? () => {
                  !finalResult.final ? handleChange(finalResult) : handleEndReview()
                } : () => { 
                  timer.start();
                  handleRetry();
                }) : () => {
                    handleSubmit();
                    setOpenFeedbackTutorial(true && !hideFeedbackTutorial);
                  }
            }
            loading={submitting}
            style={{ minWidth: finalResult ? 150 : 0 }}
          >
            {finalResult
              ? ( !isReviewUnit ? I18n.t('courseJourney.exercise.retry') : I18n.t('courseJourney.exercise.next') )
              : I18n.t('courseJourney.exercise.submit')}
          </SecondaryButton>
          {isReviewUnit || hideFeedbackTutorial ? null : (
            <FeedbackTutorial
              open={openFeedbackTutorial}
              handleClose={() => {
                setOpenFeedbackTutorial(false);
              }}
            />
          )}
        </div>
      </div>
    </SurfacePage>
  );
};

export default Exercise;
