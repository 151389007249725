import { makeStyles } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import DasbhoardLabel from './dashboard-label';
import { Box } from '@material-ui/core';
import { getCookie } from '../../../../utils/utils';


const useStyles = makeStyles(theme => ({
  rootContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 24px 32px',
    gap: '16px',
    width: '400px',
    height: '164px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 24px rgba(23, 26, 31, 0.1)',
    borderRadius: '10px',
  },

  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },

  titleCard: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '-0.2px',
    color: '#22282F',
    marginTop: '0',
  },

  btnCard: {
    padding: '0px 10px',
    height: '32px',
    background: '#EBFFFA',
    borderRadius: '24px',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: darken('#EBFFFA', 0.2),
    },

    //label
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.2px',
    color: '#156085',
  },

  btnCardBlue: {
    padding: '0px 10px',
    height: '32px',
    background: 'rgb(49, 173, 197)',
    borderRadius: '24px',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: darken('#EBFFFA', 0.2),
    },

    //label
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.2px',
    color: 'white',
  },

  circle: {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    transition: 'background-color 1s',
  },

  'circle-blue': {
    backgroundColor: '#8EF7EE',
  },

  'circle-gray': {
    backgroundColor: 'lightGray',
  },

  containerLessons: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const NormalCard = ({ title, subtitle, courseName, btnName, handleClick, order, colorLight }) => {
  const classes = useStyles();
  const [isBlue, setIsBlue] = useState(true);
  const courseNameCookie = getCookie('courseName');
  
  useEffect(() => {
    const interval = setInterval(() => {
      setIsBlue(prevState => !prevState);
    }, 1500);

    return () => {
      clearInterval(interval);
    };
  }, []);


  return (
    <div className={classes.rootContainer} style={{ order: order }}>

      <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div className={classes.containerLessons}>
          <h1 className={classes.titleCard}>{title}</h1>
          <CSSTransition in={isBlue} timeout={500} classNames="circle">
          <div
            className={`${classes.circle} ${isBlue ? classes['circle-blue'] : classes['circle-blue']} ${
              !colorLight && !isBlue ? classes['circle-gray'] : ''
            }`}
          />
        </CSSTransition>
        </div>
        <div className={classes.contentContainer}>
        <div className={classes.leftContainer}>
          <DasbhoardLabel title={subtitle} text={courseName} />
          </div>
          <div className={classes.rightContainer}>  
            <button className={btnName === "Take test" ? classes.btnCardBlue : classes.btnCard} onClick={handleClick}>
              {btnName}
            </button>
          </div>
        </div>
      </Box>
      
    </div>
  );
};

export default NormalCard;
