import React, { useState } from 'react'
import { Grid, Checkbox, Dialog, Paper, Box, Typography, Tooltip, Divider, makeStyles, styled, FormControlLabel, } from '@material-ui/core';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { SecondaryButton } from '../../../../components';
import I18n from '../../../../lang/i18n';
import { put } from '../../../../utils/network';
import { getCookie } from '../../../../utils/utils';

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: '1.2rem',
    fontWeight: 600,
    width: '100%',
    padding: '0.5em 0.5rem 0.5em',
    variant: 'h1'
  },
  correctHeader: {
    color:  theme.palette.state.success,
  },
  wrongHeader: {
    color:  theme.palette.state.failure,
  },
  correctAnswerBox: {
    fontSize: '1rem',
    marginTop: '-0.5em',
    padding: '0em 0.5rem 0.6em',
  },
  correctAnswer: {
    variant: 'body1',
    color: theme.palette.state.failure,
  },
  explanation: {
    fontSize: '1rem',
    fontWeight: 400,
    padding: '0em 0.5rem 0em',
    variant: 'body1',
    lineHeight: 1.5,
  },
  tutorialBox: {
    fontSize: '1rem',
    marginTop: '-0.5em',
    padding: '2.5em 3rem 2em',
  },
  feedbackExampleGif: {
    display: 'block',
    margin: '1em auto 1em',
    width: '80%',
  },
  feedbackTutorialText: {
    textAlign: 'center',
    fontWeight: 600,
  },
  feedbackTutorialButton: {
    minWidth: 150,
    float: 'right',
  },
}));

const HtmlFeedbackTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: '#f5f5f5',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    borderColor: theme.palette.primary.main,
    borderStyle: 'none',
  },
}));

const FeedbackTooltip = ({ children, isCorrect, feedback }) => {
  return (
    (isCorrect === undefined || isCorrect ===null) ? <>{children}</> :  (
      <HtmlFeedbackTooltip title={<Feedback isCorrect={isCorrect} feedback={feedback}/>}>
        {children}
      </HtmlFeedbackTooltip>
    )
  );
}

const Feedback = ({ isCorrect, feedback }) => {
  const classes = useStyles();
  var { explanation, correctAnswer } = feedback;
  
  if(Array.isArray(correctAnswer)) {
    correctAnswer = correctAnswer.join(', ');
  }
  
  return (
    <React.Fragment>
      { isCorrect ? (
        <>
          <Typography className={[classes.header, classes.correctHeader].join(' ')}>CORRECT</Typography>
          <Divider />
          <ReactMarkdown className={classes.explanation}>{explanation}</ReactMarkdown>
        </>
      ) : (
        <>
          <Typography className={[classes.header, classes.wrongHeader].join(' ')}>INCORRECT</Typography>
          { !correctAnswer ? null : (
            <Box className={classes.correctAnswerBox}>
              <Typography className={classes.correctAnswer} component='span' style={{ fontWeight: 600 }}> Correct answer: </Typography>
              <Typography className={classes.correctAnswer} component='span'>{correctAnswer}</Typography>
            </Box>
          )}
          <Divider />
          <ReactMarkdown className={classes.explanation}>{explanation}</ReactMarkdown>
        </>
      ) }
    </React.Fragment>
  );
}

const FeedbackTutorial = ({open, handleClose}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  const onChange = (event) => {
    setChecked(event.target.checked)
  }

  const handleConfirm = () => {
    if(checked && getCookie('feedbackTutorialDisabled') !== 'true'){
      put(`users/${getCookie('userId')}/feedbacktutorial`, { feedbackTutorialDisabled: true })
      .finally(() => handleClose());
    }
    else{
      handleClose()
    }
  }

  return (
    <Dialog open={open && getCookie('feedbackTutorialDisabled') !== 'true'}>
      <Paper variant="outlined" elevation={6}>
        <Box className={classes.tutorialBox}>
          <Typography className={classes.feedbackTutorialText}>{I18n.t('courseJourney.exercise.feedback.tutorial')}</Typography>
          <img src="feedback-example.gif" alt="Feedback Example" className={classes.feedbackExampleGif}/>
          <Grid container spacing={2} justifyContent='space-between'>
            <Grid item xs>  
              <FormControlLabel 
                label={I18n.t('courseJourney.exercise.feedback.checkbox')}
                control={<Checkbox checked={checked} onChange={onChange}/>}
              />  
            </Grid>
            <Grid item xs alignContent='right'>
              <SecondaryButton
                onClick={handleConfirm}
                className={classes.feedbackTutorialButton}
              >
              {I18n.t('courseJourney.exercise.feedback.gotIt')}
              </SecondaryButton> 
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Dialog>
  );
} 

export { FeedbackTooltip, FeedbackTutorial };