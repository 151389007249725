import React, { useState, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Subtitle, SurfacePage, Title } from '../../../components';
import PlanCard from './components/plan-card';
import { get, post } from '../../../utils/network';
import { PlanCardPlaceholder } from './components/plan-card-placeholder';
import I18n from '../../../lang/i18n';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginTop: 48,
  },
  cardsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 30,
    margin: '15px 0',
  },
  caption: {
    fontSize: 12,
  },
  premiumPlans: {
    marginTop: 48,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: 15,
    '& > div': {
      minWidth: 470,
      padding: 24,
      paddingBottom: 0,
      borderRadius: 10,
      [theme.breakpoints.down('md')]: {
        width: 'auto',
      },
    },
  },
  premium: {
    backgroundColor: '#ebfffa',
  },
  premiumIntensive: {
    backgroundColor: '#f0f2f4',
  },
  premiumTitle: {
    color: theme.palette.secondary.main,
  },
  premiumText: {
    maxWidth: 308,
  },
  bottom: {
    marginTop: 48,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& > div': {
      width: 470,
      [theme.breakpoints.down('md')]: {
        width: 'auto',
      },
    },
  },
  link: {
    fontWeight: 600,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    textDecoration: 'none',
  },
}));

const getPlanDuration = plan =>
  `${plan.periodicity} ${
    plan.periodicity === 1 ? I18n.t('plan.periodicity.month') : I18n.t('plan.periodicity.months')
  }`;

const getPlanText = plan =>
  plan.periodicity === 1
    ? I18n.t('plan.billedMonthly')
    : `${plan.price} ${I18n.t('plan.billedEvery')} ${plan.periodicity} ${I18n.t(
        'plan.periodicity.months'
      )}`;


const handle402 = () => {
  return Swal.fire({
    title: I18n.t('profile.subscription.lastPaymentUnsuccessful'),
    text: I18n.t('profile.subscription.lastPaymentUnsuccessfulDescription'),
    icon: 'error',
    confirmButtonText: I18n.t('profile.subscription.lastPaymentUnsuccessfulButton'),
  });
};

const Plans = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [subscribingId, setSubscribingId] = useState(null);
  const [data, setData] = useState([]);
  const lessons = data?.filter(product => product.category === 'lesson')[0];
  const base = data?.filter(product => product.type === 'Base')[0];
  const premium = data?.filter(product => product.type === 'Premium')[0];
  const premiumIntensive = data?.filter(product => product.type === 'PremiumIntensive')[0];

  const handleSubscribe = (productId, planId) => {
    setSubscribingId(planId);
    
    post('cart/subscriptions', {
      productId,
      planId,
    },{ errorHandlers: [{status: 402, method: handle402}]})
      .then(res => {
        window.location.href = res.message.data.url;
      })
      .finally(() => setSubscribingId(null));
  };

  useEffect(() => {
    get(`products/mine`)
      .then(res => {
        setData(res.message.data.products);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <SurfacePage>
      <Title withBackArrow>{I18n.t('plans.title')}</Title>
      <Subtitle className={classes.subtitle}>{I18n.t('plans.base')}</Subtitle>
      <Typography>{I18n.t('plans.baseSubtitle')}</Typography>
      <div className={classes.cardsContainer}>
        {loading ? (
          <PlanCardPlaceholder amount={4} />
        ) : (
          base?.plans.map(plan => (
            <PlanCard
              duration={getPlanDuration(plan)}
              price={plan.pricePerMonth}
              priceCaption={I18n.t('plan.perMonth')}
              text={getPlanText(plan)}
              loading={plan._id === subscribingId}
              isCurrentPlan={plan?.isCurrentSubscription}
              handleClick={() => handleSubscribe(base._id, plan._id)}
            />
          ))
        )}
      </div>
      <Typography className={classes.caption}>{I18n.t('plans.caption')}</Typography>
      <div className={classes.premiumPlans}>
        <div className={classes.premium}>
          <Subtitle className={classes.premiumTitle}>{I18n.t('plans.premium')}</Subtitle>
          <Typography className={classes.premiumText}>{I18n.t('plans.premiumSubtitle')}</Typography>
          <div className={classes.cardsContainer}>
            {loading ? (
              <PlanCardPlaceholder />
            ) : (
              premium?.plans.map(plan => (
                <PlanCard
                  duration={getPlanDuration(plan)}
                  price={plan.pricePerMonth}
                  text={getPlanText(plan)}
                  loading={plan._id === subscribingId}
                  isCurrentPlan={plan?.isCurrentSubscription}
                  handleClick={() => handleSubscribe(premium._id, plan._id)}
                />
              ))
            )}
          </div>
        </div>
        <div className={classes.premiumIntensive}>
          <Subtitle>{I18n.t('plans.premiumIntensive')}</Subtitle>
          <Typography className={classes.premiumText}>{I18n.t('plans.premiumIntensiveSubtitle')}</Typography>
          <div className={classes.cardsContainer}>
            {loading ? (
              <PlanCardPlaceholder />
            ) : (
              premiumIntensive?.plans.map(plan => (
                <PlanCard
                  duration={getPlanDuration(plan)}
                  price={plan.pricePerMonth}
                  text={getPlanText(plan)}
                  loading={plan._id === subscribingId}
                  isCurrentPlan={plan?.isCurrentSubscription}
                  handleClick={() => handleSubscribe(premiumIntensive._id, plan._id)}
                />
              ))
            )}
          </div>
        </div>
      </div>
      <Typography className={classes.caption}>{I18n.t('plans.caption')}</Typography>
      <div className={classes.bottom}>
        <div>
          <Subtitle>{I18n.t('plans.zoomClasses')}</Subtitle>
          <Typography>{I18n.t('plans.zoomClassesSubtitle')}</Typography>
          <div className={classes.cardsContainer}>
            {loading ? (
              <PlanCardPlaceholder />
            ) : (
              lessons?.plans.map(plan => (
                <PlanCard
                  duration={`1 ${I18n.t('plan.lesson')}`}
                  price={plan.price}
                  text={`${I18n.t('plan.duration')}: 60 min`}
                  loading={plan._id === subscribingId}
                  isCurrentPlan={plan?.isCurrentSubscription}
                  handleClick={() => history.push('/home/book_lesson/new')}
                />
              ))
            )}
          </div>
        </div>
        <div>
          <Subtitle>{I18n.t('plans.payments')}</Subtitle>
          <Typography>
            {I18n.t('plans.paymentsSubtitle')}{' '}
            <a
              href="https://stripe.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Stripe
            </a>
          </Typography>
        </div>
      </div>
    </SurfacePage>
  );
};

export default Plans;
