import React from 'react';
import moment from 'moment';
import {
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import { SmallButton, Surface, TableCell } from '../../../../components';
import I18n from '../../../../lang/i18n';
import { Event as EventIcon, Warning } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getCookie } from '../../../../utils/utils';

const StyledTableCell = ({ style, ...props }) => (
  <TableCell {...props} style={{ fontSize: 16, paddingTop: 8, paddingBottom: 8, ...style }} />
);

const Lesson = ({ id, unit, datetime, topic, zoomLink, attended = false, stripeUrl, expires }) => {
  const theme = useTheme();
  const history = useHistory();
  const isTeacher = getCookie('isTeacher');
  const handleAttend = () => {
    if (zoomLink) window.open(zoomLink, '_blank');
  };
  const handleReschedule = () => {
    history.push({
      pathname: '/home/book_lesson/new',
      search: `?lessonId=${id}`,
    });
  };

  const onClickPay = () => {
    if(moment(expires).isAfter(moment())) {
      return window.open(stripeUrl, '_blank');
    }

    return Swal.fire({
      title: I18n.t('bookedLessons.alerts.expired.title'),
      text: I18n.t('bookedLessons.alerts.expired.text'),
      icon: 'warning',
      confirmButtonText: I18n.t('bookedLessons.alerts.expired.confirm'),
    });
  }

  const PayOrAttend = () => {
    if (stripeUrl) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: 10 }}>
          <SmallButton
            onClick={onClickPay}
            style={{
              backgroundColor: theme.palette.grey.main,
              color: theme.palette.primary.contrastText,
            }}
          >
            {I18n.t('bookedLessons.buttons.pay')}
          </SmallButton>
          <Tooltip title={`${I18n.t('bookedLessons.buttons.expires')} ${moment(expires).format('lll')}`}>
            <IconButton style={{ padding: 10, marginRight: 6 }}>
              <Warning 
                style={{
                  color: theme.palette.progress.yellow,
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      );
    }

    return (
      isTeacher ? (
        <SmallButton onClick={handleAttend}>{I18n.t('bookedLessons.buttons.attend')}</SmallButton>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: 10 }}>
          <SmallButton onClick={handleAttend}>{I18n.t('bookedLessons.buttons.attend')}</SmallButton>
          <Tooltip title={I18n.t('bookedLessons.buttons.reschedule')}>
            <IconButton onClick={handleReschedule} style={{ padding: 10, marginRight: 6 }}>
              <EventIcon />
            </IconButton>
          </Tooltip>
        </div>
      )
    );
  };

  return (
    <TableContainer component={Surface} style={{ width: '100%', margin: '2px 0' }}>
      <Table>
        <TableBody>
          <TableRow
            style={{
              borderLeft: `6px solid ${
                attended ? theme.palette.state.success : theme.palette.secondary.main
              }`,
            }}
          >
            <StyledTableCell style={{ width: '25%' }}>{topic}</StyledTableCell>
            <StyledTableCell style={{width: '24%'}} align="center">{unit || ''}</StyledTableCell>
            <StyledTableCell style={{width: '25%'}} align="center">{moment(datetime).format('lll')}</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '26%' }}>
              {attended ? (
                <SmallButton
                  variant="tertiary"
                  disabled
                  style={{ color: theme.palette.state.success }}
                >
                  {I18n.t('bookedLessons.buttons.attended')}
                </SmallButton>
              ) : (
                <PayOrAttend />
              )}
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Lesson;
