import { makeStyles } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { get } from '../../../../utils/network';
import DasbhoardLabel from './dashboard-label';
import { getCookie } from '../../../../utils/utils';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  rootContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 24px 32px',
    gap: '16px',
    width: '400px',
    height: '188px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 24px rgba(23, 26, 31, 0.1)',
    borderRadius: '10px',
  },

  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },

  titleCard: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '-0.2px',
    color: '#22282F',
    marginTop: '0',
  },

  btn: {
    padding: '0px 10px',
    height: '32px',
    background: '#EBFFFA',
    borderRadius: '24px',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: darken('#EBFFFA', 0.2),
    },

    //label
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.2px',
    color: '#156085',
  },

  btnDisabled: {
    backgroundColor: '#CCCCCC', // Cambia el color del botón deshabilitado según tus necesidades
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: '#CCCCCC', // Opcional: puedes establecer el mismo color para el hover deshabilitado
    },
  },

  tooltip: {
    fontSize: '14px',
    color: '#FFFFFF', // Personaliza el color del texto del tooltip según tus necesidades
    padding: '6px 10px',
  },
}));

const CardLesson = ({ title, subtitle, btnName, handleClick, order, endDate }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const courseId = getCookie('courseId');
  const courseName = getCookie('courseName');

  useEffect(() => {
    if(!courseId && !courseName){
      setIsButtonDisabled(true);
    }
    get('scheduled-lessons/mine')
      .then(res => setData(res.message.data))
      .finally(() => setLoading(false));
  }, []);

  const dateLesson = data?.bookedLessons[0]?.datetime;
  const dateNow = moment();
  const dateObj = moment(dateLesson);
  const diff = dateObj.diff(dateNow);
  const duration = moment.duration(diff).subtract(1, 'days');
  let expireDay = duration.days();

  if (expireDay === -1) {
    expireDay = 0;
  }

  return (
    <div className={classes.rootContainer} style={{ order: order }}>
      <div className={classes.leftContainer}>
        <h1 className={classes.titleCard}>{title}</h1>
        {data?.bookedLessons?.length > 0 ? (
          <DasbhoardLabel
            title={subtitle}
            text={moment(data?.bookedLessons.reduce((bl1, bl2) => bl1.datetime < bl2.datetime ? bl1 : bl2)?.datetime).format('DD MMM YYYY')}
            description={`Days to reschedule: ${expireDay}`}
          />
        ) : (
          <DasbhoardLabel title={subtitle} text={'-'} />
        )}
      </div>
      <div className={classes.rightContainer}>
      {isButtonDisabled ? (
        <Tooltip title="Please take the Placement Test to unlock it." className={classes.tooltip}>
          <span>
            <button
              disabled={isButtonDisabled}
              className={`${classes.btn} ${classes.btnDisabled}`}
              onClick={handleClick}
            >
              {btnName}
            </button>
          </span>
        </Tooltip>
      ) : (
        <button
          disabled={isButtonDisabled}
          className={classes.btn}
          onClick={handleClick}
        >
          {btnName}
        </button>
      )}
      </div>
    </div>
  );
};

export default CardLesson;
