import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Timer as TimerIcon } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    margin: '10px 0',
  },
  time: {
    fontSize: 18,
    letterSpacing: '1px',
  },
}));

const Timer = ({ hours = '', minutes = '', seconds = '' }) => {
  const classes = useStyles();
  const formatUnitTime = time => time.toString().padStart(2, '0');

  return (
    <div className={classes.container}>
      <TimerIcon />
      <span className={classes.time}>
        {formatUnitTime(hours)}:{formatUnitTime(minutes)}:{formatUnitTime(seconds)}
      </span>
    </div>
  );
};

export default Timer;
