import { makeStyles } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { Info } from '@material-ui/icons';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  SecondaryButton,
  SuccessToast,
  Surface,
  TertiaryButton,
  Title,
} from '../../../../components';
import I18n from '../../../../lang/i18n';
import { del, put } from '../../../../utils/network';
import { formatMinutes, getCookie } from '../../../../utils/utils';
import CancelSubscriptionDialog from '../../profile/components/cancel-subscription-dialog';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 24px 32px',
    width: '400px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 24px rgba(23, 26, 31, 0.1)',
    borderRadius: '10px',
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    color: '#22282F',
    marginTop: 5,
  },
  productName: {
    fontSize: 28,
    paddingTop: 18,
    color: '#31ADC5',
    fontWeight: 500,
    margin: 0,
  },
  billed: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.grey.main,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 10,
  },
  value: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  middle: {
    margin: '30px 0',
  },
  cancelled: {
    color: theme.palette.grey.main,
    marginBottom: 20,
  },
  cancelledIcon: {
    color: '#9fa7ad',
    fontSize: 16,
    marginRight: 3,
    marginBottom: -3,
  },
  noSubscription: {
    color: theme.palette.grey.main,
    fontSize: 16,
  },

  btnCard: {
    height: '32px',
    background: '#EBFFFA',
    borderRadius: '24px',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: darken('#EBFFFA', 0.2),
    },

    //label
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.2px',
    color: '#156085',
  },
}));

const BILL_PERIODICITY = {
  1: I18n.t('profile.subscription.monthly'),
  12: I18n.t('profile.subscription.annually'),
};

const SubscribeButton = ({ goToPlans }) => {
  const classes = useStyles();
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <TertiaryButton size="small" onClick={goToPlans} color={'#EBFFFA'}>
        {I18n.t('profile.subscription.buttons.subscribeNow')}
      </TertiaryButton>
    </div>
  );
};

const CardPlan = ({ data, handleRefresh, freeTrial, schoolSubscription }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [cancelSubscriptionDialogOpen, setCancelSubscriptionDialogOpen] = useState(false);
  const handleOpenCancelSubscriptionDialog = () => setCancelSubscriptionDialogOpen(true);
  const handleCloseCancelSubscriptionDialog = () => setCancelSubscriptionDialogOpen(false);
  const handleCancelSubscription = () => {
    setLoading(true);
    del(`users/${getCookie('userId')}/subscription`)
      .then(() => {
        handleCloseCancelSubscriptionDialog();
        setSuccessMessage(I18n.t('profile.subscription.cancelledMessage'));
        handleRefresh();
      })
      .finally(() => setLoading(false));
  };
  const handleReactivateSubscription = () => {
    setLoading(true);
    put(`users/${getCookie('userId')}/subscription`)
      .then(() => {
        setSuccessMessage(I18n.t('profile.subscription.reactivatedMessage'));
        handleRefresh();
      })
      .finally(() => setLoading(false));
  };

  const goToPlans = () => history.push('/home/plans');
  return (
    <>
      <Surface className={classes.container}>
        <Title className={classes.title}>
          {data || freeTrial || schoolSubscription
            ? I18n.t('profile.subscription.title')
            : I18n.t('profile.subscription.noSubscriptionTitle')}
        </Title>

        <div>
          {data ? (
            <>
              <div>
                <p className={classes.productName}>{data.product.name}</p>
                <span className={classes.billed}>
                  {I18n.t('profile.subscription.billed')}{' '}
                  {BILL_PERIODICITY[data.product.periodicity]}
                </span>
              </div>
              <div className={classes.middle}>
                <p>
                  {I18n.t('profile.subscription.startDatetime')}{' '}
                  <span className={classes.value}>{moment(data.startDatetime).format('LLL')}</span>.
                </p>
                <p>
                  {I18n.t('profile.subscription.lastPaymentDatetime')}{' '}
                  <span className={classes.value}>
                    {moment(data.lastPaymentDatetime).format('LLL')}
                  </span>
                  .
                </p>
                {!!data.product.includedLessonsTime ? <p>
                  {I18n.t('profile.subscription.usedLessonsTime')}{' '}
                  <span className={classes.value}>{formatMinutes(data.usedLessonsTime)}</span>{' '}
                  {I18n.t('profile.subscription.includedLessonsTime')}{' '}
                  <span className={classes.value}>
                    {formatMinutes(data.product.includedLessonsTime)}
                  </span>{' '}
                  {I18n.t('profile.subscription.nextBillingDatetime')}{' '}
                  <span className={classes.value}>
                    {moment(data.nextBillingDatetime).format('LLL')}
                  </span>
                  .
                </p> : null}
              </div>
              {data?.cancelledAtDatetime && (
                <p className={classes.cancelled}>
                  <Info className={classes.cancelledIcon} />
                  {I18n.t('profile.subscription.cancelledAtDatetime')}{' '}
                  <span className={classes.value}>
                    {moment(data.cancelledAtDatetime).format('LLL')}
                  </span>
                  . {I18n.t('profile.subscription.cancelAtDatetime')}{' '}
                  <span className={classes.value}>
                    {moment(data.cancelAtDatetime).format('LLL')}
                  </span>
                </p>
              )}
              <div className={classes.actions}>
                {data?.cancelledAtDatetime ? (
                  <SecondaryButton
                    size="small"
                    onClick={handleReactivateSubscription}
                    loading={loading}
                  >
                    {I18n.t('profile.subscription.buttons.reactivate')}
                  </SecondaryButton>
                ) : (
                  <>
                    <TertiaryButton size="small" onClick={handleOpenCancelSubscriptionDialog}>
                      {I18n.t('profile.subscription.buttons.cancel')}
                    </TertiaryButton>
                    <SecondaryButton size="small" onClick={goToPlans}>
                      {I18n.t('profile.subscription.buttons.change')}
                    </SecondaryButton>
                  </>
                )}
              </div>
            </>
          ) : schoolSubscription ? (
            <>
              <div>
                <p className={classes.productName}>{I18n.t('profile.subscription.school.title')}</p>
              </div>
              <div className={classes.middle}>
                <p>
                  {I18n.t('profile.subscription.school.startDatetime')}{' '}
                  <span className={classes.value}>{moment(schoolSubscription.startDatetime).format('LLL')}</span>.
                </p>
              </div>
              {schoolSubscription.cancelAtDatetime && (                
                <p className={classes.cancelled}>
                  <Info className={classes.cancelledIcon} />
                  {I18n.t('profile.subscription.school.cancelAtDatetime')}{' '}
                  <span className={classes.value}>{moment(schoolSubscription.cancelAtDatetime).format('LLL')}</span>
                  {'. '}{I18n.t('profile.subscription.school.renewSubscription')}
                </p>
              )}
            </>
          ) : freeTrial ? (
            <>
              <div>
                <p className={classes.productName}>
                  {I18n.t('profile.subscription.freeTrial.title')}
                </p>
              </div>
              <p>
                {I18n.t('profile.subscription.freeTrial.end')}{' '}
                <span className={classes.value}>{moment(freeTrial).format('LLL')}</span>.
              </p>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button className={classes.btnCard} onClick={goToPlans}>
                  Upgrade
                </button>
              </div>
            </>
          ) : (
            <div>
              <p className={classes.noSubscription}>
                {I18n.t('profile.subscription.noSubscriptionDescription')}
              </p>
              <button className={classes.btnCard} onClick={goToPlans}>
                Upgrade
              </button>
            </div>
          )}
        </div>
      </Surface>
      <SuccessToast
        open={!!successMessage}
        onClose={() => setSuccessMessage('')}
        text={successMessage}
        duration={2000}
      />
      <CancelSubscriptionDialog
        open={cancelSubscriptionDialogOpen}
        handleClose={handleCloseCancelSubscriptionDialog}
        handleConfirm={handleCancelSubscription}
        loading={loading}
      />
    </>
  );
};

export default CardPlan;
