import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Exercise from '../exercise';
import SpeakersTable from '../../components/speakers-table';
import AudioPlayer from '../../components/audio-player';
import AudioPlayerPlaceholder from '../../components/placeholders/audio-player-placeholder';
import { useExercise } from '../../../../../hooks';

const useStyles = makeStyles(() => ({
  audioPlayer: {
    marginRight: 20,
    marginTop: 20,
  },
}));

const formatAnswersResponse = content => {
  return content.map(c => ({
    questionId: c._id,
    wordPosition: c.wordPosition,
    answer: [],
  }));
};

const formatAnswersBody = answers => {
  return answers.map(answer => ({
    ...answer,
    answer: answer.answer.sort().join(','),
  }));
};

const ListeningChoice = ({ data, reviewUnitId, reviewUnitToken, onSuccessSubmit, handleChange, handleEndReview }) => {
  const { id } = useParams();
  const classes = useStyles();
  const reviewUnit = {
    exercise: data,
    reviewUnitId,
    reviewUnitToken,
  };
  const localStorageCounterKey = `${id}-playCounter`;
  const handleSuccessSubmit = (statusCode, data) => {
    window.localStorage.removeItem(localStorageCounterKey);
    if (onSuccessSubmit) onSuccessSubmit(statusCode, data);
  };

  const [isAudioPlaying, setAudioPlaying] = useState(false);
  const handleAudioStartPlaying = () => {
    setAudioPlaying(true);
  }

  const handleAudioStopPlaying = () => {
    setAudioPlaying(false)
  }

  const {
    loading,
    submitting,
    exercise,
    answers,
    result,
    timer,
    setAnswers,
    handleClean,
    handleSubmit,
  } = useExercise({
    formatAnswersResponse,
    onSuccessSubmit: handleSuccessSubmit,
    formatAnswersBody,
    reviewUnit,
  });

  const handleCheck = (questionId, option) => () => {
    setAnswers(prevAnswers =>{

      return prevAnswers.map(answer => {
        return answer.questionId === questionId?
           {
            ...answer,
            answer: answer.answer.includes(option) 
              ? answer.answer.filter(a => a !== option)
              : [ option],
          }
        :
          {...answer,answer: answer.answer.filter(a => a !== option)};        
      })

    }
    );
  };

  const isSpeakerChecked = (questionId, speaker) =>
    answers.find(a => a.questionId === questionId).answer.includes(speaker);

  const isCorrect = questionId =>
    result ? (result.answers ? result.answers.find(a => a.questionId === questionId)?.correct : null) : null;
  ;

  const correctAnswer = (questionId, rows) => {
      if(result?.answers) {
        const answer = result.answers.find(a => a.questionId === questionId)?.correctAnswer;
        return rows[answer-1];
    }
    else { 
      return null;
    }
  }

  return (
    <Exercise
      exercise={exercise}
      loading={loading}
      submitting={submitting}
      finalResult={result}
      handleSubmit={handleSubmit}
      handleRetry={handleClean}
      handleChange={handleChange}
      handleEndReview={handleEndReview}
      timer={timer}
      isReviewUnit={!!reviewUnitId}
    >
      <div className={classes.audioPlayer}>
        {loading ? (
          <AudioPlayerPlaceholder />
        ) : (
          <AudioPlayer
            src={exercise?.audioUrl}
            counterKey={localStorageCounterKey}
            disabled={result !== null || isAudioPlaying}
            onStartPlaying={handleAudioStartPlaying}
            onStopPlaying={handleAudioStopPlaying}
            anotherTrackPlaying={isAudioPlaying}
            canPlay={!submitting}
          />
        )}
      </div>
      <SpeakersTable
        loading={loading}
        numberOfSpeakers={exercise?.numberOfSpeakers}
        handleCheck={handleCheck}
        rows={exercise?.options}
        speakers={exercise?.content}
        isChecked={isSpeakerChecked}
        isCorrectFunction={isCorrect}
        correctAnswerFunction={correctAnswer}
      />
    </Exercise>
  );
};

export default ListeningChoice;
