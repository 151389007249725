import React from 'react';
import { TitleButton } from '../../../../components';
import { getCookie } from '../../../../utils/utils';
import I18n from 'i18n-js';
import history from '../../../../utils/history';

const BookALessonButton = ({ titleButton }) => {
  const handleBookALesson = () => history.push('/home/book_lesson/new');
  return (
    !getCookie('bookLessonsEnabled') ? null : 
      <TitleButton
        onClick={handleBookALesson}
        startIcon={<img src="icons-calendar.svg" alt="calendar-icon" />}
      >
        {I18n.t('bookedLessons.buttons.bookLesson')}
      </TitleButton>
  );
};

export default BookALessonButton;
