import { Dialog, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';
import React, { useEffect, useState } from 'react';
import { post } from '../../../../utils/network';
import { getCookie } from '../../../../utils/utils';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '24px',
    flex: 1,
    width: '100%',
    background: '#FFFFFF',
    boxShadow: '0px 4px 24px rgba(34, 40, 47, 0.08)',
    borderRadius: '10px',
    [theme.breakpoints.up('lg')]: {
      maxWidth: 700,
    },
  }, 

  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: '24px',
    marginBottom: 24,
  },

  img: {
    objectFit: 'cover',
    overflow: 'hidden',
    width: '100%',
    maxHeight: 300,
  },

  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '40px',
    lineHeight: '60px',
    letterSpacing: '-0.952381px',
    color: '#31ADC5',
    textAlign: 'center',
    margin: '0px 0px 16px',
  },

  text: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.2px',
    color: '#22282F',
    flex: 'none',
    paddingLeft: '64px',
    paddingRight: '64px',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },

  btnTest: {
    //Button
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 24px',
    background: '#31ADC5',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',

    //Label
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.2px',
    color: '#FFFFFF',

    '&:hover': {
      backgroundColor: darken('#31ADC5', 0.4),
    },
  },

  btnSkip: {
    //Button
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 24px',
    background: '#EBFFFA',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',

    //Label
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.2px',
    color: '#156085',

    '&:hover': {
      backgroundColor: darken('#EBFFFA', 0.4),
    },
  },
}));

const CardWelcome = ({ img, handleClick, open, handleClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isLoaded, setIsLoaded] = useState(false);
  const smallScreen = useMediaQuery(theme.breakpoints.down(1060));

  const history = useHistory();

  useEffect(() => {
    const image = new Image();
    image.src = img;
    image.onload = () => {
      setIsLoaded(true);
    };
  }, [img]);

  const handleEnrollCourse = () => {
    post(`users/${getCookie('userId')}/course`, { courseId: '60edcdca94f8d5e3eec2a6ac' })
      .then(() => {
        window.location.reload();
      })
      .finally(() => {
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={smallScreen ? 'xl' : 'md'}
      fullScreen={smallScreen}
    >
      <div className={classes.cardContainer}>
        {isLoaded ? (
          <img className={classes.img} src={img} alt={img} />
        ) : (
          <div style={{ width: '100%', height: '100%', minHeight: 300 }} />
        )}
        <div>
          <h1 className={classes.title}>Welcome to Testbridge!</h1>
          <p className={classes.text}>
            We're off to an exciting journey to enhance your English and set you up for success in
            your Cambridge exam.
            <br /> Start by taking our placement test to find out your current English level. It will
            only take 5-10 minutes of your time.
            <br /> Best of luck, and we look forward to supporting you every step of the way.
          </p>
        </div>
        <div className={classes.btnContainer}>
          <button className={classes.btnSkip} onClick={handleEnrollCourse}>
            Skip
          </button>
          <button className={classes.btnTest} onClick={handleClick}>
            Take the test
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default CardWelcome; 