import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';
import { EventBusy } from '@material-ui/icons';
import {
  DatePicker,
  ErrorToast,
  NoResults,
  SecondaryButton,
  SmallButton,
  Subtitle,
  SuccessToast,
  Surface,
  SurfacePage,
  TableCell,
  Title,
} from '../../../components';
import I18n from '../../../lang/i18n';
import { get, patch } from '../../../utils/network';
import config from '../../../utils/config';
import { useHistory } from 'react-router-dom';
import { formatMinutes, getCookie, handle402BookLesson } from '../../../utils/utils';
import useQueryParams from '../../../hooks/use-query-params';

const useStyles = makeStyles(theme => ({
  search: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0',
    flexWrap: 'wrap',
  },
  tableCell: {
    border: 'none',
    padding: '14px 20px',
    [theme.breakpoints.down(600)]: {
      padding: '14px 8px',
    },
  },
  tableContainer: {
    marginTop: 50,
    marginBottom: 20,
    width: '95%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  unusedLessonsTime: {
    fontSize: 18,
    marginTop: 10,
    opacity: 0.8,
    color: theme.palette.text.primary,
  },
}));

const BookLesson = () => {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = useQueryParams();
  const [unusedLessonsTime, setUnusedLessonsTime] = useState(
    history?.location?.state?.unusedLessonsTime || null
  );
  const minDate = moment().add(config.bookALessonMinOffset, 'days');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [rescheduleError, setRescheduleError] = useState(null);
  const [date, setDate] = useState(minDate);
  const [dateError, setDateError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);

  const handleChangeDate = newDate => {
    setDateError(false);
    setData(null);
    setDate(moment(newDate));
  };

  const formatDateURLEncoded = (d = date) => moment(d).format().replace("+", "%2B");
  const formatDate = (d = date) => moment(d).format();
  

  const getLessons = () => {
    if (date.isBefore(minDate, 'day')) {
      setDateError(I18n.t('bookLesson.messages.minDate'));
      return;
    }
    setLoading(true);
    setData(null);
    get(`available-times?date=${formatDateURLEncoded()}`)
      .then(res => setData(res.message.data.sort((a, b) => (a.start > b.start ? 1 : -1))))
      .finally(() => setLoading(false));
  };

  const handle400 = () => {
    setRescheduleError(I18n.t('bookLesson.messages.error'));
    return Promise.reject(400);
  };

  const bookLesson = (lesson, index) => {
    const datetime = new Date(date);
    const [hours, minutes] = lesson.start.split(':');
    datetime.setHours(hours, minutes, 0, 0);
    const lessonId = queryParams.get('lessonId');
    if (lessonId) {
      setSubmitting(index);
      patch(
        `scheduled-lessons/${lessonId}`,
        { datetime: formatDate(datetime), teacher: lesson.teacherId },
        { errorHandlers: [{ status: 400, method: handle400 }, {status: 402, method: handle402BookLesson(history)}] }
      )
        .then(res => {
          if(res.status === 402) return;
          return setSuccessMessage(I18n.t('bookLesson.messages.successRescheduled'));
        })
        .catch(() => {})
        .finally(() => setSubmitting(false));
    } else {
      history.push({
        pathname: '/home/book_lesson/form',
        search: `?teacher=${lesson.teacherId}&datetime=${formatDateURLEncoded(datetime)}`,
      });
    }
  };

  useEffect(() => {
    get(`users/${getCookie('userId')}/unusedLessonsTime`, {
      errorHandlers: [{ status: 404, method: () => Promise.reject(404) }],
    }).then(res => setUnusedLessonsTime(res.message.data.unusedLessonsTime));
  }, []);

  return (
    <SurfacePage>
      <Title withBackArrow>{I18n.t('bookLesson.title')}</Title>
      <div>
        <Subtitle>{I18n.t('bookLesson.subtitle')}</Subtitle>
        {!! unusedLessonsTime && unusedLessonsTime>0 && (
          <Subtitle className={classes.unusedLessonsTime}>
            {I18n.t('bookLesson.unusedLessonsTime')}: {formatMinutes(unusedLessonsTime)}
          </Subtitle>
        )}
        <div className={classes.search}>
          <DatePicker
            label={I18n.t('bookLesson.lessonDate')}
            value={date}
            minDate={minDate}
            onChange={handleChangeDate}
            disablePast
            error={dateError}
          />
          <SecondaryButton onClick={getLessons} loading={loading}>
            {I18n.t('bookLesson.search')}
          </SecondaryButton>
        </div>
        {data &&
          (data.length > 0 ? (
            <TableContainer component={Surface} className={classes.tableContainer}>
              <Table classes={{ root: classes.table }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{I18n.t('bookLesson.startsAt')}</TableCell>
                    <TableCell>{I18n.t('bookLesson.endsAt')}</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((lesson, index) => (
                    <TableRow hover key={index}>
                      <TableCell>{lesson.start}hs</TableCell>
                      <TableCell>{lesson.end}hs</TableCell>
                      <TableCell align="center" style={{ width: 120 }}>
                        <SmallButton
                          onClick={() => bookLesson(lesson, index)}
                          loading={submitting === index}
                        >
                          {I18n.t('bookLesson.book')}
                        </SmallButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <NoResults icon={EventBusy} text={I18n.t('bookLesson.messages.noLessons')} />
          ))}
      </div>
      <ErrorToast
        open={!!rescheduleError}
        onClose={() => setRescheduleError(false)}
        text={rescheduleError}
      />
      <SuccessToast
        open={!!successMessage}
        onClose={() => history.push('/home/book_lesson')}
        text={successMessage}
        duration={3000}
      />
    </SurfacePage>
  );
};

export default BookLesson;
