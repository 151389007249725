import Swal from 'sweetalert2';
import history from './history';
import config from './config';
import I18n from '../lang/i18n';
import { getCookie, deleteAllCookies } from './utils';

const TIMEOUT = 100000;

export const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': true,
};

export const HTTPMethods = {
  POST: 'POST',
  GET: 'GET',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
  PUT: 'PUT',
};

export const swal500 = err => {
  if (![400, 401, 402, 403, 404].includes(err)) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: I18n.t('global.serverError'),
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Ok',
      cancelButtonText: I18n.t('global.retry'),
      confirmButtonColor: '#31adc5',
      cancelButtonColor: '#9fa9ba',
      customClass: {
        container: 'my-swal',
      },
    }).then(result => {
      if (!result.isConfirmed) history.go(0);
    });
  }
  return Promise.reject(err);
};

const defaultErrors = statusCode => {
  if (statusCode === 404) history.push('/404');

  if (statusCode === 401) {
    deleteAllCookies();
    history.push('/login');
  }

  if (statusCode === 402) {
    return Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: I18n.t('global.unsubscribedDialog.unsubscribed'),
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: `<span style='color:#FFF; font-weight: bold'>${I18n.t('global.unsubscribedDialog.goToPlans')}</span>`,
      cancelButtonText: `<span style='color:#00acc2; font-weight: bold'>${I18n.t('global.unsubscribedDialog.goToHome')}</span>`,
      confirmButtonColor: '#2dacc4',
      cancelButtonColor: '#f8f8f9',
      
    }).then((result) => {
      if (result.isConfirmed) history.push('/home/plans');
      else history.push('/home');
      return Promise.reject(402);
    })
  }

  if (statusCode === 403) {
    Swal.fire({
      icon: 'error',
      title: 'Oops!',
      text: I18n.t('global.permissionDenied'),
      customClass: {
        container: 'my-swal',
      },
    }).then(() => history.push('/home'));
  }
  return Promise.reject(statusCode);
};

export const handleResponse = (res, errorHandlers) => {
  const statusCode = res.status;

  if (res.ok) return res.text().then(data => ({ ...JSON.parse(data), statusCode: res.status }));

  if (errorHandlers !== undefined) {
    const errorHandler = errorHandlers.find(handler => handler.status === statusCode);
    if (!!errorHandler) return errorHandler.method(res);
  }

  return defaultErrors(statusCode);
};

const doFetch = ({
  endpoint,
  body = null,
  method,
  headers = DEFAULT_HEADERS,
  isForm = false,
  token = false,
  errorHandlers,
}) => {
  let options = {
    method: method,
    headers,
  };

  if (token) options.headers.Authorization = 'Bearer ' + token;
  else if (config.devEnvironment) options.headers.Authorization = 'Bearer ' + getCookie('token');
  else options.credentials = 'include';

  if (!isForm && body) options.body = JSON.stringify(body);
  else if (body && isForm) options.body = body;

  const controller = new AbortController();
  const { signal } = controller;

  setTimeout(() => {
    controller.abort();
  }, TIMEOUT);

  return fetch(config.backEndUrl + endpoint, { ...options, signal })
    .then(res => handleResponse(res, errorHandlers))
    .catch(err => swal500(err));
};

export const get = (endpoint, options) =>
  doFetch({ endpoint, method: HTTPMethods.GET, ...options });

export const post = (endpoint, body, options) =>
  doFetch({ endpoint, body, method: HTTPMethods.POST, ...options });

export const patch = (endpoint, body, options) =>
  doFetch({ endpoint, body, method: HTTPMethods.PATCH, ...options });

export const del = (endpoint, body, options) =>
  doFetch({ endpoint, body, method: HTTPMethods.DELETE, ...options });

export const put = (endpoint, body, options) =>
  doFetch({ endpoint, body, method: HTTPMethods.PUT, ...options });

// Usage: generateFormData({ picture: { uri : image.uri, type: 'image/jpeg' } })
export const generateFormData = form => {
  let formData = new FormData();
  Object.keys(form).forEach(key => formData.append(key, form[key]));
  return formData;
};

export const handle400 = (fields, updateErrors) => err => {
  err.text().then(error => {
    let errorObject = JSON.parse(error);
    let errors = {};
    errorObject.fields.forEach(field => {
      const regex = new RegExp(field.name);
      errors[field.name] = field.message.replace(regex, fields[field.name]);
    });
    updateErrors(errors);
  });
  return Promise.reject(400);
};
