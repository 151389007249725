import { makeStyles, TableCell as MUITableCell } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  tableCell: {
    border: 'none',
    padding: '14px 20px',
    [theme.breakpoints.down(600)]: {
      padding: '14px 8px',
    },
  },
}));

const TableCell = ({ children, align = 'left', style }) => {
  const classes = useStyles();
  return (
    <MUITableCell
      classes={{
        root: classes.tableCell,
      }}
      align={align}
      style={style}
    >
      {children}
    </MUITableCell>
  );
};

export default TableCell;
