import React from 'react';
import { CircularProgress, IconButton, useTheme, withStyles, Tooltip } from '@material-ui/core';
import * as Swal from 'sweetalert2';
import styles from './styles';
import Loading from '../loading';
import { capitalize } from '../../utils/utils';
import { Surface } from '../surface';
import { PrimaryButton, SecondaryButton } from '../button';
import SectionHeader from '../section-header';
import { Create as CreateIcon, Delete as DeleteIcon } from '@material-ui/icons';

const FormLayout = ({
  classes,
  handleClose,
  renderMoreActions,
  loading,
  editMode,
  submitting,
  handleSubmit,
  isNew,
  entityName,
  children,
  handleCancel = handleClose,
  hasChanges,
  handleEdit,
  handleDelete,
}) => {
  const theme = useTheme();

  const getTitle = () => {
    if (isNew) return `Crear ${capitalize(entityName)}`;
    if (editMode) return `Editar ${capitalize(entityName)}`;
    return capitalize(entityName);
  };

  const close = isCancel => (isCancel ? handleCancel() : handleClose());

  const shouldClose = isCancel => {
    if (!editMode || !hasChanges?.()) close(isNew ? false : isCancel);
    else {
      Swal.fire({
        title: 'Descartar cambios',
        text: 'Si continúa perderá todos los cambios',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: theme.palette.primary.main,
        cancelButtonText: 'No, conservar cambios',
        confirmButtonText: 'Salir',
        preConfirm: () => close(isNew ? false : isCancel),
      });
    }
  };

  return (
    <div className={classes.root}>
      <SectionHeader title={getTitle()} onBack={() => shouldClose(false)} withBackButton>
        <div className={classes.topActionsContainer}>
          {renderMoreActions?.()}
          {!editMode && (
            <Tooltip title={`Editar ${entityName}`}>
              <IconButton onClick={handleEdit} style={{ padding: 10, marginRight: 6 }}>
                <CreateIcon />
              </IconButton>
            </Tooltip>
          )}
          {!isNew && (
            <Tooltip title={`Eliminar ${entityName}`}>
              <IconButton onClick={handleDelete} style={{ padding: 10 }}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </SectionHeader>
      <Surface className={classes.container}>
        {loading ? (
          <Loading />
        ) : (
          <form className={classes.form}>
            {children}
            {editMode && (
              <div className={classes.actionsContainer}>
                <SecondaryButton disabled={loading || submitting} onClick={() => shouldClose(true)}>
                  Cancelar
                </SecondaryButton>
                <div className={classes.submitContainer}>
                  <>
                    {submitting && (
                      <CircularProgress color="primary" size={22} style={{ marginRight: 10 }} />
                    )}
                    <PrimaryButton
                      type="submit"
                      disabled={loading || submitting}
                      onClick={handleSubmit}
                    >
                      Confirmar
                    </PrimaryButton>
                  </>
                </div>
              </div>
            )}
          </form>
        )}
      </Surface>
    </div>
  );
};

export default withStyles(styles)(FormLayout);
