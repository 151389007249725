import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Check } from '@material-ui/icons';
import I18n from '../../../../lang/i18n';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 20,
    fontWeight: 500,
  },
  activeIndex: {
    fontSize: 16,
    margin: '4px 0',
    paddingRight: 10,
    color: '#22282f',
    fontWeight: 600,
    cursor: 'pointer',
  },
  inactiveIndex: {
    fontSize: 16,
    margin: '5px 0',
    paddingRight: 10,
    color: theme.palette.grey.main,
    cursor: 'pointer',
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.grey.light,
    margin: '14px 0',
  },
  lesson: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
}));

const UnitLesson = ({ currentIndex, lessons, handleClick, loading }) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.title}> {I18n.t('courseJourney.unit.lessons')}</Typography>
      <div className={classes.divider} />
      {loading ? (
        <>
          <Skeleton variant="text" height={30} width="85%" />
          <Skeleton variant="text" height={30} width="85%" />
          <Skeleton variant="text" height={30} width="85%" />
        </>
      ) : (
        lessons?.map((lesson, index) => (
          <div className={classes.lesson} key={index}>
            {lesson?.completed ? <Check color="secondary" /> : <div style={{ width: 24 }} />}
            <Typography
              key={index}
              className={currentIndex === index ? classes.activeIndex : classes.inactiveIndex}
              onClick={() => handleClick(index)}
            >
              {lesson.name}
            </Typography>
          </div>
        ))
      )}
    </>
  );
};

export default UnitLesson;
