import React, { useState } from 'react';
import { Box, CircularProgress, makeStyles, Typography, useTheme } from '@material-ui/core';
import { MIN_GREEN, MIN_ORANGE, MIN_YELLOW } from '../../../../utils/utils';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 8px',
    cursor: 'pointer',
  },
  circle: {
    width: 56,
    height: 56,
    borderRadius: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleHover: {
    position: 'absolute',
    height: 32,
    width: 32,
    borderRadius: 16,
    bottom: 0,
    right: -15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressValue: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: 600,
  },
  progressCheck: {
    margin: 'auto',
  },
  title: {
    fontSize: 16,
    marginTop: 8,
  },
  result: {
    fontSize: 22,
    fontWeight: 500,
  },
}));

const TOPIC_IMG = {
  Grammar: 'menu-book.svg',
  'Use of English': 'english-flag.svg',
  Reading: 'auto-stories.svg',
  Writing: 'edit.svg',
  Listening: 'headphones.svg',
  Speaking: 'record-voice.svg',
};

const TopicResult = ({ topic, result }) => {
  const classes = useStyles();
  // TODO: define result letter color
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>{topic}</Typography>
      <div
        className={classes.circle}
        style={{ backgroundColor: 'rgba(23, 26, 31, 0.05)', margin: '5px 0' }}
      >
        <img className={classes.progressCheck} src={TOPIC_IMG[topic]} alt={TOPIC_IMG[topic]} />
      </div>
      <Typography className={classes.result}>{result}</Typography>
    </div>
  );
};

const TopicProgress = ({ id, navigateToExercise, topic, topicIndex = null, score, loading }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mouseOver, setMouseOver] = useState(false);
  const progressColor =
    score > MIN_GREEN
      ? theme.palette.progress.green
      : score > MIN_YELLOW
      ? theme.palette.progress.yellow
      : score > MIN_ORANGE
      ? theme.palette.progress.orange
      : theme.palette.progress.red;

  return (
    <div
      className={classes.container}
      onClick={() => navigateToExercise(id)}
      onMouseOver={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          value={score === 0 ? 3 : score}
          size={58}
          thickness={2}
          style={{ color: progressColor }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <div
            className={classes.circle}
            style={{
              backgroundColor: mouseOver ? 'rgba(23, 26, 31, 0.08)' : 'rgba(23, 26, 31, 0.05)',
            }}
          >
            <img className={classes.progressCheck} src={TOPIC_IMG[topic]} alt={TOPIC_IMG[topic]} />
          </div>
          {mouseOver && score >= 0 && (
            <div
              className={classes.circleHover}
              style={{
                backgroundColor: progressColor,
              }}
            >
              {score === 100 ? (
                <img src="check-circle.svg" alt="completed" />
              ) : (
                <Typography className={classes.progressValue}>{score}</Typography>
              )}
            </div>
          )}
        </Box>
      </Box>
      <Typography className={classes.title}>
        {topicIndex !== null ? `${topicIndex}. ` : ''}
        {topic}
      </Typography>
      {loading && <CircularProgress size={20} />}
    </div>
  );
};

export { TopicResult, TopicProgress };
