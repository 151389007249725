import React, { memo, useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import moment from 'moment';
import { TextInput } from './text-input';
import I18n from '../lang/i18n';

const localeMap = {
  en: enLocale,
  es: esLocale,
};

function DatePicker({
  label,
  value,
  onChange,
  error,
  minDate,
  maxDate,
  disablePast,
  disableFuture,
  disabled,
}) {
  const classes = useStyles();
  const [locale, setLocale] = useState('en');

  useEffect(() => {
    setLocale(I18n.locale);
  }, []);

  return disabled ? (
    <TextInput disabled label={label} value={moment(value).format('L')} />
  ) : (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
      <KeyboardDatePicker
        label={label}
        format="dd/MM/yyyy"
        value={value}
        InputProps={{
          readOnly: disabled,
          disableUnderline: disabled,
        }}
        InputAdornmentProps={{ position: 'end' }}
        onChange={(date, value) => onChange(date)}
        invalidDateMessage={I18n.t('datePicker.invalidDateMessage')}
        minDateMessage={I18n.t('datePicker.minDateMessage')}
        minDate={minDate}
        maxDate={maxDate}
        maxDateMessage={I18n.t('datePicker.maxDateMessage')}
        cancelLabel={I18n.t('datePicker.cancel')}
        okLabel={I18n.t('datePicker.ok')}
        disableFuture={disableFuture}
        disablePast={disablePast}
        className={classes.datePicker}
        error={!!error}
        helperText={error}
        style={{ margin: '10px 0', maxWidth: 280 }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default memo(DatePicker);
