export default {
  global: {
    serverError: 'There was a server error. Please try again later.',
    retry: 'Try again',
    permissionDenied: `You don't currently have permissions to access this view.`,
    loading: 'Loading...',
    minutes: 'minutes',
    hours: 'hours',
    unsubscribedDialog: {
      unsubscribed: 'To use this feature, you need to be subscribed to a plan',
      lessonAfterCancelled: 'Your lesson is scheduled for a date after your account is set to be terminated. Please select an alternative date or consider renewing your subscription',
      goToBookLessons: 'Choose another date',
      goToPlans: 'Go to plans',
      goToHome: 'Not now',
    },
  },
  datePicker: {
    cancel: 'Cancel',
    ok: 'OK',
    invalidDateMessage: 'Invalid Date Format',
    minDateMessage: 'Invalid Date',
    maxDateMessage: 'Invalid Date',
  },
  select: {
    placeholder: 'Select',
  },
  questionCheck: {
    question: 'Question',
    choose: 'choose this question',
  },
  textAreaAnswer: {
    notSelected: 'You have not selected a question.',
    wordCount: 'Word count',
  },
  login: {
    title: 'Login',
    inputs: {
      email: 'Email',
      password: 'Password',
    },
    buttons: {
      forgotPassword: 'Forgot password?',
      login: 'Log in',
      signup: 'Register',
    },
    errors: {
      fillEmail: 'Email is required.',
      fillPassword: 'Password is required.',
      wrongEmailOrPassword: 'The email or password you entered is incorrect.',
      details: 'Details',
      validateAccount: 'You will receive an email to validate your account.',
    },
    signupCaption: `Don't have an account?`,
    loading: 'Logging in...',
    cookiesBanner: "<b>Informativa</b><br/>Noi e terze parti selezionate utilizziamo esclusivamente cookie o tecnologie simili per finalità tecniche, come specificato nella <a {}>Cookie Policy</a>. <br/>Trattandosi di soli cookie tecnici, il rifiuto del consenso non consentirebbe la normale navigazione e fruizione del sito web e degli applicativi in esso contenuti.",
    gotIt: "Accetta"
  },
  passwordReset: {
    dialog: {
      title: 'Recover password',
      emailCaption: 'Enter your email address:',
      email: 'Email',
      buttons: {
        cancel: 'Cancel',
        confirm: 'Confirm',
      },
      errors: {
        invalidEmail: 'Email is not valid.',
        emailNotRegistered: 'The email entered is not registered in the system.',
      },
      success: 'You will receive an email so you can restore your password.',
    },
  },
  drawer: {
    home: 'Home',
    courseJourney: 'Course journey',
    extraPractice: 'Extra practice',
    bookLesson: 'Book a lesson',
    courseMap: 'Course map',
    statistics: 'Statistics',
    profile: 'Profile',
    logout: 'Logout',
  },
  courseJourney: {
    title: 'Course Journey',
    goToCourseMap: 'Go to course map',
    activeUnit: 'Active unit',
    viewUnit: 'View unit',
    completeUnit: 'Complete unit',
    unit: {
      bookLesson: 'Book a lesson',
      lessons: 'Lessons',
    },
    exercise: {
      next: 'Next',
      submit: 'Submit',
      retry: 'Try again',
      correctAnswers: 'correct answers.',
      score: 'score.',
      answersCaption: {
        A: 'Excellent job! Keep it up!',
        B: 'Well done! Perhaps you can do even better!',
        C: 'You have reached the minimum to pass. Try a bit harder and improve your score.',
        D: 'You are just below the minimum requirement. Try again.',
        E: "Unfortunately you wouldn't have passed. Try again.",
      },
      listeningLimit:
        'The audio cannot be played again because you have reached the limit of allowed reproductions.',
      playerDisabled: `You must click on 'Try Again' to be able to play the audio again.`,
      anotherTrackPlaying: `An audio track is currently playing.`,
      keywordList: 'Keyword list',
      genericTypes: {
        reading: 'Reading',
        useOfEnglish: 'Use of English',
        listening: 'Listening',
        writing: 'Writing',
        overallScore: 'Overall Score',
      },
      feedback: {
        gotIt: 'Got it!',
        tutorial: 'You can hover over your answers to see the feedback',
        checkbox: 'Do not show again',
        writing: {
          loading: 'Reviewing the exercise, please hold on a few seconds...',
          feedbackTitle: 'Here\'s your writing exercise review:',
        },
      },
    },
    placement: {
      title: 'Placement test',
      subtitle: 'Please answer the questions below',
      answeredQuestions: 'Answered questions',
      startQuestion: 'How would you assess your English level?',
      suggestedCourse: 'Your suggested course is',
      correctAnswers: 'correct answers.',
      basedTest: 'Based on your test, you are now at',
      level: ' level.',
      raiseLevel:
        'As your level was lower than the test, we suggest that you raise your level a bit before taking the course.',
      wellDone: 'Well done! Go for B2 First. Start the course now!',
      suggestedCertify: 'Your will prepare to certify',
      enrollCaption: 'Would you prefer to enroll in another course?',
      enrollNow: 'Start now',
      enrollOtherCourse: 'Choose another course',
      next: 'Next',
      start: 'Start',
      msgBased1: 'We can only offer a B2 course at the moment.',
      msgBased2: 'Are you willing to work hard and try out the B2 course?',
      msgBased3: 'Well done! You should be ready to prepare for B2 First.',
      msgBased4: 'Go for it!',
      msgBased5: 'Great job! We cannot offer a C1 course at the moment.',
      msgBased6: 'How about preparing a B2 First and securing a certificate?',
      msgBased7: 'Brilliant! We cannot offer a C2 course at the moment.',
    },
    review: {
      title: 'Revision',
      revisionScore: 'Revision score',
      start: {
        welcome: 'Welcome to',
        message1: 'Take the opportunity to assess your understanding of units',
        message2: `by testing yourself on the material you've learned.`,
        messageAnd: 'and',
        explanation: `This revision includes six exercises, designed to be completed in around 10 minutes each. Your progress is automatically saved after submitting each exercise, so you can continue from where you left off in the next session.`,
        next: 'Next',
        start: 'Start',
        continue: 'Continue'
      },
      feedback: {
        tips: 'TIPS',
        A: {
          title: 'Fantastic! You would have passed with flying colours.',
          subtitle: 'You can definitely move forward.',
          tips: [
            "Don't forget to revise every now and then.",
            'Do ”Extra Practice” to maintain or improve your Overall score.',
            'Book a lesson with an online teacher.',
          ],
        },
        B: {
          title: 'Well done! You have a good grasp of the material in these units.',
          subtitle: 'Feel free to move forward.',
          tips: [
            'Remember it is always wise to revise.',
            'Try the test again or do “Extra Practice” to improve your Overall score.',
            'Book a lesson with an online teacher.',
          ],
        },
        C: {
          title: 'Good! You have reached the minimum requirement to pass.',
          subtitle: 'You can move forward.',
          tips: [
            'Revise to gain more confidence and try to improve your score',
            'Try the test again or do “Extra Practice” to improve your Overall score',
            'Book a lesson with an online teacher',
          ],
        },
        D: {
          title:
            'You haven’t reached the minimum requirement to pass this revision. However, your Overall score is good enough to unblock your journey. ',
          subtitle: 'Continue revising to achieve a higher score.',
          tips: [
            'Go over the units. Watch the videos and do the exercises',
            'Do “Extra Practice” to improve your Overall score and try the test again',
            'Book a lesson with an online teacher',
          ],
          blocked: {
            title: 'Your journey is blocked but you are close to passing.',
            subtitle: 'Revise and try to achieve a higher score.',
            tips: [
              'Go over the units. Watch the videos and do the exercises',
              'Do “Extra Practice” to improve your Overall score and try the test again',
              'Book a lesson with an online teacher',
            ],
          },
        },
        E: {
          title: "Unfortunately you haven't reached the minimum requirement to pass.",
          subtitle: 'You must improve your Revision score to unblock the journey.',
          tips: [
            'Go over the units. Watch the videos and do the exercises',
            'Do “Extra Practice” to improve your Overall score and try the test again',
            'Book a lesson with an online teacher',
          ],
        },
      },
      done: 'Done',
    },
  },
  bookLesson: {
    title: 'Book a lesson',
    subtitle: 'Please complete the date',
    search: 'Search',
    lessonDate: 'Lesson date',
    startsAt: 'Starts at',
    endsAt: 'Ends at',
    book: 'Book',
    unusedLessonsTime: 'Available subscription time',
    messages: {
      minDate: 'The booking of lessons must be at least 24 hours in advance.',
      success: 'The lesson has been successfully booked!',
      error: 'The selected lesson is not available.',
      noLessons: `There are no lessons available for the selected date. \n Please try another date.`,
      successWithSubscription:
        'The lesson has been successfully booked using the time included of your subscription!',
      successRescheduled: 'The lesson has been successfully rescheduled!',
    },
    payment: {
      productName: 'Product name',
      price: 'Price',
      success: {
        title: 'Success',
        subtitle: 'You’ve successfully purchased',
        caption:
          'You will received an email confirmation soon with the complete information of the purchase.',
        done: 'Done',
      },
      failure: {
        title: 'Error',
        subtitle: 'Your purchase has not been confirmed',
        goHome: 'Go home',
      },
    },
    form: {
      unit: 'Unit',
      topic: 'Topic',
      comments: 'Comments',
      unitQuestion: 'Which unit would you like to focus on in this lesson?',
      topicQuestion: 'Which skill would you like to focus on in this lesson?',
      topics: {
        lessonReview: 'Lesson Review',
        practiceSpeaking: 'Practice Speaking',
        unitExercisesCorrection: 'Unit exercises correction',
        examStrategy: 'Exam strategy',
      },
    },
  },
  bookedLessons: {
    title: 'Your online lessons',
    booked: 'Booked',
    attended: 'Attended',
    buttons: {
      bookLesson: 'Book a lesson',
      attend: 'Attend',
      attended: 'Attended',
      reschedule: 'Reschedule',
      expires: 'This reservation expires on',
      pay: 'Checkout now',
    },
    noLessons: {
      title: 'No booked lessons yet.',
      bookLesson: 'Book a lesson',
      text: 'with our teachers to improve your English level or go back to your current Unit.',
    },
    alerts: {
      expired: {
        title: 'This lesson has expired',
        text: 'Please book a new lesson.',
        confirm: 'Ok',
      },
    },
  },
  levelSelectorDialog: {
    title: 'Select the level you want to continue with',
    confirm: 'Confirm',
  },
  home: {
    title: 'Home',
    welcome: 'Hello, ',
    placementTest: 'Placement Test',
    yourLevel: 'Your Level',
    takeAgain: 'Take again',
    activePlan: 'Your active plan',
    upgrade: 'Upgrade',
    student: {
      cards: {
        profile: 'My profile',
        course: 'My course',
        placementTest: 'Placement Test',
      },
      afterReading: 'After reading our',
      faq: 'frequently asked questions',
      callTechnical: ', you can contact us for technical assistance at ',
    },
    teacher: {
      welcome: 'Welcome to Testbridge',
      navigateTo: 'As an integral part of our teaching community, your contribution is highly valued. Please navigate to your personalized ',
      calendar: 'Calendar',
      upcomingLessons: ' to see your upcoming lessons.',
    },
  },
  profile: {
    title: 'My profile',
    inputs: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      course: 'Course',
    },
    errors: {
      fillFirstName: 'First Name is required.',
      fillLastName: 'Last Name is required.',
      fillEmail: 'Email is required.',
      invalidFirstName: 'Firstname is not valid.',
      invalidLastName: 'Lastname is not valid.',
      invalidEmail: 'Email is not valid.',
    },
    buttons: {
      passwordReset: 'Change my password',
      cancel: 'Cancel',
      edit: 'Edit',
      save: 'Save',
    },
    messages: {
      passwordReset: 'We have sent you an email so you can change your password.',
      updated: 'Your profile has been successfully updated!',
    },
    subscription: {
      title: 'Active subscription',
      noSubscriptionTitle: 'No active subscription',
      noSubscriptionDescription: "You don't have any Testbridge subscription yet.",
      billed: 'billed',
      monthly: 'monthly',
      annually: 'annually',
      startDatetime: 'Subscription began on',
      lastPaymentDatetime: 'Last payment received on',
      usedLessonsTime: 'You have used',
      includedLessonsTime: 'from the total of',
      nextBillingDatetime: 'available for lessons in your plan. This will be renewed on',
      cancelledAtDatetime: 'Subscription cancelled on',
      cancelAtDatetime: 'It will be removed on',
      freeTrial: {
        title: 'Free trial',
        end: 'Free trial ends on',
      },
      school: {
        title: 'School plan',
        startDatetime: 'Your subscription began on',
        cancelAtDatetime: 'Your plan will expire on',
        renewSubscription: 'Please contact your school to renew it.',
      },
      buttons: {
        cancel: 'Cancel subscription',
        change: 'Change subscription',
        reactivate: 'Reactivate subscription',
        subscribeNow: 'Subscribe now',
      },
      cancelledMessage: 'Your subscription has been cancelled.',
      reactivatedMessage: 'Your subscription has been reactivated!',
      cancelDialog: {
        title: 'Are you sure you want to cancel your subscription?',
        text: 'You will still be able to use your plan and enable it again before the next billing period. After that moment it will be permanently deleted and you will not be charged until your subscribe again.',
        cancel: 'Cancel subscription',
        keep: 'Keep subscription',
      },
      lastPaymentUnsuccessful: 'Last payment unsuccessful',
      lastPaymentUnsuccessfulDescription:
        'You will not be able to upgrade your subscription until you pay the last invoice.',
      lastPaymentUnsuccessfulButton: 'Pay now',
    },
  },
  statistics: {
    title: 'Your statistics',
    subtitle: 'Your course score',
    text: 'Measured on review and extra practice exercises',
  },
  courseMap: {
    title: 'Course map',
    bookLesson: 'Book a lesson',
    unit: 'Unit',
    topics: 'Topics',
    grammar: 'Grammar',
    vocabulary: 'Vocabulary',
  },
  signup: {
    title: 'Free Trial!',
    captions: [
      'Accesso libero per 15 giorni',
      'Prime 2 unità e Revision 1',
      'Carta di credito non richiesta',
    ],
    inputs: {
      firstName: 'Nome',
      lastName: 'Cognome',
      email: 'Email',
      password: 'Password',
    },
    buttons: {
      signup: 'Continua',
    },
    errors: {
      fillFirstName: 'Il nome è obbligatorio',
      fillLastName: 'Il cognome è obbligatorio',
      fillEmail: 'L’email è obbligatoria',
      fillPassword: 'La password è obbligatoria',
      invalidFirstName: 'Il nome non è valido.',
      invalidLastName: 'Il cognome non è valido.',
      invalidEmail: "L'email non è valida.",
      invalidPasswordLength: 'La password deve avere tra 8 e 20 caratteri',
      invalidPassword:
        'La password deve contenere almeno un carattere minuscolo, un carattere maiuscolo e un numero.',
      verify: 'Per favore completa il Captcha.',
      verifyError: 'Si è verificato un errore durante la verifica del Captcha'
    },
    passwordCaption: 'Minimo 8 caratteri',
    successMessage: 'Congratulazioni! Il tuo account è stato creato con successo.',
    loginCaption: `Hai già un account?`,
  },
  verifyEmail: {
    title: 'Verify your email',
    subtitle: 'You will need to verify your email address to complete registration',
    emailSentTo:
      'An email has been sent to {{email}} with a link to verify your account. If you have not received the email after a few minutes, check your spam folder or try to log in to resend verification email',
    continueToLogin: 'Continue to login',
  },
  passwordResetForm: {
    title: 'Change password',
    inputs: {
      newPassword: 'New password',
      confirmNewPassword: 'Confirm new password',
    },
    buttons: {
      confirm: 'Confirm password',
      goToLogin: 'Go to login',
    },
    errors: {
      invalidLink: 'Invalid link. Request the password change again.',
      invalidPassword:
        'Password must have at least one lowercase character, one uppercase character and one number.',
      invalidPasswordLength: 'Password must be between 8 and 20 characters.',
      passwordMismatch: 'Passwords must match.',
    },
    successMessage: 'Your password was changed successfully!',
  },
  plans: {
    title: 'Subscriptions',
    base: 'Base',
    baseSubtitle: 'Access to all of the content',
    caption: 'The subscription renews automatically, it can be canceled at any time.',
    premium: 'Premium',
    premiumSubtitle: 'Access to all content + 4 live lessons to be held within the month',
    premiumIntensive: 'Premium intensive',
    premiumIntensiveSubtitle: 'Access to all content + 8 live lessons to be held within the month',
    zoomClasses: 'Private lessons via Zoom',
    zoomClassesSubtitle: 'Available in the app at any time',
    payments: 'Payments',
    paymentsSubtitle: 'Secure payments via ',
  },
  plan: {
    perMonth: 'per month',
    duration: 'Duration',
    lesson: 'lesson',
    periodicity: {
      month: 'month',
      months: 'months',
    },
    billedEvery: 'billed every',
    billedMonthly: 'Single payment each month',
    buttons: {
      select: 'Select',
      yourPlan: 'Your plan',
    },
  },
  faq: {
    title: 'Frequently asked questions',
  },
};
