import React from 'react';
import { Dialog, DialogActions } from '@material-ui/core';
import LevelSelector from './level-selector';
import { DialogTitle, SecondaryButton } from '../../../../components';
import I18n from '../../../../lang/i18n';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    margin: 20,
    marginTop: 0,
  },
  actions: {
    margin: 10,
  },
}));

const LevelSelectorDialog = ({
  open,
  selected,
  submitting,
  handleClose,
  handleChange,
  handleSelect,
}) => {
  const classes = useStyles();
  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle onClose={handleClose}>{I18n.t('levelSelectorDialog.title')}</DialogTitle>
      <div className={classes.container}>
        <LevelSelector selected={selected} onChange={handleChange} />
      </div>
      <DialogActions className={classes.actions}>
        {selected && (
          <SecondaryButton loading={submitting} onClick={handleSelect}>
            {I18n.t('levelSelectorDialog.confirm')}
          </SecondaryButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default LevelSelectorDialog;
