import { useMediaQuery, useTheme } from '@material-ui/core';
import { useEffect, useState } from 'react';

const useCustomSlider = () => {
  const theme = useTheme();

  const [slidesToShow, setSlidesToShow] = useState(2.99);
  const [centerMode, setCenterMode] = useState(true);
  const mediumScreen = useMediaQuery(theme.breakpoints.down(1350));
  const smallScreen = useMediaQuery(theme.breakpoints.down(1060));
  const mobileScreen = useMediaQuery(theme.breakpoints.down(900));

  useEffect(() => {
    if (mobileScreen) {
      setCenterMode(false);
      setSlidesToShow(1);
    } else if (smallScreen || mediumScreen) {
      setCenterMode(false);
      setSlidesToShow(2);
    } else {
      setCenterMode(true);
      setSlidesToShow(2.99);
    }
  }, [mediumScreen, smallScreen, mobileScreen]);

  const screenSize = mobileScreen
    ? 'mobile'
    : smallScreen
    ? 'small'
    : mediumScreen
    ? 'medium'
    : 'large';

  return {
    slidesToShow,
    centerMode,
    screenSize,
  };
};

export default useCustomSlider;
