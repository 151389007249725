import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { SecondaryButton, Subtitle, SurfacePage, Title } from '../../../../components';
import { useHistory } from 'react-router-dom';
import Timer from '../components/timer';
import { TopicResult } from '../components/topic';
import { getLetterScore } from '../../../../utils/utils';
import I18n from '../../../../lang/i18n';
import CardComplete from '../../../logged/home/components/card-complete';
import { useExerciseTimer } from '../../../../hooks';
import { useEffect } from 'react';


const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 20,
    marginTop: 20,
    fontWeight: 500,
  },
  value: {
    fontSize: 28,
    fontWeight: 500,
    color: theme.palette.accent.main,
    marginLeft: 10,
  },
  topicResults: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
    margin: '0 10px',
    flexWrap: 'wrap',
    padding: '40px 0',
  },
  feedbackTitle: {
    fontSize: 20,
    fontWeight: 500,
    paddingTop: 10,
  },
  feedbackSubtitle: {
    fontSize: 18,
  },
  feedbackTips: {
    fontSize: 18,
    paddingTop: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
}));

const TopicResults = ({results}) => {
  const classes = useStyles();

  return (
    <div className={classes.topicResults}>
      {Object.entries(results).filter(([k]) => k !== "overall").map(([type, score]) => (
        <TopicResult
          key={type}
          topic={I18n.t(`courseJourney.exercise.genericTypes.${type}`)}
          result={getLetterScore(score)}
        />
      ))}
    </div>
  );
}

const ReviewUnitResults = ({ result }) => {
  const classes = useStyles();
  const history = useHistory();
  const revisionScore = getLetterScore(result?.overall);
  const revisionScoreLangKey = `courseJourney.review.feedback.${
    result?.blocked ? `${revisionScore}.blocked` : revisionScore
  }`;
 
  const goToCourseJourney = () => history.push('/home/course_journey');
  const goToDashboard = () => history.push('/home/status');
  const [isCardOpen, setCardOpen] = useState(false);
  const url = window.location.href;
  const segments = url.split('/');
  const lastSegment = segments[segments.length - 1]; 
  const timer = useExerciseTimer();

  const handleOpenCard = () => { 
    if(lastSegment === '623e65b0d6c8bb6948795df5'){
      revisionScore === 'C' || revisionScore === 'B' || revisionScore === 'A'? setCardOpen(true) : goToCourseJourney();
    }else{
      goToCourseJourney();
    }
  };

  useEffect(() => {
    timer.reload();
  }, []);

  return (
    <SurfacePage>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Title withBackArrow>{I18n.t('courseJourney.review.title')}</Title>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {timer && <Timer hours={timer.hours} minutes={timer.minutes} seconds={timer.seconds} />}
      </div>
      <Title  className={classes.text}>
        {I18n.t('courseJourney.review.revisionScore')}:
        <span className={classes.value}>{revisionScore}</span>
      </Title>
      <TopicResults results={result} />
      <Title className={classes.feedbackTitle}>{I18n.t(`${revisionScoreLangKey}.title`)}</Title>
      <Subtitle className={classes.feedbackSubtitle}>
        {I18n.t(`${revisionScoreLangKey}.subtitle`)}
      </Subtitle>
      <Typography className={classes.feedbackTips}>
        {I18n.t('courseJourney.review.feedback.tips')}:
      </Typography>
      <ul style={{ lineHeight: 1.8 }}>
        {I18n.t(`${revisionScoreLangKey}.tips`).map((tip, index) => (
          <li key={index}>{tip}</li>
        ))}
      </ul>
      <div className={classes.buttonContainer}>
        <SecondaryButton onClick={handleOpenCard}>
          {I18n.t('courseJourney.review.done')}
        </SecondaryButton>
      </div>
      <CardComplete
        img="photo-card-congratulations.webp"
        handleClick={goToDashboard}
        open={isCardOpen}
      />
    </SurfacePage>
  );
};

export default ReviewUnitResults;
