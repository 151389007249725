import React from 'react';
import { InputAdornment, makeStyles, TextField, useTheme } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  inputRoot: {
    borderRadius: 4,
  },
  notchedOutline: {
    borderColor: theme.palette.accent.main,
  },
  input: {
    textAlign: 'center',
    fontWeight: 600,
    padding: '6px 0',
    marginTop: 3,
  },
  inputEllipsis: {
    textAlign: 'center',
    fontWeight: 600,
    padding: '6px 0',
    marginTop: 3,
    textOverflow: 'ellipsis',
  },
  inputCorrect: {
    borderColor: theme.palette.state.success,
  },
  inputIncorrect: {
    borderColor: theme.palette.state.failure,
  },
}));

const TextInput = ({
  name,
  value,
  placeholder,
  isCorrect = null,
  handleChange,
  maxWidth = 'none',
  type,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  return isCorrect !== null ? (
    <TextField
      variant="outlined"
      color="primary"
      placeholder={placeholder}
      name={name}
      value={value}
      margin="dense"
      style={{
        maxWidth,
        width: (value.length + 1) * 13,
        minWidth: 160,
        margin: '0 8px',
        pointerEvents: 'none',
      }}
      InputProps={{
        readOnly: true,
        classes: {
          root: classes.inputRoot,
          notchedOutline: isCorrect ? classes.inputCorrect : classes.inputIncorrect,
          input: maxWidth !== 'none' ? classes.inputEllipsis : classes.input,
        },
        endAdornment: (
          <InputAdornment position="end">
            {isCorrect ? (
              <Check style={{ color: theme.palette.state.success }} />
            ) : (
              <Close style={{ color: theme.palette.state.failure }} />
            )}
          </InputAdornment>
        ),
      }}
    />
  ) : (
    <TextField
      variant="outlined"
      color="primary"
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={handleChange}
      margin="dense"
      style={{
        width: (value?.length + 1) * 13,
        maxWidth,
        minWidth: 160,
        margin: '0 8px',
        whiteSpace: 'nowrap',
      }}
      InputProps={{
        spellCheck: 'false',
        autoComplete: 'off',
        classes: {
          root: classes.inputRoot,
          notchedOutline: classes.notchedOutline,
          input: maxWidth !== 'none' ? classes.inputEllipsis : classes.input,
        },
      }}
      type={type}
    />
  );
};

export default TextInput;
