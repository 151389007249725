import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SurfacePage } from '../../../components';
import I18n from '../../../lang/i18n';

const useStyles = makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 80,
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 80,
  },
  title: {
    fontSize: '3.5rem',
    color: 'primary',
    fontWeight: 600,
  },
  text: {
    fontSize: '1.30rem',
    maxWidth: 700,
    fontWeight: 450,
    textAlign: 'center',
  },
  link: {
    color: theme.palette.accent.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },
}));

const TeacherHome = () => {
  const classes = useStyles();
  const history = useHistory();
  const goToCalendar = () => history.push('/home/book_lesson');

  return (
    <SurfacePage maxWidth={1210}>
      <div className={classes.titleContainer}>
        <Typography color={'primary'} component="span" className={classes.title}>
          {I18n.t('home.teacher.welcome')}
        </Typography>
      </div>
      <div className={classes.textContainer}>
        <p className={classes.text}>
          {I18n.t('home.teacher.navigateTo')}{' '}
          <span onClick={goToCalendar} className={classes.link}>
            {I18n.t('home.teacher.calendar')}
          </span>
          {I18n.t('home.teacher.upcomingLessons')}
        </p>
      </div>
    </SurfacePage>
  );
};

export default TeacherHome;
