import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Slider from 'react-slick';
import { Loading, SecondaryButton, SurfacePage, Title, TitleButton } from '../../../components';
import UnitCard from './components/unit-card';
import { get } from '../../../utils/network';
import { useCustomSlider } from '../../../hooks';
import I18n from '../../../lang/i18n';
import { getCookie } from '../../../utils/utils';
import { useHistory } from 'react-router-dom';
import { SLIDER_SETTINGS } from './utils';

const useStyles = makeStyles(theme => ({
  sliderContainer: {
    margin: '24px auto',
    height: 450,
    borderRadius: 10,
    backgroundColor: 'rgba(240, 242, 244, 0.5)',
  },
}));

const CourseJourney = () => {
  const classes = useStyles();
  const sliderRef = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [units, setUnits] = useState([]);
  const [activeUnit, setActiveUnit] = useState(0);
  const { slidesToShow, centerMode, screenSize } = useCustomSlider();
  const getSliderWidth = () => {
    if (screenSize === 'mobile') return 300;
    if (screenSize === 'small') return 680;
    if (screenSize === 'medium') return 800;
    return 1100;
  };

  const moveToActiveUnit = () => sliderRef.current?.innerSlider?.changeSlide({ message: 'index', index: activeUnit}) // This could change

  const goToCourseMap = () => history.push('/home/course_map');

  useEffect(() => {
    get(`courses/${getCookie('courseId')}/mine`)
      .then(res => {
        const unitsData = res.message.data.units;
        get(`users/${getCookie('userId')}/active_unit`).then(res => {
          const activeUnit = res.message.data;
          const index = unitsData.findIndex(unit => unit._id === activeUnit._id);
          setActiveUnit(index)
          setUnits(unitsData);
        });
      })
      .finally(() => setLoading(false));
  }, []);

  const lockCompleteMessage = units.length > 0 ? units[units.length - 1].progress !== 100 : true;

  return (
    <SurfacePage maxWidth={1210}>
      <Title withBackArrow>{I18n.t('courseJourney.title')}</Title>
      <TitleButton startIcon={<img src="icons-map.svg" alt="map-icon" />} onClick={goToCourseMap}>
        {I18n.t('courseJourney.goToCourseMap')}
      </TitleButton>
      <div className={classes.sliderContainer} style={{ width: getSliderWidth() }}>
        {loading ? (
          <Loading style={{ height: '100%' }} />
        ) : (
          units.length > 0 && (
            <Slider
              {...SLIDER_SETTINGS}
              slidesToShow={slidesToShow}
              centerMode={centerMode}
              ref={sliderRef}
              initialSlide={activeUnit}
              arrows={screenSize !== 'mobile'}
            >
              {units.map((unit, index) => (
                <div key={unit._id}>
                  <UnitCard
                    id={unit._id}
                    blocked={unit?.blocked}
                    unitHeader={unit.header}
                    description={unit.name}
                    photoUrl={unit.photoUrl}
                    type={unit.type}
                    progress={(unit?.progress || 0) * 100}
                  />
                </div>
              ))}
              {
                units.length > 0 && (
                  <UnitCard
                    endOfCourse={true}
                    unitHeader={lockCompleteMessage ? "Welcome!" : "Congratulations!"}
                    description={lockCompleteMessage ? "Welcome to Testbridge" : "You've completed this course"}
                    progress={lockCompleteMessage ? 0 : 100}
                    blocked={lockCompleteMessage}
                  />
                )
              }
            </Slider>
          )
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <SecondaryButton style={{ borderRadius: 50 }} onClick={moveToActiveUnit} disabled={loading}>
          {I18n.t('courseJourney.activeUnit')}
        </SecondaryButton>
      </div>
    </SurfacePage>
  );
};

export default CourseJourney;
