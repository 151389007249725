import React from 'react';
import { makeStyles } from '@material-ui/core';
import { SecondaryButton } from './button';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      marginTop: 15,
    },
  },
}));

const TitleButton = ({ startIcon, onClick, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <div />
      <SecondaryButton onClick={onClick} startIcon={startIcon}>
        {children}
      </SecondaryButton>
    </div>
  );
};

export default TitleButton;
