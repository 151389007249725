import React from 'react';
import { makeStyles } from '@material-ui/core';
import Exercise from '../exercise';
import { Question } from '../../components/question';
import QuestionPlaceholder from '../../components/placeholders/question-placeholder';
import { useExercise } from '../../../../../hooks';

const useStyles = makeStyles(theme => ({
  questionList: {
    flex: 1,
    borderLeft: `1px solid ${theme.palette.grey.light}`,
    paddingLeft: 20,
    maxHeight: '50vh',
    overflow: 'auto',
    paddingTop: 15,
  },
}));

const formatAnswersResponse = content => {
  return content.map(c => ({
    questionId: c._id,
    answer: '',
  }));
};

const ReadingQuestion = ({ data, reviewUnitId, reviewUnitToken, onSuccessSubmit, handleChange, handleEndReview }) => {
  const classes = useStyles();
  const reviewUnit = {
    exercise: data,
    reviewUnitId,
    reviewUnitToken,
  };
  const handleSuccessSubmit = (statusCode, data) => {
    if (onSuccessSubmit) onSuccessSubmit(statusCode, data);
  };
  const {
    loading,
    submitting,
    exercise,
    answers,
    result,
    handleChangeAnswer,
    handleClean,
    handleSubmit,
    timer,
  } = useExercise({ formatAnswersResponse, onSuccessSubmit: handleSuccessSubmit, reviewUnit, });

  const renderQuestions = exercise => (
    <div className={classes.questionList}>
      {loading ? (
        <QuestionPlaceholder amount={2} />
      ) : (
        <div>
          {exercise?.content.map((question, index) => {
            const answer = result?.answers?.find(a => a.questionId === question._id);
            return (
              <Question
                key={question._id}
                index={index + 1}
                selected={answers?.find(a => a.questionId === question._id)?.answer}
                question={question.question}
                options={question.options}
                handleCheck={handleChangeAnswer(question._id)}
                isCorrect={answer?.correct}
                explanation={question.explanation}
                correctOption={answer?.correctAnswer}
              />
            );
          })}
        </div>
      )}
    </div>
  );

  return (
    <Exercise
      exercise={exercise}
      loading={loading}
      submitting={submitting}
      finalResult={result}
      handleSubmit={handleSubmit}
      handleRetry={handleClean}
      handleChange={handleChange}
      handleEndReview = {handleEndReview}
      renderRight={renderQuestions}
      timer={timer}
      isReviewUnit={!!reviewUnitId}
    />
  );
};

export default ReadingQuestion;
