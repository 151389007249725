import I18n from 'i18n-js';

import es from './locales/es';
import en from './locales/en';

I18n.fallbacks = true;
I18n.translations = {
  es,
  en,
};
I18n.locale = 'en' || navigator.language.split('-')[0];

export default I18n;
