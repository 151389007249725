export default {
  global: {
    serverError:
      'Parece que el servidor no está funcionando actualmente. Por favor intente nuevamente más tarde.',
    retry: 'Reintentar',
    permissionDenied: 'Parece que no tiene permisos para acceder a esta vista.',
    loading: 'Obteniendo información...',
    minutes: 'minutos',
    hours: 'horas',
    unsubscribedDialog: {
      unsubscribed: 'Para utilizar esta función, necesitas estar suscrito a un plan',
      lessonAfterCancelled: 'su lección está programada para una fecha posterior a la cancelación de su cuenta. Seleccione una fecha alternativa o considere renovar su suscripción',
      goToBookLessons: 'Seleccionar otra fecha',
      goToPlans: 'Seleccionar un plan',
      goToHome: 'Ahora no',
    },
  },
  datePicker: {
    cancel: 'Cancelar',
    ok: 'Confirmar',
    invalidDateMessage: 'Formato de fecha inválido',
    minDateMessage: 'Fecha inválida',
    maxDateMessage: 'Fecha inválida',
  },
  select: {
    placeholder: 'Seleccione',
  },
  questionCheck: {
    question: 'Pregunta',
    choose: 'seleccionar esta pregunta',
  },
  textAreaAnswer: {
    notSelected: 'No seleccionaste ninguna pregunta.',
    wordCount: 'Cantidad de palabras',
  },
  login: {
    title: 'Iniciar sesión',
    inputs: {
      email: 'Email',
      password: 'Contraseña',
    },
    buttons: {
      forgotPassword: '¿Olvidaste tu contraseña?',
      login: 'Iniciar sesión',
      signup: 'Registrarme',
    },
    errors: {
      fillEmail: 'Complete el email.',
      fillPassword: 'Complete la contrasña.',
      wrongEmailOrPassword: 'Email o contraseña incorrectos.',
      details: 'Detalles',
      validateAccount: 'Hemos enviado un correo para que valides tu cuenta.',
    },
    signupCaption: '¿No tenés una cuenta?',
    loading: 'Iniciando sesión...',
    cookiesBanner: "<b>Informativa</b><br/>Noi e terze parti selezionate utilizziamo esclusivamente cookie o tecnologie simili per finalità tecniche, come specificato nella <a {}>Cookie Policy</a>. <br/>Trattandosi di soli cookie tecnici, il rifiuto del consenso non consentirebbe la normale navigazione e fruizione del sito web e degli applicativi in esso contenuti.",
    gotIt: "Accetta"
  },
  passwordReset: {
    dialog: {
      title: 'Recuperar contraseña',
      emailCaption: 'Ingrese su correo electrónico:',
      email: 'Email',
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Confirmar',
      },
      errors: {
        invalidEmail: 'Ingrese un email válido.',
        emailNotRegistered: 'El email ingresado no se encuentra registrado en el sistema.',
      },
      success: 'Te enviamos un mail para que puedas recuperar tu contraseña.',
    },
  },
  drawer: {
    home: 'Inicio',
    courseJourney: 'Recorrido del curso',
    extraPractice: 'Practica extra',
    bookLesson: 'Reservar una lección',
    courseMap: 'Mapa del curso',
    statistics: 'Estadísticas',
    profile: 'Mi perfil',
    logout: 'Cerrar sesión',
  },
  courseJourney: {
    title: 'Recorrido del curso',
    goToCourseMap: 'Ir al mapa del curso',
    activeUnit: 'Unidad actual',
    viewUnit: 'Ver unidad',
    completeUnit: 'Completar unidad',
    unit: {
      bookLesson: 'Reservar una lección',
      lessons: 'Lecciones',
    },
    exercise: {
      next: 'siguiente',
      submit: 'Enviar',
      retry: 'Reintentar',
      correctAnswers: 'respuestas correctas.',
      score: 'puntos.',
      answersCaption: {
        A: '¡Excelente trabajo! ¡Seguí así!',
        B: '¡Bien hecho! ¡Quizás puedas hacerlo aún mejor! ',
        C: 'Has alcanzado el puntaje mínimo para aprobar. Esforzate un poco más y mejor{a tu puntuación.',
        D: 'Estás justo por debajo del requisito mínimo. Intentar otra vez.',
        E: 'Desafortunadamente no habrías aprobado. Intentalo nuevamente',
      },
      listeningLimit:
        'No es posible volver a reproducir el contenido porque alcanzaste el límite de reproducciones permitidas.',
      playerDisabled: `Debe seleccionar 'Reintentar' para poder volver a reproducir el contenido.`,
      keywordList: 'Lista de palabras clave',
      genericTypes: {
        // TODO: traduce
        reading: 'Reading',
        useOfEnglish: 'Use of English',
        listening: 'Listening',
        writing: 'Writing',
        overallScore: 'Overall Score',
      },
      feedback: {
        gotIt: '¡Entendido!',
        tutorial: 'Puedes pasar el cursor sobre tus respuestas para ver la realimentación',
        checkbox: 'No mostrar de nuevo',
        writing: {
          loading: 'Evaluando el ejercicio, por favor espere unos segundos...',
          feedbackTitle: 'Aquí está tu revisión del ejercicio de writing:',
        },
      },
    },
    placement: {
      title: 'Prueba de nivel',
      subtitle: 'Por favor responda las siguientes preguntas',
      answeredQuestions: 'Preguntas respondidas',
      startQuestion: '¿Qué examen desea certificar?',
      suggestedCourse: 'Tu curso sugerido es',
      suggestedCertify: 'Estarás preparado para certificar',
      enrollCaption: '¿Prefiere inscribirse en otro curso?',
      enrollNow: 'Inscribirme ahora',
      enrollOtherCourse: 'Seleccionar otro curso',
      next: 'Siguiente',
      start: 'Comenzar',
    },
    review: {
      // TODO: traduce
      title: 'Revisión',
      revisionScore: 'Revision score',
      start: {
        welcome: 'Bienvenido a la',
        message1: 'Aproveche la oportunidad para evaluar su comprensión de las unidades',
        message2: `poniéndose a prueba con el material que ha aprendido.`,
        messageAnd: 'y',
        explanation: `Esta revisión incluye seis ejercicios, diseñados para ser completados en alrededor de 10 minutos cada uno. Su progreso se guarda automáticamente después de enviar cada ejercicio, por lo que puede continuar desde donde lo dejó en la próxima sesión.`,
        next: 'Siguiente',
        start: 'Comenzar',
        continue: 'Continuar'

      },
      feedback: {
        tips: 'TIPS',
        A: {
          title: 'Fantastic! You would have passed with flying colours.',
          subtitle: 'You can definitely move forward.',
          tips: [
            "Don't forget to revise every now and then.",
            'Do ”Extra Practice” to maintain or improve your Overall score.',
            'Book a lesson with an online teacher.',
          ],
        },
        B: {
          title: 'Well done! You have a good grasp of the material in these units.',
          subtitle: 'Feel free to move forward.',
          tips: [
            'Remember it is always wise to revise.',
            'Try the test again or do “Extra Practice” to improve your Overall score.',
            'Book a lesson with an online teacher.',
          ],
        },
        C: {
          title: 'Good! You have reached the minimum requirement to pass.',
          subtitle: 'You can move forward.',
          tips: [
            'Revise to gain more confidence and try to improve your score',
            'Try the test again or do “Extra Practice” to improve your Overall score',
            'Book a lesson with an online teacher',
          ],
        },
        D: {
          title:
            'You haven’t reached the minimum requirement to pass this revision. However, your Overall score is good enough to unblock your journey. ',
          subtitle: 'Continue revising to achieve a higher score.',
          tips: [
            'Go over the units. Watch the videos and do the exercises',
            'Do “Extra Practice” to improve your Overall score and try the test again',
            'Book a lesson with an online teacher',
          ],
          blocked: {
            title: 'Your journey is blocked but you are close to passing.',
            subtitle: 'Revise and try to achieve a higher score.',
            tips: [
              'Go over the units. Watch the videos and do the exercises',
              'Do “Extra Practice” to improve your Overall score and try the test again',
              'Book a lesson with an online teacher',
            ],
          },
        },
        E: {
          title: "Unfortunately you haven't reached the minimum requirement to pass.",
          subtitle: 'You must improve your Revision score to unblock the journey.',
          tips: [
            'Go over the units. Watch the videos and do the exercises',
            'Do “Extra Practice” to improve your Overall score and try the test again',
            'Book a lesson with an online teacher',
          ],
        },
      },
      done: 'Done',
    },
  },
  bookLesson: {
    title: 'Reservar una lección',
    subtitle: 'Por favor seleccione la fecha',
    search: 'Buscar',
    lessonDate: 'Fecha de la lección',
    startsAt: 'Comienza',
    endsAt: 'Finaliza',
    book: 'Reservar',
    unusedLessonsTime: 'Tiempo de suscripción disponible',
    messages: {
      minDate: 'La reserva de lecciones debe hacerse con al menos 24 horas de anticipación.',
      success: 'La lección ha sido reservada correctamente!',
      error: 'La lección seleccionada no se encuentra disponible.',
      noLessons: `No hay lecciones disponibles para la fecha seleccionada. \n Por favor, pruebe con otra fecha.`,
      successWithSubscription:
        'La lección ha sido reservada correctamente utilizando el tiempo disponible de su suscripción!',
      successRescheduled: 'La fecha de su lecicón ha sido cambiada correctamente!',
    },
    payment: {
      productName: 'Nombre del producto',
      price: 'Precio',
      success: {
        title: 'Compra exitosa',
        subtitle: 'Compraste correctamente',
        caption: 'En breve recibirás un mail con la información completa de la compra.',
        done: 'Continuar',
      },
      failure: {
        title: 'Error',
        subtitle: 'Su compra no fue confirmada',
        goHome: 'Ir al inicio',
      },
    },
    form: {
      unit: 'Unidad',
      topic: 'Tópico',
      comments: 'Comentarios',
      unitQuestion: '¿En qué unidad le gustaría enfocarse en esta lección?',
      topicQuestion: '¿En qué habilidad le gustaría enfocarse en esta lección?',
      topics: {
        lessonReview: 'Revisión de lección',
        practiceSpeaking: 'Practica del habla',
        unitExercisesCorrection: 'Correción de ejercicios de la unidad',
        examStrategy: 'Estrategia de examen',
      },
    },
  },
  bookedLessons: {
    title: 'Mis lecciones online',
    booked: 'Reservadas',
    attended: 'Asistidas',
    buttons: {
      bookLesson: 'Reservar una lección',
      attend: 'Asistir',
      attended: 'Asistida',
      reschedule: 'Cambiar fecha',
      expires: 'Esta reserva expira el',
      pay: 'Continuar con el pago',
    },
    noLessons: {
      title: 'Aún no tiene lecciones reservadas.',
      bookLesson: 'Reserva una lección',
      text: 'con nuestros profesores para mejorar tu nivel de inglés o volver a tu Unidad actual.',
    },
    alerts: {
      expired: {
        title: 'La lección ha expirado',
        text: 'Por favor, vuelva a reservar una lección.',
        confirm: 'Ok',
      },
    },
  },
  levelSelectorDialog: {
    title: 'Seleccione el nivel con el que desea continuar',
    confirm: 'Confirmar',
  },
  home: {
    title: 'Inicio',
    student: {
      cards: {
        profile: 'Mi perfil',
        course: 'Mi curso',
        placementTest: 'Prueba de nivel',
      },
      afterReading: 'Después de leer nuestras',
      faq: 'preguntas frecuentes',
      callTechnical: ', para obtener asistencia técnica puede enviarnos un mail a ',
    },
    teacher: {
      welcome: 'Bienvenido/a a Testbridge',
      navigateTo: 'Como una parte integral de nuestra comunidad de enseñanza, tu contribución es muy valorada. Por favor, navega a tu ',
      calendar: 'Calendario',
      upcomingLessons: ' personalizado para ver tus próximas lecciones.',
    },
  },
  profile: {
    title: 'Mi Perfil',
    inputs: {
      firstName: 'Nombre',
      lastName: 'Apellido',
      email: 'Email',
      course: 'Curso',
    },
    errors: {
      fillFirstName: 'Complete su nombre.',
      fillLastName: 'Complete su apellido.',
      fillEmail: 'Complete su email.',
      invalidFirstName: 'El nombre debe ser un nombre válido.',
      invalidLastName: 'El apellido debe ser un apellido válido.',
      invalidEmail: 'El email debe ser un mail válido.',
    },
    buttons: {
      passwordReset: 'Cambiar mi contraseña',
      cancel: 'Cancelar',
      edit: 'Editar',
      save: 'Guardar',
    },
    messages: {
      passwordReset: 'Te enviamos un mail para que puedas cambiar tu contraseña.',
      updated: 'Su perfil ha sido actualizado correctamente!',
    },
    subscription: {
      title: 'Suscripción activa',
      noSubscriptionTitle: 'No tenes suscripción activa',
      noSubscriptionDescription: 'Aún no tiene ninguna suscripción a Testbridge.',
      billed: 'facturada',
      monthly: 'mensualmente',
      annually: 'anualmente',
      startDatetime: 'La suscripción comenzó el',
      lastPaymentDatetime: 'La fecha del último pago recibido fue',
      usedLessonsTime: 'Has utilizado',
      includedLessonsTime: 'del total de',
      nextBillingDatetime: 'disponibles para lecciones de tu plan. Este tiempo será renovado el',
      cancelledAtDatetime: 'La suscripción fue cancelada el',
      cancelAtDatetime: 'Será removida el',
      freeTrial: {
        title: 'Prueba gratuita',
        end: 'La prueba gratuita finalizará el',
      },
      school: {
        title: 'Plan escolar',
        startDatetime: 'Tu suscripción comenzó el',
        cancelAtDatetime: 'Será removida el',
        renewSubscription: 'Por favor, contacta con tu escuela para renovarla.',
      },
      buttons: {
        cancel: 'Cancelar suscripción',
        change: 'Cambiar suscripción',
        reactivate: 'Reactivar suscripción',
        subscribeNow: 'Suscribirse ahora',
      },
      cancelledMessage: 'Su suscripción fue cancelada.',
      reactivatedMessage: 'Su suscripción fue reactivada.',
      cancelDialog: {
        title: '¿Está seguro/a de que sea cancelar su suscripción?',
        text: 'Aún podrá usar su plan y habilitarlo nuevamente antes del próximo período de facturación. Después de ese momento, se eliminará permanentemente y no se le cobrará hasta que se suscriba nuevamente.',
        cancel: 'Cancelar suscripción',
        keep: 'Mantener suscripción',
      },

      lastPaymentUnsuccessful: 'El último pago falló',
      lastPaymentUnsuccessfulDescription:
        'No podrás actualizar la suscripción hasta que pagues la última factura.',
      lastPaymentUnsuccessfulButton: 'Pagar',
    },
  },
  statistics: {
    title: 'Tus estadísticas',
    subtitle: 'Puntuación de su curso',
    text: 'Basada en ejercicios de repaso y práctica adicional',
  },
  courseMap: {
    title: 'Mapa del curso',
    bookLesson: 'Reservar una lección',
    unit: 'Unidad',
    topics: 'Tópicos',
    grammar: 'Gramática',
    vocabulary: 'Vocabulario',
  },
  signup: {
    title: '¡Prueba gratis!',
    checkbox: {
      linkPath: 'es',
      accept: 'He leido y acepto ', 
      termsAndConditions: 'los terminos y condiciones',
      privacyPolicy: 'la política de privacidad'
    },
    captions: [
      'Accesso libero per 15 giorni',
      'Prime 2 unità e Revision 1',
      'Carta di credito non richiesta',
    ],
    inputs: {
      firstName: 'Nome',
      lastName: 'Cognome',
      email: 'Email',
      password: 'Password',
    },
    buttons: {
      signup: 'Continua',
    },
    errors: {
      fillFirstName: 'Il nome è obbligatorio',
      fillLastName: 'Il cognome è obbligatorio',
      fillEmail: 'L’email è obbligatoria',
      fillPassword: 'La password è obbligatoria',
      invalidFirstName: 'Il nome non è valido.',
      invalidLastName: 'Il cognome non è valido.',
      invalidEmail: "L'email non è valida.",
      invalidPasswordLength: 'La password deve avere tra 8 e 20 caratteri',
      invalidPassword:
        'La password deve contenere almeno un carattere minuscolo, un carattere maiuscolo e un numero.',
      verify: 'Per favore completa il Captcha.',
      verifyError: 'Si è verificato un errore durante la verifica del Captcha'
    },
    passwordCaption: 'Minimo 8 caratteri',
    successMessage: 'Congratulazioni! Il tuo account è stato creato con successo.',
    loginCaption: `Hai già un account?`,
  },
  verifyEmail: {
    title: 'Verifica tu email',
    subtitle: 'Necesitamos confirmar tu email para poder continuar.',
    emailSentTo:
      'Un email fue enviado a {{email}} con un link para confirmar tu cuenta. Si no recibes el email en unos minutos, revisa la carpeta de spam, o intenta iniciar sesión para validar tu cuenta.',
    resendEmail: 'Reenviar email',
    continueToLogin: 'Iniciar Sesión',
    emailResent: 'El email fue reenviado',
  },
  passwordResetForm: {
    title: 'Recuperar contraseña',
    inputs: {
      newPassword: 'Contraseña nueva',
      confirmNewPassword: 'Confirmar contraseña nueva',
    },
    buttons: {
      confirm: 'Confirmar contraseña',
      goToLogin: 'Volver al login',
    },
    errors: {
      invalidLink: 'Enlace invalido. Solicite el cambio de contraseña nuevamente.',
      invalidPassword:
        'La contraseña debe tener al menos un caracter en minúscula, uno en mayúscula y un número.',
      invalidPasswordLength: 'La contraseña debe tener entre 8 y 20 caracteres.',
      passwordMismatch: 'Las contraseñas deben coincidir.',
    },
    successMessage: '¡Su contraseña ha sido cambiada correctamente!',
  },
  plans: {
    title: 'Suscripciones',
    base: 'Base',
    baseSubtitle: 'Acceso a todo el contenido',
    caption: 'La suscripción se renueva automáticamente, se puede cancelar en cualquier momento.',
    premium: 'Premium',
    premiumSubtitle:
      'Acceso a todo el contenido + 4 lecciones en vivo que se llevarán a cabo dentro del mes',
    premiumIntensive: 'Premium intensive',
    premiumIntensiveSubtitle:
      'Acceso a todo el contenido + 8 lecciones en vivo que se llevarán a cabo dentro del mes',
    zoomClasses: 'Clases privadas a través de Zoom',
    zoomClassesSubtitle: 'Disponible en la aplicación en cualquier momento',
    payments: 'Pagos',
    paymentsSubtitle: 'Pagos seguros a través de ',
  },
  plan: {
    perMonth: 'por mes',
    duration: 'Duración',
    lesson: 'lección',
    periodicity: {
      month: 'mes',
      months: 'meses',
    },
    billedEvery: 'facturados cada',
    billedMonthly: 'Pago único cada mes',
    buttons: {
      select: 'Seleccionar',
      yourPlan: 'Tu plan',
    },
  },
  faq: {
    title: 'Preguntas frecuentes',
  },
};
