import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { SecondaryButton, Subtitle, SurfacePage, Title } from '../../../../components';
import I18n from '../../../../lang/i18n';
import { useCallback } from 'react';
import { useState } from 'react';
import { get } from '../../../../utils/network';
import { useEffect } from 'react';
import {getUnitReviewInLocalStorage} from '../../../../utils/utils';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  subtitle: {
    fontSize: 20,
    marginTop: 14,
    fontWeight: 500,
  },
  subtitle2: {
    fontSize: 16,
    marginTop: 14,
    fontWeight: 500,
  },

  levels: {
    marginTop: 20,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    gap: 10,
  },
}));

const ReviewUnitStart = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [unitReview, setUnitReview] = useState({});
  const [loading, setLoading] = useState({});
  const [isUniReviewInLS, setIsUniReviewInLS] = useState(false);
  const getUnitReview = useCallback(() => {
    return get(`units/${id}`)
      .then(res => setUnitReview(res.message.data))
      .finally(() => setLoading(false));
  }, [id]);
  
  useEffect(() => {
    getUnitReview();
    setIsUniReviewInLS(getUnitReviewInLocalStorage(id) !== null)
  }, [id, getUnitReview]);

  const getFirstReviewedUnits = header => header ? (header.split(' ')[1]-1) * 2 + 1 : ''

  const handleStart = () => history.replace(`/home/course_journey/reviewUnits/${id}`);
  
  return (
    <SurfacePage>
      <div className={classes.title}>
      <Title withBackArrow>{I18n.t('courseJourney.review.title')}</Title>
      </div>
      {!loading ? (
        <div>
          <Title className={classes.subtitle}>
            {I18n.t('courseJourney.review.start.welcome').concat(' ').concat(unitReview.header)}
          </Title>
        </div>
      ) : 
        <div>
          <Skeleton variant="text" width='20%' height={30} />
          <Skeleton variant="text" width='70%' height={30} />
          <Skeleton variant="text" width='100%' height={60} />
        </div>
      }
      { !loading && (
        <div>
          <Subtitle className={classes.subtitle2}>
            {I18n.t('courseJourney.review.start.message1')+ ' ' + (getFirstReviewedUnits(unitReview.header)) + ' ' + I18n.t('courseJourney.review.start.messageAnd') + ' ' + (getFirstReviewedUnits(unitReview.header) + 1) + ' ' + I18n.t('courseJourney.review.start.message2')}
          </Subtitle>
        </div>
        )
      }

      { !loading && (
        <div>
          <p>
            {I18n.t('courseJourney.review.start.explanation')}
          </p>
        </div>
        )
      }

      <div className={classes.buttonContainer}>
        {(
          <SecondaryButton onClick={handleStart}>
            {isUniReviewInLS? (I18n.t('courseJourney.review.start.continue')) : (I18n.t('courseJourney.review.start.start'))}
          </SecondaryButton>
        )}
      </div>
    </SurfacePage>
  );
};

export default ReviewUnitStart;
