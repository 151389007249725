import { makeStyles } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';
import React, { useEffect, useState } from 'react';
import config from '../../../utils/config';
import I18n from '../../../lang/i18n';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    width: '100%',
    position: 'fixed',
    bottom: '0',
    left: '0',
    height: '90px',
    backgroundColor: '#f7f7f7',
    color: '#171717',
    padding: '10px',
    textAlign: 'center',
    zIndex: '9999',
    display: 'flex', 
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  
  button: {
    //Button
    width: '27vh',
    height: '4.5vh',
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '3px',
    border: 'none',
    cursor: 'pointer',
    marginLeft: '3%',
    border: '1px solid #454545',

    //Label
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    letterSpacing: '-0.2px',
    color: 'black',

    '&:hover': {
      backgroundColor: darken('#0000', 1),
    },
  },
  buttonContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20 },
  closeBanner: {
    marginLeft: '3%',
    cursor: 'pointer',
    height: '15px',
    width: '15px',
  },
  text: {
    textAlign: 'left',
    width: '75%',
  }
  
}));

const CookieBanner = ({ handleClose }) => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(true); 


  const handleCloseBanner = () => {
    setIsVisible(false); 
    sessionStorage.setItem('cookieBannerClosed', 'true');
  };

  useEffect(() => {
    const isBannerClosed = sessionStorage.getItem('cookieBannerClosed');
    if (isBannerClosed === 'true') {
      setIsVisible(false);
    }
  }, []);

  return isVisible ? (
    <div className={classes.cardContainer}>
      <p className={classes.text} dangerouslySetInnerHTML={{__html: I18n.t("login.cookiesBanner", { 'interpolation': {'escapeValue': false} }).replace("{}", `href="${config.cookiesUrl}" target="_blank"`)}}></p>
      <div className={classes.buttonContainer}>
        <button className={classes.button} onClick={handleCloseBanner}>
          {I18n.t("login.gotIt")}
        </button>
        <img src={"icon-close.png"} alt="icon-close" className={classes.closeBanner} onClick={handleCloseBanner} />
      </div>
    </div>
  ) : null; 
};

export default CookieBanner; 
