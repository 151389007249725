import { Skeleton } from '@material-ui/lab';
import React from 'react';

const PlanCardPlaceholder = ({ amount = 1 }) => {
  return [...Array(amount)].map((_, i) => (
    <Skeleton
      key={i}
      variant="rect"
      height={280}
      width={222}
      style={{ borderRadius: 4, margin: '10px 0' }}
    />
  ));
};

export { PlanCardPlaceholder };
