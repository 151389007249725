import React from 'react';
import { Dialog, DialogActions, DialogContent, makeStyles } from '@material-ui/core';
import { DialogTitle, SmallButton } from '../../../../components';
import I18n from '../../../../lang/i18n';

const useStyles = makeStyles(theme => ({
  content: {
    padding: '14px 20px',
    fontSize: 16,
  },
  actions: {
    margin: 10,
  },
}));

const CancelSubscriptionDialog = ({ handleClose, handleConfirm, loading, open }) => {
  const classes = useStyles();
  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle onClose={handleClose}>
        {I18n.t('profile.subscription.cancelDialog.title')}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {I18n.t('profile.subscription.cancelDialog.text')}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <SmallButton variant="tertiary" onClick={handleClose}>
          {I18n.t('profile.subscription.cancelDialog.keep')}
        </SmallButton>
        <SmallButton loading={loading} onClick={handleConfirm}>
          {I18n.t('profile.subscription.cancelDialog.cancel')}
        </SmallButton>
      </DialogActions>
    </Dialog>
  );
};

export default CancelSubscriptionDialog;
