export default theme => ({
  background: {
    backgroundColor: '#efefef',
    [theme.breakpoints.down(516)]: {
      backgroundColor: 'white',
    },
  },
  gridLogin: {
    minHeight: '92vh',
  },
  title: {
    textAlign: 'left',
    marginTop: "48px",
    letterSpacing: "-0.95px",
    color: "##22282f"
    
  },
  titleContainer: {
    width: 495,
    [theme.breakpoints.down(516)]: {
      width: 'auto',
    },
  },
  formLogin: {
    marginTop: '2.5%',
    marginBottom: '2.5%',
    width: 400,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '35px 50px 35px 50px',
    borderRadius: 8,
    boxShadow: '0 8px 16px 0 rgb(0 0 0 / 10%)',
    [theme.breakpoints.down(516)]: {
      maxWidth: '80%',
      padding: '20px 10px',
      margin: 0,
      boxShadow: 'none',
      borderRadius: 0,
    },
    backgroundColor: 'white',
  },
  inputLogin: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputIcon: {
    marginRight: 5,
    color: 'grey',
  },
  signInButton: {
    marginTop: 10,
    marginBottom: 10,
    margin: '0 auto',
    minWidth: 140,
  },
  signupTitle: {
    margin: '10px 0',
  },
  textAction: {
    border: 'none',
    color: '#2b78ff',
    fontSize: 16,
    fontWeight: 600,
    cursor: 'pointer',
    margin: '20px 0',
  },
  loadingToken: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
  },
  errorContainer: {
    display: 'flex',
    padding: 10,
    fontSize: 14,
    justifyContent: 'flex-start',
    marginBottom: 10,
    alignItems: 'center',
    borderRadius: '.25rem',
  },
  errorIcon: {
    fontSize: 22,
    padding: '0 5px',
  },
  errorMessage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 10,
  },
  errorUuid: {
    margin: 0,
    textAlign: 'left',
    paddingBottom: 5,
  },
  errorText: {
    margin: 0,
    textAlign: 'left',
  },
});
