import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { get } from '../../../utils/network';
import { Subtitle, SurfacePage, Title, TitleButton } from '../../../components';
import { makeStyles } from '@material-ui/core';
import { TopicProgress } from '../course-journey/components/topic';
import TopicProgressPlaceholder from '../course-journey/components/placeholders/topic-progress-placeholder';
import { UnitHeaderPlaceholder } from '../course-journey/components/placeholders/unit-placeholder';
import { getCookie } from '../../../utils/utils';
import BookALessonButton from '../book-lesson/components/book-a-lesson-button';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  unitTitle: {
    fontWeight: 500,
    fontSize: 20,
    marginTop: 50,
  },
  topicsProgress: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
    margin: '0 10px',
    flexWrap: 'wrap',
  },
}));

const ExtraPractice = () => {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [extraPracticeExercises, setExtraPracticeExercises] = useState({});

  const navigateToExercise = unit => exerciseId => {
    const { type, subtype } = Object.values(extraPracticeExercises)
      .flatMap(unit => unit?.extraPracticeExercises)
      .find(exercise => exercise?._id === exerciseId);
    const pathname = `/home/course_journey/exercises/${type}${
      subtype ? `/${subtype}` : ''
    }/${exerciseId}`;
    history.push({
      pathname,
      search: `?unitHeader=${unit.header}&unitName=${unit.name}&unitId=${unit.id}`,
    });
  };

  useEffect(() => {
    setLoading(true);
    get(`courses/${getCookie('courseId')}/extrapractice/mine`).then(res => {
      const units = res.message.data;
      if(!units) return;
      const extraPractice = units.reduce((acc, unit) => {
        return {
          ...acc,
          [unit.header]: {
            extraPracticeExercises: unit.extraPracticeExercises,
            unit: { header: unit.header, name: unit.name, id: unit._id },
          }
        };
      }, {});
      setLoading(false);
      return setExtraPracticeExercises(extraPractice);
    });
  }, []);

  return (
    <SurfacePage>
      <Title withBackArrow>Extra practice</Title>
      <div className={classes.header}>
        <Subtitle>Exercises list</Subtitle>
        <BookALessonButton/>
      </div>
      {loading ? (
        <div style={{ margin: '10px 0' }}>
          <UnitHeaderPlaceholder />
          <div className={classes.topicsProgress}>
            <TopicProgressPlaceholder amount={5} />
          </div>
          <div style={{ height: 20 }} />
          <UnitHeaderPlaceholder />
          <div className={classes.topicsProgress}>
            <TopicProgressPlaceholder amount={5} />
          </div>
        </div>
      ) : (
        Object.keys(extraPracticeExercises)
          .sort( (unitA, unitB) =>unitA.header < unitB.header)
          .map(
            unit =>
              extraPracticeExercises[unit].extraPracticeExercises?.length > 0 && (
                <div key={unit}>
                  <p className={classes.unitTitle}>{unit}</p>
                  <div className={classes.topicsProgress}>
                    {extraPracticeExercises[unit].extraPracticeExercises?.map(
                      ({ _id, genericType, score }, index) => (
                        <TopicProgress
                          key={_id}
                          id={_id}
                          navigateToExercise={navigateToExercise(extraPracticeExercises[unit].unit)}
                          topic={genericType}
                          score={score}
                          topicIndex={index + 1}
                        />
                      )
                    )}
                  </div>
                </div>
              )
          )
      )}
    </SurfacePage>
  );
};

export default ExtraPractice;
