import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import I18n from '../lang/i18n';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh',
  },
  text: {
    fontSize: 16,
  },
}));

const Loading = ({ style = {} }) => {
  const classes = useStyles();
  return (
    <div className={classes.container} style={style}>
      <CircularProgress color="primary" />
      <br />
      <p className={classes.text}>{I18n.t('global.loading')}</p>
    </div>
  );
};

export default Loading;
