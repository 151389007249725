import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { SurfacePage, Title, TitleButton } from '../../../components';
import I18n from '../../../lang/i18n';
import NoLessons from './components/no-lessons';
import Lesson from './components/lesson';
import { get } from '../../../utils/network';
import LessonPlaceholder from './components/lesson-placeholder';
import TextPlaceholder from '../../../components/text-placeholder';
import { getCookie } from '../../../utils/utils';

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    fontWeight: 500,
    fontSize: 20,
    marginTop: 50,
  },
}));

const BookedLessons = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const handleBookALesson = () =>
    history.push({
      pathname: '/home/book_lesson/new',
      state: { unusedLessonsTime: data?.unusedLessonsTime },
    });
  const noLessons =
    !data || (data?.bookedLessons?.length === 0 && data?.attendedLessons?.length === 0);
  const isTeacher = getCookie('isTeacher');
  
  const compareDates = (date1, date2) => {
    if (date1 > date2)
      return 1
    else if (date1 < date2)
      return -1
    return 0
  }

  useEffect(() => {
    get('scheduled-lessons/mine')
      .then(res => setData(res.message.data))
      .finally(() => setLoading(false));
  }, []);

  return (
    <SurfacePage>
      <Title withBackArrow>{I18n.t('bookedLessons.title')}</Title>
      { isTeacher ? null : 
        (<TitleButton
          onClick={handleBookALesson}
          startIcon={<img src="icons-calendar.svg" alt="calendar-icon" />}
        >
          {I18n.t('bookedLessons.buttons.bookLesson')}
        </TitleButton>)
      }
      {loading ? (
        <>
          <div className={classes.sectionTitle}>
            <TextPlaceholder width={100} height={38} />
            <LessonPlaceholder amount={3} />
          </div>
          <div className={classes.sectionTitle}>
            <TextPlaceholder width={100} height={38} />
            <LessonPlaceholder amount={3} />
          </div>
        </>
      ) : noLessons ? (
        <NoLessons handleClick={handleBookALesson} isTeacher={isTeacher} />
      ) : (
        <>
          {data?.bookedLessons?.length > 0 && (
            <>
              <p className={classes.sectionTitle}>{I18n.t('bookedLessons.booked')}</p>
              {data.bookedLessons 
                .sort((a, b) => compareDates(a.datetime, b.datetime))
                .map(lesson => (
                  <Lesson
                    key={lesson._id}
                    id={lesson._id}
                    unit={lesson?.unit?.header}
                    datetime={lesson.datetime}
                    zoomLink={isTeacher ? lesson.zoomStartUrl : lesson.zoomJoinUrl}
                    topic={lesson.topic}
                    stripeUrl={lesson.stripeUrl}
                    expires={lesson.expires}
                  />
                ))}
            </>
          )}
          {data?.attendedLessons?.length > 0 && (
            <>
              <p className={classes.sectionTitle}>{I18n.t('bookedLessons.attended')}</p>
              {data.attendedLessons
                .sort((a, b) => -compareDates(a.datetime, b.datetime))
                .map(lesson => (
                  <Lesson
                    key={lesson._id}
                    id={lesson._id}
                    unit={lesson?.unit?.header}
                    datetime={lesson.datetime}
                    attended
                    topic={lesson.topic}
                  />
                ))}
            </>
          )}
        </>
      )}
    </SurfacePage>
  );
};

export default BookedLessons;
