import React, { useState } from 'react';
import {
  CircularProgress,
  Fab,
  Hidden,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
  useTheme,
  useMediaQuery,
  Tooltip,
} from '@material-ui/core';
import { Add, Create, Delete, Visibility } from '@material-ui/icons';
import styles from './styles';
import { Link } from 'react-router-dom';
import Loading from '../loading';
import { SuccessToast } from '../toast';
import SectionHeader from '../section-header';
import { PrimaryButton } from '../button';
import { Surface } from '../surface';

const ROWS_PER_PAGE = 10;

const TableComponent = ({
  classes,
  data,
  page,
  dataCount,
  loading,
  updating,
  path,
  newMessage,
  columns,
  handleOpenForm,
  handleDelete,
  handleChangePage,
  successMessage,
  onCloseToast,
  loadingMore,
  title,
}) => {
  const theme = useTheme();
  const [rowIdOver, setRowIdOver] = useState('');
  const emptyRows = ROWS_PER_PAGE - Math.min(ROWS_PER_PAGE, data.length - page * ROWS_PER_PAGE);
  const handleOver = rowId => setRowIdOver(rowId);
  const handleLeave = () => setRowIdOver('');
  const smallScreen = useMediaQuery(theme.breakpoints.down(1020));

  const renderTableHeader = () =>
    columns.map(column => {
      const Cell = () => (
        <TableCell
          style={{ color: '#353535de', minWidth: column.minWidth }}
          classes={{ root: classes.tableHead }}
        >
          {column.label}
        </TableCell>
      );

      if (column.hidden) {
        return (
          <Hidden key={column.id} {...column.hidden}>
            <Cell />
          </Hidden>
        );
      } else {
        return <Cell key={column.id} />;
      }
    });

  const renderTableRow = row =>
    columns.map(column => {
      const cellProps = {
        onClick: () => handleOpenForm(row._id),
        key: column.id,
        classes: { root: classes.tableCell },
      };
      if (column.hidden) {
        return (
          <Hidden key={column.id} {...column.hidden}>
            <TableCell {...cellProps}>
              {column.field && row[column.id] ? row[column.id][column.field] : row[column.id]}
            </TableCell>
          </Hidden>
        );
      } else {
        return (
          <TableCell {...cellProps}>
            {column.field && row[column.id] ? row[column.id][column.field] : row[column.id]}
          </TableCell>
        );
      }
    });

  const renderActions = row => (
    <div className={classes.iconsContainer}>
      <Tooltip title="Ver información">
        <Link to={`${path}/${row._id}`}>
          <IconButton>
            <Visibility />
          </IconButton>
        </Link>
      </Tooltip>
      <Tooltip title="Editar">
        <Link
          to={{
            pathname: `${path}/${row._id}`,
            editMode: true,
          }}
        >
          <IconButton>
            <Create />
          </IconButton>
        </Link>
      </Tooltip>
      <Tooltip title="Eliminar">
        <IconButton onClick={() => handleDelete(row._id)}>
          <Delete />
        </IconButton>
      </Tooltip>
    </div>
  );

  return (
    <div>
      <SectionHeader title={title}>
        {smallScreen ? (
          <Fab size="small" color="primary" aria-label="add" onClick={() => handleOpenForm()}>
            <Add style={{ color: 'white' }} />
          </Fab>
        ) : (
          <PrimaryButton onClick={() => handleOpenForm()} startIcon={<Add />}>
            {newMessage}
          </PrimaryButton>
        )}
      </SectionHeader>
      <Surface className={classes.root}>
        {loading ? (
          <Loading style={{ height: 639 }} />
        ) : (
          <div>
            <TableContainer style={{ borderRadius: 4 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {renderTableHeader()}
                    <TableCell classes={{ root: classes.tableHead }} style={{ minWidth: 200 }}>
                      {updating && <CircularProgress size={24} style={{ marginRight: 20 }} />}
                    </TableCell>
                  </TableRow>
                </TableHead>
                {loadingMore ? (
                  <caption style={{ padding: 0, color: 'black', height: 520 }}>
                    <Loading style={{ height: 520 }} />
                  </caption>
                ) : (
                  <TableBody>
                    {data
                      .slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE)
                      .map(row => {
                        const showActions = rowIdOver === row._id || smallScreen;
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={row._id}
                            onMouseLeave={() => handleLeave()}
                            onMouseOver={() => handleOver(row._id)}
                          >
                            {renderTableRow(row)}
                            <TableCell
                              style={{ paddingTop: 2, paddingBottom: 2 }}
                              classes={{ root: classes.tableCell }}
                            >
                              {showActions ? (
                                renderActions(row)
                              ) : (
                                <div style={{ height: '100%', width: '100%' }} />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 52 * emptyRows }}>
                        <TableCell colSpan={6} classes={{ root: classes.tableCell }} />
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[ROWS_PER_PAGE]}
              component="div"
              count={dataCount}
              rowsPerPage={ROWS_PER_PAGE}
              page={page}
              labelDisplayedRows={({ from, to, count }) => from + ' al ' + to + ' de ' + count}
              onChangePage={handleChangePage}
              classes={{ root: classes.pagination }}
            />
          </div>
        )}
      </Surface>
      <SuccessToast open={successMessage !== ''} onClose={onCloseToast} text={successMessage} />
    </div>
  );
};

export default withStyles(styles)(TableComponent);
