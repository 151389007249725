import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Loading, SurfacePage, Title } from '../../../components';
import I18n from '../../../lang/i18n';
import FAQItem from './FAQItem';
import { get } from '../../../utils/network';

const useStyles = makeStyles(theme => ({
  faqList: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const FAQ = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [faqs, setFaqs] = useState([]);

  const getFaqs = () => {
    setLoading(true);
    get(`faqs?locale=${I18n.locale}`)
      .then(res => setFaqs(res.message.data.sort((a, b) => (a.position > b.position ? 1 : -1))))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getFaqs();
  }, []);

  return (
    <SurfacePage maxWidth={1210}>
      <Title withBackArrow>{I18n.t('faq.title')}</Title>
      <div className={classes.faqList}>
        {loading ? (
          <Loading style={{ height: '100%' }} />
        ) : (
          faqs.map(f => <FAQItem answer={f.answer} question={f.question} key={f._id} />)
        )}
      </div>
    </SurfacePage>
  );
};

export default FAQ;
