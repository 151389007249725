/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton, makeStyles, FormControlLabel, Typography, Checkbox } from '@material-ui/core';
import { Visibility, VisibilityOff, VpnKey as VpnKeyIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 6,
    backgroundColor: "#f0f2f4"
  },
  textAreaRoot: {
    borderRadius: 10,
    border: '1px solid #b3bac1',
    background: '#f0f2f4 !important',
    padding: 24,
    '&:hover': {
      background: '#f0f2f4',
    },
  },
  textAreaDisabled: {
    pointerEvents: 'none',
  },
  helperText: {
    marginLeft: 4,

  },
  error: {
    color: '#00204a !important',
  },
  errorCheckbox:{
    textAlign: 'start',
    color: '#f44336',
    fontSize: 12
  },
  adornedEnd: {
    paddingRight: 5,
  },
  inputPlacerholder: {
    fontWeight: 400,
    color: "#22282f"
  }
}));

const PasswordInput = ({
  error,
  name,
  value,
  handleChange,
  handleBlur,
  label,
  InputProps,
  hideStartAdornment,
  helperText,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();
  return (
    <TextField

      error={!!error}
      name={name}
      value={value}
      onChange={handleChange}
      margin="dense"
      variant="filled"
      type={showPassword ? 'text' : 'password'}
      fullWidth
      helperText={error || helperText}
      label={label}
      onBlur={handleBlur}
      autoComplete="current-password"
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      InputLabelProps={{
        classes: { error: classes.error, root: classes.inputPlacerholder },
      }}
      InputProps={{
        ...InputProps,
        disableUnderline: true,
        classes: { root: classes.root, adornedEnd: classes.adornedEnd },
        startAdornment: !hideStartAdornment && (
          <InputAdornment position="start">
            <VpnKeyIcon style={{ marginRight: 5, color: 'grey' }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={e => e.preventDefault()}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      style={{ margin: '10px 0' }}
    />
  );
};

const PrivacyCheckbox = ({errors, accept, url, langPath, resource, checkboxName, onClick, field, checkboxMessage}) => {
  const classes = useStyles();
 
  return (
    <div>
      <FormControlLabel
        label={
          <div>
            {`${accept}`}
            <a
              href={`${url}/${langPath}/${resource}`}
              rel="noopener noreferrer"
              target="_blank"
            >
             {`${checkboxMessage}`} 
            </a>
          </div>
        }
        control={<Checkbox name={checkboxName} onClick={onClick} />}
      />
      {errors[field] && <Typography className={classes.errorCheckbox}>{errors[field]} hola</Typography>}
    </div>
  );
};

const LoginInput = ({ error, name, value, handleChange, type, label, icon, handleBlur }) => {
  const classes = useStyles();
  return (
    <TextField
      error={error !== ''}
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      margin="dense"
      autoComplete="email"
      variant="filled"
      type={type}
      fullWidth
      helperText={error}
      label={label}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      InputLabelProps={{
        classes: { error: classes.error, root: classes.inputPlacerholder },
      }}
      InputProps={{
        disableUnderline: true,
        startAdornment: icon && <InputAdornment position="start">{icon}</InputAdornment>,
        classes: { root: classes.root, error: classes.error },
      }}
      style={{ margin: '10px 0' }}
    />
  );
};

const TextInput = ({
  name,
  value,
  error,
  handleChange,
  handleBlur,
  label,
  disabled,
  type,
  hide,
  fullWidth = false,
  style = {},
}) => {
  return hide ? null : (
    <TextField
      name={name}
      fullWidth={fullWidth}
      error={!!error}
      helperText={error}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      label={label}
      InputProps={{
        readOnly: disabled,
        disableUnderline: disabled,
        autoComplete: 'off',
      }}
      type={type}
      style={{ margin: '10px 0', ...style }}
    />
  );
};

const TextArea = ({
  error,
  name,
  value,
  handleChange,
  type,
  label,
  multiline = true,
  rows = 5,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <TextField
      error={error !== ''}
      name={name}
      value={value}
      onChange={handleChange}
      margin="dense"
      variant="filled"
      type={type}
      fullWidth
      helperText={error}
      label={label}
      multiline={multiline}
      rows={rows}
      disabled={disabled}
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      InputLabelProps={{
        classes: { error: classes.error },
      }}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.textAreaRoot,
          error: classes.error,
          disabled: classes.textAreaDisabled,
        },
      }}
    />
  );
};

export { PasswordInput, LoginInput, TextInput, TextArea, PrivacyCheckbox};
