import React, { memo, useEffect } from 'react';
import { Paper, Box, makeStyles, Popper, IconButton } from '@material-ui/core';
import TextPlaceholder from '../../../../components/text-placeholder';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import I18n from 'i18n-js';

const useStyles = makeStyles(theme => ({
  closeButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: '1em 1em 0em'
  },
  messageContainer: {
    fontSize: '1rem',
    margin: 'auto',
    padding: '0em 3rem 2em',
    width: '40vw',
    height: 444,
    overflow: 'hidden',
    overflowY: 'scroll',
  },
  errorText: {
    textAlign: 'center',
  },
  loadingText: {
    textAlign: 'center',
  },
  errorIcon: {
    width: '5em',
    display: 'block',
    margin: '0em auto 1em',
  },
  feedbackTitle: {
    fontSize: '1.2em',
    color: theme.palette.primary.main
  }
}))

const WritingFeedback = ({ anchorEl, open, feedback, closeModal }) => {
  const classes = useStyles();
  return (
    <Popper 
      popperOptions={{
        modifiers: {
            offset: {
              offset: '-80,0'
            } 
          },
        }
      }
      placement='left-start'
      anchorEl={anchorEl.current}
      open={!!open}
    >
      <Paper variant="outlined" elevation={6}>
        <Box className={classes.closeButtonContainer}>
          <IconButton aria-label="Hide feedback" size="large" fontSize="inherit" onClick={closeModal}>
            <img src="icons-close.svg" alt="Hide feedback"/>
          </IconButton>
        </Box >
        <Box container className={classes.messageContainer}>
          { 
            feedback?.type === 'error' ? (
              <>
                <img src="icons-writing-warning.svg" className={classes.errorIcon}/>
                <ReactMarkdown className={classes.errorText}>{feedback?.text}</ReactMarkdown>
              </>
            ) : feedback?.type === 'loading' ? (
              <>
                <ReactMarkdown className={classes.loadingText}>{feedback?.text}</ReactMarkdown>
                <TextPlaceholder amount={6}/>
              </>
            ) : feedback?.type === 'success' ? (
              <>
                <ReactMarkdown className={classes.feedbackTitle}>
                  {`**${I18n.t('courseJourney.exercise.feedback.writing.feedbackTitle')}**`}
                </ReactMarkdown>
                <ReactMarkdown>{feedback?.text}</ReactMarkdown>
              </>
            ) : null 
          }
        </Box>
      </Paper>
    </Popper>
  );
}

export default memo(WritingFeedback);