import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 0',
    backgroundColor: '#f9f9f9',
    borderRadius: theme.shape.borderRadius,
  },
  text: {
    textAlign: 'center',
    margin: 0,
    marginTop: 10,
    color: theme.palette.grey.main,
    fontSize: 16,
    whiteSpace: 'pre-line',
  },
  icon: {
    width: 80,
    height: 80,
    color: theme.palette.grey.main,
  },
}));

const NoResults = ({ icon, text }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {React.createElement(icon, { className: classes.icon })}
      <p className={classes.text}>{text}</p>
    </div>
  );
};

export default NoResults;
